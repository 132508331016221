import { DatePicker, FilterSelect, IconTextInput, SimpleTableWithMenu, tableEditOption } from '../../utils/components';
import React, { useContext } from 'react';
import { TDiagnosisContext, TLabRequest } from '../../utils/typesUtil';
import { DiagnosisCenterContext } from '../DiagnosisCenter';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { getTime } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { displayMessage, getTableRowId, pageDataValidation } from '../../utils/utils';
import Joi from 'joi';
import { CalendarChangeEvent } from 'primereact/calendar';
const _ = require('lodash');

const validateLabRequest: Joi.ObjectSchema<TLabRequest> = Joi.object({
    requestId: Joi.string(),
    selectedProsthesis: Joi.string().messages({ 'string.empty': 'Select a valid prosthesis for patient' }),
    selectedMaterialType: Joi.string().messages({ 'string.empty': 'Select a material type' }),
    numberOfUnits: Joi.string().messages({ 'string.empty': 'Add number of units' }),
    nameOfLab: Joi.string().messages({ 'string.empty': 'Select a lab for conducting the test' }),
    courier: Joi.string().messages({ 'string.empty': 'Add a courier for transporting' }),
    station: Joi.string().messages({ 'string.empty': 'Select station for transporting item' }),
    code: Joi.string().messages({ 'string.empty': 'Add a confirmation code to request procedure.' }),
    carNumber: Joi.string().messages({ 'string.empty': 'Add a car doing the transit' }),
    driverContact: Joi.string().messages({ 'string.empty': 'Add the driver of the vehicle transporting the item' }),
    dateImpressionTaken: Joi.date(),
    receivedDate: Joi.date(),
    dispatchedDate: Joi.date(),
    applianceFittedDate: Joi.date(),
    diagnosisId: Joi.string(),
    requestFiles: Joi.array()
});
const LabRequest = () => {
    const { setStateValues, state, toastRef } = useContext<Partial<TDiagnosisContext>>(DiagnosisCenterContext!);

    const onSelectChange = (e: DropdownChangeEvent) => {
        setStateValues!({
            labRequest: { ...state?.labRequest!, [e.target.id]: e.value }
        });
    };
    const onMaterialTypeChange = (e: DropdownChangeEvent) => {
        setStateValues!({
            labRequest: { ...state?.labRequest!, selectedMaterialType: e.value }
        });
    };
    const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateValues!({
            labRequest: { ...state?.labRequest!, [e.target.id]: e.target.value }
        });
    };
    const labRequestValidation = () => {
        return pageDataValidation(validateLabRequest, { ...state?.labRequest }, toastRef!);
    };
    const addNewLabRequest = () => {
        if (!labRequestValidation()) return;
        const labRequest = state?.labRequest !== undefined ? [...state?.diagnosisLabRequests] : [];
        const newRequest = { ...state?.labRequest!, requestId: uuidv4() };
        const existingRequestIndex = _.findIndex(state?.diagnosisLabRequests, function (r: TLabRequest) {
            return r.nameOfLab === newRequest.nameOfLab && r.selectedProsthesis === newRequest.selectedProsthesis;
        });
        if (existingRequestIndex === -1) {
            setStateValues!({
                diagnosisLabRequests: [...labRequest, newRequest]
            });
        } else {
            displayMessage({
                toastComponent: toastRef!,
                header: 'Add Error',
                message: 'Same Request data exists. Try to edit it!',
                infoType: 'error',
                life: 5000
            });
        }
    };

    const editRequest = (e: React.MouseEvent<HTMLButtonElement>) => {
        const requestId = getTableRowId(e, 'id');
        const selectedRequest = state?.diagnosisLabRequests.find((request: TLabRequest) => request.requestId === requestId);
        selectedRequest!.dateImpressionTaken = new Date(selectedRequest!.dateImpressionTaken as Date);
        selectedRequest!.dispatchedDate = new Date(selectedRequest!.dispatchedDate as Date);
        selectedRequest!.receivedDate = new Date(selectedRequest!.receivedDate as Date);
        selectedRequest!.applianceFittedDate = new Date(selectedRequest!.applianceFittedDate as Date);
        setStateValues!({
            labRequest: { ...selectedRequest! },
            labRequestEditState: true
        });
    };
    const deleteRequest = (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const labRequestId = getTableRowId(e, 'name');
            const selectedRequest = state?.diagnosisLabRequests.find((labRequest: TLabRequest) => labRequest.requestId === labRequestId);
            const requestsNewList = _.without(state?.diagnosisLabRequests!, selectedRequest);
            setStateValues!({
                diagnosisLabRequests: requestsNewList
            });
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef!,
                life: 5000
            });
        }
    };
    const updateLabRequest = () => {
        if (!labRequestValidation) return;
        const updatingItem = state?.diagnosisLabRequests.find((labRequest: TLabRequest) => labRequest.requestId === state?.labRequest?.requestId);
        const updatingItemIndex = _.findIndex(state?.diagnosisLabRequests, updatingItem);

        setStateValues!({
            diagnosisLabRequests: _.set(state?.diagnosisLabRequests, updatingItemIndex, state?.labRequest),
            labRequestEditState: false
        });
    };

    return (
        <>
            <div className="card p-fluid fadeinleft animation-duration-500">
                <div className="grid">
                    <div className="col-5 max-h-30rem overflow-auto">
                        <h6>New Lab Request</h6>
                        <div className="grid p-formgrid">
                            <div className="field lg:col-6 md:col-12 col-12">
                                <FilterSelect
                                    selectableOptions={state?.prosthesisTypes!}
                                    selectedOption={state?.labRequest?.selectedProsthesis!}
                                    onSelectChange={onSelectChange}
                                    elementId="selectedProsthesis"
                                    defaultValue="Type of Prosthesis"
                                    showClearIcon={true}
                                />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <FilterSelect
                                    selectableOptions={state?.materialTypes!}
                                    selectedOption={state?.labRequest?.selectedMaterialType!}
                                    onSelectChange={onMaterialTypeChange}
                                    elementId="selectedMaterialType"
                                    defaultValue="Type of Material"
                                    showClearIcon={true}
                                />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="numberOfUnits">Number of Units</label>
                                <InputText type="number" value={state?.labRequest?.numberOfUnits} onChange={onInputChanged} id="numberOfUnits" min={0} />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <FilterSelect selectableOptions={state?.listLabs!} selectedOption={state?.labRequest?.nameOfLab!} onSelectChange={onSelectChange} elementId="nameOfLab" defaultValue="Laboratory" showClearIcon={true} />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <FilterSelect selectableOptions={state?.listCouriers!} selectedOption={state?.labRequest?.courier!} onSelectChange={onSelectChange} elementId="courier" defaultValue="Courier" showClearIcon={true} />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <FilterSelect selectableOptions={state?.listCarStations!} selectedOption={state?.labRequest?.station!} onSelectChange={onSelectChange} elementId="station" defaultValue="Station" showClearIcon={true} />
                            </div>
                            <IconTextInput value={state?.labRequest?.code} onInputChange={onInputChanged} placeholderValue="Code" iconText="pi pi-user" componentId="code" customClasses="lg:col-6 md:col-12 col-12" />
                            <IconTextInput value={state?.labRequest?.carNumber} onInputChange={onInputChanged} placeholderValue="Car Number" iconText="pi pi-user" componentId="carNumber" customClasses="lg:col-6 md:col-12 col-12" />
                            <IconTextInput value={state?.labRequest?.driverContact} onInputChange={onInputChanged} placeholderValue="Driver Contact" iconText="pi pi-phone" componentId="driverContact" customClasses="lg:col-6 md:col-12 col-12" />
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="dateImpressionTaken">Impression Date</label>
                                <DatePicker
                                    dateValue={state?.labRequest?.dateImpressionTaken!}
                                    onDateChange={(e: CalendarChangeEvent) => setStateValues!({ labRequest: { ...state?.labRequest!, dateImpressionTaken: e.value! } })}
                                    labelText="Impression Date"
                                    controlId="dateImpressionTaken"
                                    selectionType="single"
                                    displayButtonBar={true}
                                    displayTime={false}
                                />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="dispatchedDate">Parcel Dispatched Date</label>
                                <DatePicker
                                    dateValue={state?.labRequest?.dispatchedDate!}
                                    onDateChange={(e: CalendarChangeEvent) => setStateValues!({ labRequest: { ...state?.labRequest!, dispatchedDate: e.value! } })}
                                    labelText="Dispatched Date"
                                    controlId="dispatchedDate"
                                    selectionType="single"
                                    displayButtonBar={true}
                                    displayTime={false}
                                    maximumDateValue={new Date(state?.labRequest?.dateImpressionTaken! as Date)}
                                />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="receivedDate">Parcel Received Date</label>
                                <DatePicker
                                    dateValue={state?.labRequest?.receivedDate!}
                                    onDateChange={(e: CalendarChangeEvent) => setStateValues!({ labRequest: { ...state?.labRequest!, receivedDate: e.value! } })}
                                    labelText="Received Date"
                                    controlId="receivedDate"
                                    selectionType="single"
                                    displayButtonBar={true}
                                    displayTime={false}
                                />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="applianceFittedDate">Parcel Received Date</label>
                                <DatePicker
                                    dateValue={state?.labRequest?.applianceFittedDate!}
                                    onDateChange={(e: CalendarChangeEvent) => setStateValues!({ labRequest: { ...state?.labRequest!, applianceFittedDate: e.value! } })}
                                    labelText="Received Date"
                                    controlId="applianceFittedDate"
                                    selectionType="single"
                                    displayButtonBar={true}
                                    displayTime={false}
                                />
                            </div>
                            <div className="field lg:col-4 md:col-12 col-12 lg:mt-4">
                                <Button onClick={!state?.labRequestEditState ? addNewLabRequest : updateLabRequest}>{!state?.labRequestEditState ? `Add Request` : `Update Request`}</Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-7">
                        <div>
                            <SimpleTableWithMenu
                                tableKey={'requestId'}
                                columnsDef={[
                                    { body: (rowData: TLabRequest) => tableEditOption(editRequest, deleteRequest, rowData.requestId), header: 'Edit', style: '8rem' },
                                    { field: 'nameOfLab', header: 'Lab', style: '10rem' },
                                    { field: 'selectedProsthesis', header: 'Prosthesis', style: '10rem' },
                                    { field: 'selectedMaterialType', header: 'Material', style: '5rem' },
                                    { field: 'courier', header: 'Courier', style: '5rem' },
                                    { field: 'code', header: 'Code', style: '5rem' }
                                ]}
                                tableData={state?.diagnosisLabRequests}
                                hasMenuList={false}
                                tableTitle="Lab Requests"
                                lastTableUpdate={getTime(new Date())}
                                childTableDef={[]}
                                tableMinWidth={50}
                                searchValues={['nameOfLab', 'selectedProsthesis', 'courier']}
                                searchFieldPlaceHolder="Search by Lab name, Prosthesis or Courier"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default LabRequest;
