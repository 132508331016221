import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';

import Dashboard from './components/Dashboard';
import IconsDemo from './utilities/IconsDemo';
import Invoice from './pages/Invoice';
import Patient from './components/Patient';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import Appointments from './components/Appointments';
import ConsultationRoom from './components/ConsultationRoom';
import DiagnosisCenter from './components/DiagnosisCenter';
import BillList from './components/BillList';
import Users from './components/Users';
import Debtors from './components/Debtors';
import LabRequests from './components/LabRequests';
import { AppUserContext, useUserLocalStorage } from './utils/utils';
import ItemStatePage from './components/Items';
import Payments from './components/Payments';
import Expenditure from './components/Expenditure';
import Purchases from './components/Purchases';
import Adjustments from './components/Adjustments';
import Sales from './components/Diagnosis/Sales';
import TreatmentProcedures from './components/others/TreatmentProcedures';
import Reporting from './components/Reporting';

const App = (props: any) => {
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('horizontal');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);
    const copyTooltipRef = useRef<any>();
    const location = useLocation();
    const [user] = useUserLocalStorage();
    PrimeReact.ripple = true;

    const menu = [
        {
            label: 'Favorites',
            icon: 'pi pi-home',
            items: [{ label: 'Dashboard', icon: 'pi pi-home', to: '/' }]
        },
        {
            label: 'Patients',
            icon: 'pi pi-download',
            items: [
                {
                    label: 'Patient',
                    icon: 'pi pi-heart-fill',
                    to: '/patient'
                },
                {
                    label: 'Appointments',
                    icon: 'pi pi-calendar-plus',
                    to: '/appointments'
                },
                {
                    label: 'Consultation',
                    icon: 'pi pi-book',
                    to: '/consultation'
                },
                {
                    label: 'Labs',
                    icon: 'pi pi-file',
                    to: '/labs'
                }
            ]
        },
        {
            label: 'Financials',
            icon: 'pi pi-money-bill',
            items: [
                {
                    label: 'Bills',
                    icon: 'pi pi-money-bill',
                    to: '/bills'
                },
                {
                    label: 'Debtors',
                    icon: 'pi pi-users',
                    to: '/debtors'
                },
                {
                    label: 'Payments',
                    icon: 'pi pi-money-bill',
                    to: '/payments'
                },
                {
                    label: 'Expenditure',
                    icon: 'pi pi-money-bill',
                    to: '/expenses'
                }
            ]
        },
        {
            label: 'General',
            icon: 'pi pi-cog',
            items: [
                {
                    label: 'Users',
                    icon: 'pi pi-user',
                    to: '/users'
                },
                {
                    label: 'Procedures',
                    icon: 'pi pi-list',
                    to: '/procedures'
                },
                {
                    label: 'Reports',
                    icon: 'pi pi-list',
                    to: '/reports'
                }
            ]
        },
        {
            label: 'Store',
            icon: 'pi pi-shopping-cart',
            items: [
                {
                    label: 'Items',
                    icon: 'pi pi-desktop',
                    to: '/items'
                },
                {
                    label: 'Sales',
                    icon: 'pi pi-money-bill',
                    to: '/sales'
                },
                {
                    label: 'Purchases',
                    icon: 'pi pi-desktop',
                    to: '/purchases'
                },
                {
                    label: 'Adjustments',
                    icon: 'pi pi-pencil',
                    to: '/adjustments'
                }
            ]
        }
    ];

    const routes = [
        { parent: 'Dashboard', label: 'Sales Dashboard' },
        { parent: 'Pages', label: 'Crud' },
        { parent: 'Pages', label: 'Calendar' },
        { parent: 'Pages', label: 'Timeline' },
        { parent: 'Pages', label: 'Invoice' },
        { parent: 'Pages', label: 'Login' },
        { parent: 'Pages', label: 'Help' },
        { parent: 'Pages', label: 'Empty' },
        { parent: 'Pages', label: 'Access' },
        { parent: 'Start', label: 'Patient' },
        { parent: 'Start', label: 'Appointments' },
        { parent: 'Start', label: 'Consultation' },
        { parent: 'Start', label: 'Diagnosis' },
        { parent: 'Start', label: 'Bills' },
        { parent: 'Start', label: 'Users' },
        { parent: 'Start', label: 'Items' },
        { parent: 'Start', label: 'Debtors' },
        { parent: 'Start', label: 'Labs' },
        { parent: 'Start', label: 'Payments' },
        { parent: 'Start', label: 'Expenses' },
        { parent: 'Store', label: 'Purchases' },
        { parent: 'Store', label: 'Adjustments' },
        { parent: 'Store', label: 'Sales' },
        { parent: 'General', label: 'Procedures' },
        { parent: 'General', label: 'Reports' }
    ];

    let rightMenuClick: any;
    let configClick: any;
    let menuClick: any;
    let searchClick: boolean = false;
    let topbarItemClick: any;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onMenuModeChange = (menuMode: any) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    };

    const onRightMenuButtonClick = () => {
        rightMenuClick = true;
        setRightMenuActive(true);
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuActiveChange = (active: any) => {
        setRightMenuActive(active);
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = (event: any) => {
        setConfigActive((prevState) => !prevState);
        configClick = true;
        event.preventDefault();
    };

    const onRippleChange = (e: any) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onMenuButtonClick = (event: any) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event: any) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onToggleMenu = (event: any) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event: any) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event: any) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const onInputClick = () => {
        searchClick = true;
    };

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    return (
        <AppUserContext.Provider value={user!} key={'appUser'}>
            <div className={layoutClassName} onClick={onDocumentClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <div className="layout-main">
                    <AppTopbar
                        items={menu}
                        menuMode={menuMode}
                        colorScheme={props.colorScheme}
                        menuActive={menuActive}
                        topbarMenuActive={topbarMenuActive}
                        activeInlineProfile={activeInlineProfile}
                        onTopbarItemClick={onTopbarItemClick}
                        onMenuButtonClick={onMenuButtonClick}
                        onSidebarMouseOver={onSidebarMouseOver}
                        onSidebarMouseLeave={onSidebarMouseLeave}
                        onToggleMenu={onToggleMenu}
                        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                        onMenuClick={onMenuClick}
                        onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        resetActiveIndex={resetActiveIndex}
                    />

                    <AppMenu
                        model={menu}
                        onRootMenuItemClick={onRootMenuItemClick}
                        onMenuItemClick={onMenuItemClick}
                        onToggleMenu={onToggleMenu}
                        onMenuClick={onMenuClick}
                        menuMode={menuMode}
                        colorScheme={props.colorScheme}
                        menuActive={menuActive}
                        sidebarActive={sidebarActive}
                        sidebarStatic={sidebarStatic}
                        pinActive={pinActive}
                        onSidebarMouseLeave={onSidebarMouseLeave}
                        onSidebarMouseOver={onSidebarMouseOver}
                        activeInlineProfile={activeInlineProfile}
                        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                        resetActiveIndex={resetActiveIndex}
                    />

                    <AppBreadcrumb routes={routes} onMenuButtonClick={onMenuButtonClick} menuMode={menuMode} onRightMenuButtonClick={onRightMenuButtonClick} onInputClick={onInputClick} searchActive={searchActive} breadcrumbClick={breadcrumbClick} />

                    <div className="layout-main-content">
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/icons" element={<IconsDemo />} />
                            <Route path="/invoice" element={<Invoice colorMode={props.colorScheme} location={location} />} />
                            <Route path="/patient" element={<Patient />} />
                            <Route path="/appointments" element={<Appointments />} />
                            <Route path="/consultation" element={<ConsultationRoom />} />
                            <Route path="/diagnosis" element={<DiagnosisCenter />} />
                            <Route path="/bills" element={<BillList />} />
                            <Route path="/users" element={<Users />} />
                            <Route path="/items" element={<ItemStatePage />} />
                            <Route path="/debtors" element={<Debtors />} />
                            <Route path="/labs" element={<LabRequests />} />
                            <Route path="/payments" element={<Payments />} />
                            <Route path="/expenses" element={<Expenditure />} />
                            <Route path="/purchases" element={<Purchases />} />
                            <Route path="/adjustments" element={<Adjustments />} />
                            <Route path="/sales" element={<Sales />} />
                            <Route path="/procedures" element={<TreatmentProcedures />} />
                            <Route path="/reports" element={<Reporting />} />
                        </Routes>
                    </div>

                    <AppFooter colorScheme={props.colorScheme} />
                </div>

                <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick} onRightMenuActiveChange={onRightMenuActiveChange} />

                <AppConfig
                    configActive={configActive}
                    onConfigButtonClick={onConfigButtonClick}
                    onConfigClick={onConfigClick}
                    menuMode={menuMode}
                    changeMenuMode={onMenuModeChange}
                    colorScheme={props.colorScheme}
                    changeColorScheme={props.onColorSchemeChange}
                    theme={props.theme}
                    changeTheme={props.onMenuThemeChange}
                    componentTheme={props.componentTheme}
                    changeComponentTheme={props.onComponentThemeChange}
                    ripple={ripple}
                    onRippleChange={onRippleChange}
                />
            </div>
        </AppUserContext.Provider>
    );
};

export default App;
