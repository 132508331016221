import { updateCacheRecord, useGetPaymentsList } from '../utils/reactQueryUtils';
import { displayMessage, getBaseURL, getTableRowId, getThisMonthDates, getThisWeekDates, getTodayDates, pageDataValidation, promptUserAction, remakeDropdownSelects } from '../utils/utils';
import React, { useEffect, useRef, useState } from 'react';
import { DropdownOption, TPaymentListItem } from '../utils/typesUtil';
import { FilterSelect, GeneralPageProps, Loader, SimpleTableWithMenu } from '../utils/components';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DropdownChangeEvent } from 'primereact/dropdown';
import Consultation from '../classes/Consultation';
import Joi from 'joi';
import { useQueryClient } from '@tanstack/react-query';

const items = [
    { name: 'Today', value: 1 },
    { name: 'This Week', value: 2 },
    { name: 'This Month', value: 3 },
    { name: 'All Time', value: 4 }
];

type TPaymentsPage = {
    showListValue: number;
    paymentsTableList: TPaymentListItem[];
    paymentTypes: DropdownOption[];
    amountPaid?: number;
    selectedPaymentType: string;
    diagnosisId: string;
    paymentId: string;
    isLoading: boolean;
};

const consultation = new Consultation();

const validatePaymentEdit = Joi.object({
    paymentId: Joi.string(),
    amountPaid: Joi.number().min(1).messages({ 'number.min': 'Please ensure that amount tendered by patient is greater than 0' }),
    diagnosisId: Joi.string(),
    selectedPaymentType: Joi.string()
});
const Payments = () => {
    const queryClient = useQueryClient();
    const toastRef = useRef<Toast>(null);
    const { data: paymentsList, isLoading, refetch, dataUpdatedAt } = useGetPaymentsList({ urlLink: `${getBaseURL()}/bills/get_payments_list` });
    const [paymentsListState, setPaymentsListState] = useState<TPaymentsPage | null>({
        showListValue: 1,
        paymentsTableList: [],
        paymentTypes: [],
        amountPaid: 0,
        selectedPaymentType: '',
        diagnosisId: '',
        paymentId: '',
        isLoading: false
    });
    const opRef = useRef<OverlayPanel>(null);

    useEffect(() => {
        setPaymentsListState((prevState) => {
            return { ...prevState!, paymentsTableList: getTodayDates<TPaymentListItem>(paymentsList!, 'paymentDate'), paymentTypes: remakeDropdownSelects([{ id: 'Cash' }, { id: 'Momo' }], 'id', 'id') };
        });
    }, []);
    useEffect(() => {
        setPaymentsListState((prevState) => {
            return { ...prevState!, paymentsTableList: paymentsList! };
        });
    }, [paymentsList]);
    const onEditPayment = (e: React.MouseEvent<HTMLButtonElement>) => {
        const thisPaymentId = getTableRowId(e, 'id');
        const selectedPayment = paymentsListState?.paymentsTableList.find((payment: TPaymentListItem) => payment.paymentId === thisPaymentId);
        const { amountPaid, paymentType, diagnosisId, paymentId } = selectedPayment!;
        setPaymentsListState((prevState) => {
            return { ...prevState!, amountPaid: amountPaid!, selectedPaymentType: paymentType!, diagnosisId, paymentId };
        });
        opRef.current?.toggle(e);
    };
    const paymentsTotal = () => {
        return paymentsListState?.paymentsTableList
            ?.reduce((previousValue, currentValue) => {
                return previousValue + parseFloat(currentValue.amountPaid.toString());
            }, 0)
            .toFixed(2);
    };
    const onSelectButtonChange = (e: SelectButtonChangeEvent) => {
        switch (e.value) {
            case 1:
                setPaymentsListState((prevState) => {
                    return { ...prevState!, paymentsTableList: getTodayDates<TPaymentListItem>(paymentsList!, 'paymentDate'), showListValue: e.value };
                });
                break;
            case 2:
                setPaymentsListState((prevState) => {
                    return { ...prevState!, paymentsTableList: getThisWeekDates<TPaymentListItem>(paymentsList!, 'paymentDate'), showListValue: e.value };
                });
                break;
            case 3:
                setPaymentsListState((prevState) => {
                    return { ...prevState!, paymentsTableList: getThisMonthDates<TPaymentListItem>(paymentsList!, 'paymentDate'), showListValue: e.value };
                });
                break;
            case 4:
                setPaymentsListState((prevState) => {
                    return { ...prevState!, paymentsTableList: paymentsList!, showListValue: e.value };
                });
                break;
            default:
                return paymentsList;
        }
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setPaymentsListState((prevState) => {
            return { ...prevState!, [e.target.id]: e.target.value };
        });
    };
    const onPaymentAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentsListState((prevState) => {
            return { ...prevState!, amountPaid: parseFloat(e.target.value) };
        });
    };
    const getPaymentEditState = () => {
        const paymentData: Partial<TPaymentListItem> = {
            paymentId: paymentsListState?.paymentId!,
            amountPaid: paymentsListState!.amountPaid!,
            diagnosisId: paymentsListState?.diagnosisId!,
            selectedPaymentType: paymentsListState?.selectedPaymentType!
        };
        return paymentData;
    };
    const onCompleteUpdate = async () => {
        try {
            const paymentData = getPaymentEditState();
            setPaymentsListState((prevState) => {
                return { ...prevState!, isLoading: true };
            });
            const updateResponse = await consultation.updateBillPayment(paymentData);
            console.log(updateResponse);
            if (updateResponse.data.status === 1) {
                await updateCacheRecord<TPaymentListItem>(queryClient, ['paymentsList'], [updateResponse.data.operatedData, paymentsListState?.diagnosisId!, 'diagnosisId']);
                displayMessage({
                    header: 'Payment Update Success',
                    message: 'Payment Data edited successfully!',
                    infoType: 'success',
                    toastComponent: toastRef,
                    life: 5000
                });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setPaymentsListState((prevState) => {
                return { ...prevState!, isLoading: false };
            });
        }
    };
    const promptPaymentEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!pageDataValidation(validatePaymentEdit, getPaymentEditState(), toastRef)) return;
        promptUserAction({ yesAction: onCompleteUpdate, event, displayText: 'Are you sure you want to change the payment value for this saved payment?' });
    };

    return (
        <>
            {paymentsListState?.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} toastPosition="top-right" />
            <div className="card flex align-content-between">
                <div className="flex-1 m-2">
                    Period Payments:<span className="text-cyan-500 font-bold ml-3">{paymentsTotal()}</span>
                </div>
                <div className="flex-1 font-bold m-2 align-content-between">
                    <SelectButton value={paymentsListState?.showListValue} onChange={onSelectButtonChange} optionLabel="name" options={items} />
                </div>
            </div>
            <div className="card">
                <div className="p-fluid lg:pl-5">
                    <div className="card">
                        <div className="p-fluid lg:pl-5">
                            <SimpleTableWithMenu
                                tableKey={'paymentId'}
                                columnsDef={[
                                    { field: 'paymentDate', header: 'Date' },
                                    { field: 'fullName', header: 'Name' },
                                    { field: 'amountPaid', header: 'Amount Paid' },
                                    { field: 'paymentType', header: 'Payment Type' },
                                    { body: (rowData: TPaymentListItem) => <Button icon="pi pi-pencil" className="bg-transparent" id={rowData.paymentId} onClick={onEditPayment} />, header: 'Edit Payment' }
                                ]}
                                tableData={paymentsListState?.paymentsTableList}
                                menuModel={[]}
                                hasMenuList={true}
                                tableTitle="Users List"
                                lastTableUpdate={dataUpdatedAt}
                                childTableDef={[]}
                                searchValues={['fullName', 'phoneNumber', 'location']}
                                searchFieldPlaceHolder="Search by Full Name, Phone Number or Location"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <OverlayPanel ref={opRef} className="lg:col-4">
                <Card title="Edit Payment" className="shadow-8">
                    <div className="grid">
                        <div className="field lg:col-6 md:col-12 col-12">Payment Type:</div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <FilterSelect selectableOptions={paymentsListState!.paymentTypes} selectedOption={paymentsListState!.selectedPaymentType} onSelectChange={onSelectChange} elementId="selectedPaymentType" defaultValue="" />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="field lg:col-6 md:col-12 col-12">Amount Tendered:</div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <InputText type="number" value={paymentsListState?.amountPaid?.toString()} onChange={onPaymentAmountChange} id="paymentAmount" />
                        </div>
                    </div>
                    <div className="grid mt-4">
                        <Button onClick={promptPaymentEdit}>Update Payment</Button>
                    </div>
                </Card>
            </OverlayPanel>
        </>
    );
};
export default Payments;
