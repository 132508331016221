import React, { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { TDiagnosis, TDiagnosisCenter, TDiagnosisContext, TDiagnosisDataList, TPaymentInstrument, TUsers } from '../utils/typesUtil';
import {
    AppUserContext,
    carStations,
    couriers,
    customReducer,
    displayMessage,
    drugsFormulationTypes,
    getBaseURL,
    getTableRowId,
    medicalDiagnosisConditions,
    medicalHistoryData,
    pageDataValidation,
    partnerLabs,
    REDUCER_ACTION_TYPE,
    remakeDropdownSelects,
    typeOfMaterial,
    typesOfProsthesis,
    wizardItems
} from '../utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { Steps, StepsSelectEvent } from 'primereact/steps';
import MedicalHistory from './Diagnosis/MedicalHistory';
import PastDrugHistory from './Diagnosis/PastDrugHistory';
import Allergies from './Diagnosis/Allergies';
import DentalHistory from './Diagnosis/DentalHistory';
import DoctorNotes from './Diagnosis/DoctorNotes';
import MedicalDiagnosis from './Diagnosis/MedicalDiagnosis';
import LabRequest from './Diagnosis/LabRequest';
import DentalChart from './Diagnosis/DentalChart';
import TreatmentPlan from './Diagnosis/TreatmentPlan';
import Prescription from './Diagnosis/Prescription';
import Treatment from './Diagnosis/Treatment';
import { Button } from 'primereact/button';
import { v4 as uuidv4 } from 'uuid';
import Diagnosis from '../classes/Diagnosis';
import { addRecordToCache, deleteCacheRecord, updateCacheRecord, useGetHangedDiagnosisData, useItemsListFetch, usePatientsDiagnosisListFetch, useTreatmentProceduresListFetch } from '../utils/reactQueryUtils';
import { DatePicker, GeneralPageProps, Loader } from '../utils/components';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Timeline } from 'primereact/timeline';
import { format } from 'date-fns';
import PatientHistoryDisplay from './Diagnosis/PatientHistoryDisplay';
import { Toast } from 'primereact/toast';
import Joi from 'joi';
import Billing from './Diagnosis/Billing';
import { Dialog } from 'primereact/dialog';
import Appointments from './Appointments';
import ClinicalPictures from './Diagnosis/ClinicalPictures';

const _ = require('lodash');
export const DiagnosisCenterContext = createContext<Partial<TDiagnosisContext>>({});

const INITIAL_STATE: TDiagnosisCenter = {
    diagnosingPatient: undefined,
    activeIndex: 0,
    medicalHistory: [],
    drugHistory: '',
    allergies: [],
    medicalHistoryValues: [],
    dentalHistory: [],
    allergiesValues: [],
    dentalHistoryValues: [],
    doctorNotes: '',
    medicalDiagnosisConditions: [],
    selectedMedicalConditions: [],
    labRequest: undefined,
    prosthesisTypes: [],
    materialTypes: [],
    treatmentPlan: '',
    drugsList: [],
    drugsPrescription: undefined,
    formulationList: [],
    treatmentsList: [],
    selectedTreatments: [],
    prescriptionForDiagnosis: [],
    diagnosisLabRequests: [],
    patientId: '',
    diagnosisId: '',
    diagnosisList: [],
    diagnosisHistory: undefined,
    prescriptionEditState: false,
    labRequestEditState: false,
    editDiagnosisState: false,
    isLoading: true,
    otherValue: '',
    otherMedicalHistory: [],
    otherAllergies: [],
    otherDentalHistory: [],
    otherTreatment: [],
    listLabs: [],
    listCarStations: [],
    listCouriers: [],
    showDiagnosticHistory: false,
    selectedInstruments: [],
    paymentInstruments: [
        { item: 'Registration Fee', price: '0' },
        { item: 'Consultation Fee', price: '0' },
        { item: 'Procedure Fee', price: '0' },
        { item: 'Drugs Fee', price: '0' }
    ],
    billedInstruments: [],
    showAppointmentDialog: false,
    itemsList: [],
    diagnosisDate: new Date(),
    visitedSteps: [],
    frequencyList: [],
    diagnosisDescription: '',
    otherMedicalDiagnosis: [],
    patientImages: [],
    patientImagesPath: [],
    imagesToShow: [],
    diagnosisStatus: [],
    patientStatus: 'Accounts',
    discountOptions: ['Cash Discount', 'Percentage Discount'],
    billDiscount: { discountOption: 'Cash Discount', discountAmount: '0', discountPercentage: 0 },
    discountValue: '0',
    treatmentProcedures: [],
    procedurePayerList: [],
    selectedProcedurePayer: '',
    imgSRC: '',
    patientDebtAmount: 0
};

const validateDiagnosis: Joi.ObjectSchema<TDiagnosis> = Joi.object({
    diagnosisId: Joi.string().required().messages({ 'string.empty': 'Ensure that page is initialized before proceeding' }),
    patientId: Joi.string().required().messages({ 'string.empty': 'Ensure that page is initialized before proceeding' }),
    medicalHistory: Joi.array(),
    drugHistory: Joi.string().allow(''),
    allergies: Joi.array(),
    dentalHistory: Joi.array(),
    doctorNotes: Joi.string().allow(''),
    selectedMedicalConditions: Joi.array(),
    diagnosisLabRequests: Joi.array(),
    treatmentPlan: Joi.string().allow(''),
    selectedTreatments: Joi.array(),
    prescriptionForDiagnosis: Joi.array(),
    otherMedicalHistory: Joi.array(),
    otherAllergies: Joi.array(),
    otherDentalHistory: Joi.array(),
    otherTreatment: Joi.array(),
    selectedInstruments: Joi.array(),
    billedInstruments: Joi.array(),
    diagnosisDate: Joi.date(),
    diagnosisDescription: Joi.string().messages({ 'string.empty': 'Add an identifiable description for this diagnosis' }),
    otherMedicalDiagnosis: Joi.array(),
    patientImagesPath: Joi.array(),
    patientImages: Joi.array(),
    patientStatus: Joi.string(),
    billDiscount: Joi.object(),
    selectedProcedurePayer: Joi.string().allow('')
});

const diagnosis = new Diagnosis();
const DiagnosisCenter = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const opRef = useRef<OverlayPanel>(null);
    const notificationRef = useRef<HTMLElement>(null);
    const [state, dispatch] = useReducer(customReducer<TDiagnosisCenter>, INITIAL_STATE);
    const toastRef = useRef<Toast>(null);
    const diagnosisDateRef = useRef<OverlayPanel>(null);
    const location = useLocation();
    const patientId = location.state.key;
    const { data: listOfDiagnosis, isLoading } = usePatientsDiagnosisListFetch({ urlLink: `${getBaseURL()}/diagnosis/get_all_diagnosis?patientId=${patientId}`, enableFetch: patientId !== undefined });
    const { data: itemsList, isLoading: isLoadingItems } = useItemsListFetch({ urlLink: `${getBaseURL()}/items/get_all_items` });
    const { data: treatmentProceduresList, isLoading: treatmentProceduresLoading } = useTreatmentProceduresListFetch({ urlLink: `${getBaseURL()}/diagnosis/get_all_treatment_procedures` });
    const user = useContext<TUsers>(AppUserContext);
    const { data: generalBag } = useGetHangedDiagnosisData(queryClient);

    useEffect(() => {
        if (!user) navigate('/login');
        if (treatmentProceduresList) {
            setStateValues({
                medicalHistoryValues: medicalHistoryData,
                allergiesValues: ['None', 'Sulphur Containing Substances', 'NSAIDS', 'Penicillins', 'G6PD'],
                dentalHistoryValues: ['None', 'Extractions', 'Amalgum Filling', 'Composite Filling', 'Removable Dentures', 'Crowns & Bridges', 'Orthodontics', 'S & P'],
                medicalDiagnosisConditions: remakeDropdownSelects(medicalDiagnosisConditions, 'name', 'name'),
                prosthesisTypes: remakeDropdownSelects(typesOfProsthesis, 'name', 'name'),
                materialTypes: remakeDropdownSelects(typeOfMaterial, 'name', 'name'),
                formulationList: remakeDropdownSelects(drugsFormulationTypes, 'name', 'name'),
                treatmentsList: treatmentProceduresList?.map((treatment) => treatment.procedureDescription),
                diagnosisId: uuidv4(),
                patientId: patientId,
                isLoading: false,
                listLabs: remakeDropdownSelects(partnerLabs, 'name', 'name'),
                listCouriers: remakeDropdownSelects(couriers, 'name', 'name'),
                listCarStations: remakeDropdownSelects(carStations, 'name', 'name'),
                drugsPrescription: { prescriptionId: uuidv4(), itemId: '', itemName: '', quantityIntake: '0', unitPrice: '0', formulation: '', duration: '0', dosage: '0', subTotal: '0', frequency: '0' },
                labRequest: {
                    requestId: uuidv4(),
                    nameOfLab: '',
                    selectedProsthesis: '',
                    selectedMaterialType: '',
                    numberOfUnits: '',
                    carNumber: '',
                    code: '',
                    courier: '',
                    driverContact: '',
                    station: '',
                    dateImpressionTaken: new Date(),
                    dispatchedDate: new Date(),
                    receivedDate: new Date(),
                    applianceFittedDate: new Date(),
                    requestFiles: []
                },
                diagnosisDate: new Date(generalBag?.diagnosingPatient.appointmentDate as Date),
                visitedSteps: [0],
                frequencyList: remakeDropdownSelects([{ name: '1 Daily' }, { name: '12 hr Interval' }, { name: '8 hour Interval' }, { name: '6 hour Interval' }], 'name', 'name'),
                diagnosisStatus: remakeDropdownSelects([{ desc: 'Accounts' }, { desc: 'Completed' }], 'desc', 'desc'),
                treatmentProcedures: treatmentProceduresList,
                procedurePayerList: remakeDropdownSelects([{ procedurePayer: 'Walk-In' }, { procedurePayer: 'Insurance' }, { procedurePayer: 'Company' }], 'procedurePayer', 'procedurePayer')
            });
        }

        try {
            if (generalBag?.pausedDiagnosis) {
                const hangedData = generalBag.pausedDiagnosis;
                // const paymentInstruments = _.override(state.paymentInstruments, hangedData.billedInstruments);
                setStateValues({
                    medicalHistory: hangedData.medicalHistory,
                    drugHistory: hangedData.drugHistory,
                    allergies: hangedData.allergies,
                    dentalHistory: hangedData.dentalHistory,
                    doctorNotes: hangedData.doctorNotes,
                    selectedMedicalConditions: hangedData.selectedMedicalConditions,
                    diagnosisLabRequests: hangedData.diagnosisLabRequests,
                    treatmentPlan: hangedData.treatmentPlan,
                    selectedTreatments: hangedData.selectedTreatments,
                    prescriptionForDiagnosis: hangedData.prescriptionForDiagnosis,
                    otherMedicalHistory: hangedData.otherMedicalHistory,
                    otherAllergies: hangedData.otherAllergies,
                    otherDentalHistory: hangedData.otherDentalHistory,
                    otherTreatment: hangedData.otherTreatment,
                    billedInstruments: hangedData.billedInstruments,
                    selectedInstruments: hangedData.selectedInstruments,
                    billDiscount: hangedData.billDiscount,
                    selectedProcedurePayer: hangedData.selectedProcedurePayer
                    // paymentInstruments: hangedData.billedInstruments
                });
            }
        } catch (error: any) {}
    }, [generalBag, treatmentProceduresList]);

    useEffect(() => {
        setStateValues({
            diagnosingPatient: generalBag?.diagnosingPatient,
            patientDebtAmount: generalBag?.patientDebtAmount
        });
    }, [generalBag]);

    useEffect(() => {
        setStateValues({
            diagnosisList: listOfDiagnosis,
            itemsList: itemsList
        });
    }, [listOfDiagnosis, itemsList]);

    const setStateValues = (stateValues: Partial<TDiagnosisCenter>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };

    if (isLoading || isLoadingItems || treatmentProceduresLoading) return <Loader />;
    const onStepSelect = async (e: StepsSelectEvent) => {
        setStateValues({
            activeIndex: e.index
        });
        await hangPatientDiagnosis();
    };
    const goToNextProcess = async () => {
        if (state.activeIndex === 12) return;
        setStateValues({ activeIndex: state.activeIndex + 1 });
        showHideNotification();
        await hangPatientDiagnosis();
    };
    const goBack = async () => {
        if (state.activeIndex === 0) return;
        setStateValues({ activeIndex: state.activeIndex - 1 });
        showHideNotification();
        await hangPatientDiagnosis();
    };

    const saveDiagnosis = async () => {
        if (isLoading) return;
        try {
            const stateValues: TDiagnosis = getStateValues();
            if (!pageDataValidation<TDiagnosis>(validateDiagnosis, stateValues, toastRef)) return;

            setStateValues({ isLoading: true });
            const diagnosisSaveResponse = await diagnosis.createInstance(stateValues);

            if (diagnosisSaveResponse.data.status === 1) {
                await addRecordToCache<TDiagnosis>(queryClient, ['diagnosisList'], diagnosisSaveResponse.data.operatedData);
                await deleteCacheRecord(queryClient, ['hangedDiagnosis'], [stateValues, stateValues.patientId, 'patientId']); //remove record from hanged diagnosis;
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Diagnosis Success',
                    message: 'Patient Diagnosis was successfully saved with the supplied credentials',
                    infoType: 'success',
                    life: 5000
                });
                navigate('/consultation');
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };

    const onclickOp = (e: React.MouseEvent<HTMLButtonElement>) => {
        opRef.current?.toggle(e);
    };
    const timeLineContentTemplate = (item: TDiagnosisDataList) => {
        const formattedDate = format(new Date(item.diagnosisDate as Date), 'yyyy-MM-dd');
        const diagnosis = item.diagnosisDetails[0] as TDiagnosis;
        return <Button className="underline cursor-pointer bg-transparent" onClick={showDiagnosisHistory} id={item.diagnosisId} label={`${formattedDate} ${diagnosis.diagnosisDescription}`}></Button>;
    };
    const showDiagnosisHistory = (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const selectedDiagnosisId = getTableRowId(e, 'id');
            const diagnosisListItem = state.diagnosisList?.find((diagnosis: TDiagnosisDataList) => diagnosis.diagnosisId === selectedDiagnosisId);

            const diagnosisHistory = diagnosisListItem?.diagnosisDetails[0]; //becomes an array at parsing json data upon retrieval

            if (typeof diagnosisHistory === 'object') {
                setStateValues({
                    diagnosisHistory,
                    showDiagnosticHistory: true
                });
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef!,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        }
    };
    const getStateValues = () => {
        const billedInstruments = state.paymentInstruments.filter((paymentInstrument: TPaymentInstrument) => {
            return state.selectedInstruments.includes(paymentInstrument.item) || parseFloat(paymentInstrument.price) > 0;
        });
        const {
            diagnosisId,
            patientId,
            medicalHistory,
            drugHistory,
            allergies,
            dentalHistory,
            doctorNotes,
            selectedMedicalConditions,
            diagnosisLabRequests,
            treatmentPlan,
            selectedTreatments,
            prescriptionForDiagnosis,
            otherMedicalHistory,
            otherAllergies,
            otherDentalHistory,
            otherTreatment,
            selectedInstruments,
            diagnosisDate,
            diagnosisDescription,
            otherMedicalDiagnosis,
            patientImages,
            patientImagesPath,
            patientStatus,
            billDiscount,
            selectedProcedurePayer
        } = state;
        const stateValues: TDiagnosis = {
            diagnosisId,
            patientId,
            medicalHistory,
            drugHistory,
            allergies,
            dentalHistory,
            doctorNotes,
            selectedMedicalConditions,
            diagnosisLabRequests,
            treatmentPlan,
            selectedTreatments,
            prescriptionForDiagnosis,
            otherMedicalHistory,
            otherAllergies,
            otherDentalHistory,
            otherTreatment,
            billedInstruments,
            selectedInstruments,
            diagnosisDescription,
            diagnosisDate: format(new Date(diagnosisDate as Date), 'yyyy-MM-dd'),
            otherMedicalDiagnosis,
            patientImages,
            patientImagesPath,
            patientStatus,
            billDiscount,
            selectedProcedurePayer
        };
        return stateValues;
    };
    const updateDiagnosis = async () => {
        try {
            const stateValues = getStateValues();

            setStateValues({ isLoading: true });
            const diagnosisUpdateResponse = await diagnosis.updateInstance(stateValues);
            if (diagnosisUpdateResponse.data.status === 1) {
                await updateCacheRecord<TDiagnosis>(queryClient, ['diagnosisList'], [diagnosisUpdateResponse.data.operatedData, stateValues.diagnosisId, 'diagnosisId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Selected Patient Diagnosis was successfully updated with the supplied credentials',
                    infoType: 'success',
                    life: 3000
                });
                setTimeout(() => {
                    navigate('/consultation');
                }, 3000);
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef!,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setStateValues({ editDiagnosisState: true, isLoading: false });
        }
    };
    const hangPatientDiagnosis = async () => {
        const stateValues = { ...getStateValues(), fullName: state.diagnosingPatient?.fullName, pausedPage: state.activeIndex };

        const hangedDiagnosis: any[] | undefined = queryClient.getQueryData(['hangedDiagnosis']);

        const alreadyHangedDiagnosis = hangedDiagnosis !== undefined ? hangedDiagnosis : [];

        if (_.find(hangedDiagnosis, { patientId: stateValues.patientId })) {
            //find the existing diagnosis
            const existingDiagnosis = _.find(hangedDiagnosis, { patient: stateValues.patientId });
            const updatedValues = { ...existingDiagnosis, ...stateValues };
            await updateCacheRecord(queryClient, ['hangedDiagnosis'], [updatedValues, stateValues.patientId, 'patientId']);
            return;
        }

        const newHangedDiagnosis = [...alreadyHangedDiagnosis, stateValues];

        queryClient.setQueryData(['hangedDiagnosis'], newHangedDiagnosis);
        displayMessage({
            toastComponent: toastRef,
            header: 'Hang Success',
            message: 'Patient data was successfully held up.',
            infoType: 'success',
            life: 3000
        });
    };
    const showHideNotification = () => {
        notificationRef.current?.classList.remove('hidden');
        setTimeout(() => {
            notificationRef.current?.classList.add('hidden');
        }, 3000);
    };
    const returnToConsultationRoom = () => {
        navigate('/consultation');
    };
    const addToSteps = (currentStep: number) => {
        const newVisitedStepsState = [...state.visitedSteps, currentStep];
        setStateValues({ visitedSteps: newVisitedStepsState });
    };
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="col-12 md:col-12">
                <div className="card card-w-title">
                    <div className="flex">
                        <div className="flex-1 flex align-items-start justify-content-start m-2 border-round">
                            <h5>
                                {`Patient Diagnosis [Name: ${state?.diagnosingPatient?.fullName} || Age: ${state?.diagnosingPatient?.age}]`}
                                <Button tooltip="Open Patient Folder" icon="pi pi-folder" rounded className="ml-5 cursor-pointer text-cyan-500 bg-transparent hover:shadow-8" onClick={onclickOp} />
                            </h5>
                        </div>
                        <div className="flex-2 flex align-items-start justify-content-start m-2 border-round">
                            <i ref={notificationRef} className="text-orange-200 hidden pr-4">
                                Saving record to local storage...
                            </i>
                        </div>
                        <div className="flex-2 flex align-items-start justify-content-start m-2 border-round">
                            <Button onClick={returnToConsultationRoom} className="mr-5 hover:shadow-8 bg-transparent" icon="pi pi-home" tooltip="Back to Consultation Room" tooltipOptions={{ position: 'top' }} />
                            <Button onClick={goBack} className="mr-5 hover:shadow-8 bg-transparent" icon="pi pi-arrow-left" tooltip="Visit Previous Step" tooltipOptions={{ position: 'top' }} />
                            <Button onClick={goToNextProcess} className="hover:shadow-8 bg-transparent" icon="pi pi-arrow-right" tooltip="Go To Next Step" tooltipOptions={{ position: 'top' }} />
                            <Button onClick={(e) => diagnosisDateRef.current?.toggle(e)} className="hover:shadow-8 bg-transparent ml-3" icon="pi pi-calendar" tooltip="Change Diagnosis Date" tooltipOptions={{ position: 'top' }} />
                            <Button disabled={state.isLoading} onClick={!state.editDiagnosisState ? saveDiagnosis : updateDiagnosis} className="ml-5">
                                {!state.editDiagnosisState ? `Save Diagnosis` : `Update Diagnosis`}
                            </Button>
                            <Button className="ml-3" onClick={hangPatientDiagnosis}>
                                Hang Diagnosis
                            </Button>
                        </div>
                    </div>

                    <Steps className="mb-2" model={wizardItems} activeIndex={state.activeIndex} onSelect={onStepSelect} readOnly={false} />

                    <DiagnosisCenterContext.Provider value={{ setStateValues, state, toastRef, onclickOp }} key={'diagnosis'}>
                        {state.activeIndex === 0 ? (
                            <MedicalHistory />
                        ) : state.activeIndex === 1 ? (
                            <PastDrugHistory />
                        ) : state.activeIndex === 2 ? (
                            <Allergies />
                        ) : state.activeIndex === 3 ? (
                            <DentalHistory />
                        ) : state.activeIndex === 4 ? (
                            <DoctorNotes />
                        ) : state.activeIndex === 5 ? (
                            <MedicalDiagnosis />
                        ) : state.activeIndex === 6 ? (
                            <DentalChart />
                        ) : state.activeIndex === 7 ? (
                            <LabRequest />
                        ) : state.activeIndex === 8 ? (
                            <TreatmentPlan />
                        ) : state.activeIndex === 9 ? (
                            <Treatment />
                        ) : state.activeIndex === 10 ? (
                            <Prescription />
                        ) : state.activeIndex === 11 ? (
                            <ClinicalPictures />
                        ) : (
                            <Billing />
                        )}

                        <OverlayPanel ref={opRef} className="lg:w-11" showCloseIcon onHide={() => setStateValues({ showDiagnosticHistory: false })}>
                            <Splitter className="lg:h-30rem">
                                <SplitterPanel className="flex align-items-start justify-content-center mt-3 shadow-5 border-50 overflow-scroll overflow-y-auto overflow-x-auto" size={25}>
                                    <Timeline align="alternate" value={state.diagnosisList} content={timeLineContentTemplate} marker={() => <span className="pi pi-calendar text-orange-300"></span>} className="w-full md:w-20rem" />
                                </SplitterPanel>
                                <SplitterPanel size={75} className="overflow-scroll overflow-y-auto overflow-x-auto">
                                    {state.showDiagnosticHistory && <PatientHistoryDisplay />}
                                </SplitterPanel>
                            </Splitter>
                        </OverlayPanel>
                    </DiagnosisCenterContext.Provider>
                    {state.showAppointmentDialog && (
                        <Dialog onHide={() => setStateValues({ showAppointmentDialog: false })} visible={state.showAppointmentDialog} maximized={true} position="top">
                            <Appointments />
                        </Dialog>
                    )}
                </div>
            </div>
            <OverlayPanel ref={diagnosisDateRef}>
                <div className="field lg:col-12 md:col-12 col-12">
                    <label htmlFor="appointmentDate">Diagnosis Date</label>
                    <DatePicker
                        dateValue={state.diagnosisDate}
                        onDateChange={(e) => setStateValues({ diagnosisDate: e.value! })}
                        labelText="Diagnosis Date"
                        controlId="diagnosisDate"
                        selectionType="single"
                        displayButtonBar={true}
                        displayTime={true}
                    />
                </div>
            </OverlayPanel>
        </>
    );
};
export default DiagnosisCenter;
