import { Card } from 'primereact/card';
import { DropdownOption, TUsers } from '../utils/typesUtil';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { AppUserContext, customReducer, displayMessage, getBaseURL, getTableRowId, inputChange, REDUCER_ACTION_TYPE, remakeDropdownSelects, selectControlChange } from '../utils/utils';
import { useQueryClient } from '@tanstack/react-query';
import { FilterSelect, GeneralPageProps, IconTextInput, Loader, SimpleTableWithMenu, tableEditOption } from '../utils/components';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import UsersModel from '../classes/UsersModel';
import { v4 as uuidv4 } from 'uuid';
import { addRecordToCache, deleteCacheRecord, updateCacheRecord, useUsersListFetch } from '../utils/reactQueryUtils';
import { useNavigate } from 'react-router-dom';
interface IUsers extends TUsers {
    isLoading: boolean;
    showDialog: boolean;
    editingState: boolean;
    genderTypes: DropdownOption[];
    roles: DropdownOption[];
}
const INITIAL_STATE: IUsers = {
    emailAddress: '',
    fullName: '',
    gender: '',
    password: '',
    phoneNumber: '',
    userId: '',
    username: '',
    isLoading: false,
    showDialog: false,
    editingState: false,
    role: '',
    genderTypes: [],
    roles: []
};
const users = new UsersModel();
const Users = () => {
    const navigate = useNavigate();
    const user = useContext<TUsers>(AppUserContext);
    const queryClient = useQueryClient();
    const [state, dispatch] = useReducer(customReducer<IUsers>, INITIAL_STATE);
    const toastRef = useRef<Toast>(null);
    const { data: usersList, isLoading, refetch, dataUpdatedAt } = useUsersListFetch({ urlLink: `${getBaseURL()}/users/get_all_users` });

    useEffect(() => {
        if (!user) navigate('/login');
        setStateValues({
            genderTypes: remakeDropdownSelects([{ name: 'Female' }, { name: 'Male' }], 'name', 'name'),
            roles: remakeDropdownSelects([{ name: 'Super User' }, { name: 'User' }], 'name', 'name'),
            userId: uuidv4()
        });
    }, []);
    const setStateValues = (stateValues: Partial<IUsers>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    if (isLoading) return <Loader />;
    const usersMenu = () => {
        return [
            {
                label: 'New User',
                icon: 'pi pi-plus',
                command: () => setStateValues({ showDialog: true })
            },
            {
                label: 'Refresh Table',
                icon: 'pi pi-refresh',
                command: () => refetch()
            }
        ];
    };
    const controlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        inputChange(e, dispatch);
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        selectControlChange(e, dispatch);
    };
    const saveUser = async () => {
        try {
            const { userId, fullName, gender, phoneNumber, emailAddress, username, password, role } = state;
            const user: TUsers = { userId, fullName, gender, phoneNumber, emailAddress, username, password, role };
            setStateValues({ isLoading: true });
            const saveUserResponse = await users.createInstance(user);

            if (saveUserResponse.data.status === 1) {
                await addRecordToCache<TUsers>(queryClient, ['usersList'], saveUserResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'New user was successfully saved with the supplied credentials',
                    infoType: 'success',
                    life: 5000
                });
                return;
            }
            if (saveUserResponse.data.status === 0) throw Error(saveUserResponse.data.error);
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const updateUser = async () => {
        try {
            const { userId, fullName, gender, phoneNumber, emailAddress, username, password, role } = state;
            const user: TUsers = { userId, fullName, gender, phoneNumber, emailAddress, username, password, role };
            setStateValues({ isLoading: true });
            const updateUserResponse = await users.updateInstance(user);

            if (updateUserResponse.data.status === 1) {
                await updateCacheRecord<TUsers>(queryClient, ['usersList'], [updateUserResponse.data.operatedData, userId, 'userId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Selected User was successfully updated with the supplied credentials',
                    infoType: 'success',
                    life: 3000
                });
                return;
            }
            if (updateUserResponse.data.status === 0) throw Error(updateUserResponse.data.error);
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const onDialogShow = () => {
        if (!state.editingState) {
            setStateValues({
                userId: uuidv4()
            });
        }
    };
    const setupEditUser = (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedUserId = getTableRowId(e, 'id');
        const selectedUser = usersList?.find((user: TUsers) => user.userId === selectedUserId);
        if (selectedUser) {
            const { userId, fullName, phoneNumber, emailAddress, gender, role } = selectedUser;
            setStateValues({
                userId,
                fullName,
                phoneNumber,
                emailAddress,
                gender,
                role,
                editingState: true,
                showDialog: true
            });
        }
    };
    const promptUserDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const selectedUserId = getTableRowId(e, 'name');
            const deletingUser = usersList?.find((user: TUsers) => user.userId === selectedUserId);
            if (deletingUser) {
                setStateValues({ isLoading: true });
                const removedUserResponse = await users.deleteInstance(selectedUserId);
                if (removedUserResponse.data.status === 1) {
                    await deleteCacheRecord<TUsers>(queryClient, ['usersList'], [removedUserResponse.data.operatedData, selectedUserId, 'userId']);
                    displayMessage({
                        toastComponent: toastRef,
                        header: 'Delete Success',
                        message: 'User was successfully deleted!',
                        infoType: 'success',
                        life: 5000
                    });
                }
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    return (
        <>
            <Card>
                {state.isLoading && <Loader />}
                <GeneralPageProps toastRef={toastRef} />
                <div className="p-fluid lg:pl-5">
                    <SimpleTableWithMenu
                        tableKey={'userId'}
                        columnsDef={[
                            { field: 'fullName', header: 'Name' },
                            { field: 'gender', header: 'Gender' },
                            { field: 'phoneNumber', header: 'Phone' },
                            { field: 'emailAddress', header: 'Email' },
                            { field: 'username', header: 'Username' },
                            { field: 'role', header: 'Role' },
                            { body: (rowData: TUsers) => tableEditOption(setupEditUser, promptUserDelete, rowData.userId), header: 'Edit' }
                        ]}
                        tableData={usersList}
                        menuModel={usersMenu()}
                        hasMenuList={true}
                        tableTitle="Users List"
                        lastTableUpdate={dataUpdatedAt}
                        childTableDef={[]}
                        searchValues={['fullName', 'phoneNumber', 'location']}
                        searchFieldPlaceHolder="Search by Full Name, Phone Number or Location"
                    />
                </div>
            </Card>
            <Dialog onHide={() => setStateValues({ showDialog: false, editingState: false })} onShow={onDialogShow} visible={state.showDialog} closeOnEscape={false} header="New User" position="top-right" className="lg:w-7">
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <IconTextInput value={state.fullName} onInputChange={controlChange} placeholderValue="Full Name" iconText="pi pi-user" componentId="fullName" customClasses="lg:col-6 md:col-12 col-12" />
                        <div className="field lg:col-6 md:col-12 col-12">
                            <FilterSelect selectableOptions={state.genderTypes} selectedOption={state.gender} onSelectChange={onSelectChange} elementId="gender" defaultValue="Gender" />
                        </div>
                        <IconTextInput value={state.phoneNumber} onInputChange={controlChange} placeholderValue="Phone Number" iconText="pi pi-phone" componentId="phoneNumber" customClasses="lg:col-6 md:col-12 col-12" />

                        <IconTextInput value={state.emailAddress} onInputChange={controlChange} placeholderValue="Email Address" iconText="pi pi-at" componentId="emailAddress" customClasses="lg:col-6 md:col-12 col-12" />

                        {!state.editingState && <IconTextInput value={state.username} onInputChange={controlChange} placeholderValue="User name" iconText="pi pi-user" componentId="username" customClasses="lg:col-6 md:col-12 col-12" />}
                        {!state.editingState && (
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label>Password</label>
                                <InputText type="password" id="password" value={state.password} onChange={controlChange} autoComplete="off" />
                            </div>
                        )}
                        <div className="field lg:col-6 md:col-12 col-12">
                            <FilterSelect selectableOptions={state.roles} selectedOption={state.role} onSelectChange={onSelectChange} elementId="role" defaultValue="Role" />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <Button onClick={!state.editingState ? saveUser : updateUser} className="mt-4">
                                {!state.editingState ? `Save User` : `Update User`}
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};
export default Users;
