import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import { useContext } from 'react';
import { TDiagnosisContext } from '../../utils/typesUtil';
import { DiagnosisCenterContext } from '../DiagnosisCenter';

const PastDrugHistory = () => {
    const { setStateValues, state } = useContext<Partial<TDiagnosisContext>>(DiagnosisCenterContext!);
    return (
        <>
            <div className="card p-fluid max-h-30rem fadeinleft animation-duration-500">
                <h6>Drug History</h6>
                <Editor value={state?.drugHistory} onTextChange={(e: EditorTextChangeEvent) => setStateValues!({ drugHistory: e.htmlValue! })} style={{ height: '320px' }} />
            </div>
        </>
    );
};
export default PastDrugHistory;
