import React, { useContext } from 'react';
import { DiagnosisCenterContext } from '../DiagnosisCenter';
import { TDiagnosisContext } from '../../utils/typesUtil';
import { DataView } from 'primereact/dataview';
import { CheckboxInput } from '../../utils/components';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { onCheckBoxItemSelect } from '../../utils/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Chip } from 'primereact/chip';
const _ = require('lodash');
const MedicalHistory = () => {
    const { setStateValues, state } = useContext<Partial<TDiagnosisContext>>(DiagnosisCenterContext!);

    const onHistorySelect = (e: CheckboxChangeEvent) => {
        onCheckBoxItemSelect(setStateValues!, 'medicalHistory', state?.medicalHistory!, e.target.id, e.checked!);
    };

    const historyValuesTemplate = (value: string) => {
        return (
            <div className="col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start">
                    <CheckboxInput inputName={value} checkLabel={value} checkedState={state?.medicalHistory.includes(value)!} inputId={value} onCheckChange={onHistorySelect} />
                </div>
            </div>
        );
    };
    const onOtherMedicalValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setStateValues!({ otherValue: e.target.value });
    };
    const addToOtherMedicalHistory = () => {
        if (state?.otherValue === '') return;
        setStateValues!({ otherMedicalHistory: [...state?.otherMedicalHistory!, state?.otherValue!], otherValue: '' });
    };
    return (
        <>
            <div className="card p-fluid max-h-30rem fadeinleft animation-duration-500">
                <h6>Select Applicable History Data</h6>
                <div className="grid">
                    <div className="col-12 lg:col-3">
                        <DataView value={_.chunk(state!.medicalHistoryValues, 8)[0]} itemTemplate={historyValuesTemplate} />
                    </div>
                    <div className="col-12 lg:col-3">
                        <DataView value={_.chunk(state!.medicalHistoryValues, 8)[1]} itemTemplate={historyValuesTemplate} />
                    </div>
                    <div className="col-12 lg:col-6">
                        <h6>Others</h6>
                        <InputTextarea value={state?.otherValue} onChange={onOtherMedicalValueChange} rows={2} cols={30} />
                        <div className="col-2">
                            <Button onClick={addToOtherMedicalHistory}>Add</Button>
                        </div>
                        <Card className="card mt-4" subTitle="Other Medical History Items">
                            {state?.otherMedicalHistory.map((other: string, index) => {
                                return <Chip className="text-cyan-200" id={other} label={other} key={`omv${index}`} removable />;
                            })}
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};
export default MedicalHistory;
