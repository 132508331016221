import FileUploader from '../../utils/components';
import React, { useContext, useRef } from 'react';
import { TScannedFiles } from '../../utils/typesUtil';
import { displayMessage, encodeFilesToData, getBaseURL, getTableRowId } from '../../utils/utils';
import { FileUploadFilesEvent } from 'primereact/fileupload';
import { DiagnosisCenterContext } from '../DiagnosisCenter';
import { Galleria, GalleriaResponsiveOptions } from 'primereact/galleria';
import { Button } from 'primereact/button';
import Webcam from 'react-webcam';
const _ = require('lodash');

const ClinicalPictures = () => {
    const { toastRef, setStateValues, state } = useContext(DiagnosisCenterContext);
    const filesUploadRef = useRef(null);
    const galleria = useRef<Galleria>(null);
    const videoConstraints = { width: 350, height: 250, facingMode: 'user' };
    const webcamRef = useRef<Webcam>(null);
    const collectedFilesUpload = async (event: FileUploadFilesEvent) => {
        try {
            const fileItems: TScannedFiles[] = state?.patientImages! !== undefined ? [...state?.patientImages!] : [];
            for (let i = 0; i < event.files.length; i++) {
                fileItems.push(await encodeFilesToData(event.files[i]));
            }
            const patientImages = fileItems;
            const patientImagesPath = fileItems.map((item: TScannedFiles) => item.fName);
            setStateValues!({
                patientImages,
                patientImagesPath
            });
            displayMessage({
                header: 'Image(s) added',
                message: 'Images files successfully added to list',
                infoType: 'success',
                toastComponent: toastRef!,
                life: 5000
            });
        } catch (error: any) {
            console.log(error);
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef!,
                life: 5000
            });
        }
    };
    const responsiveOptions: GalleriaResponsiveOptions[] = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];
    const itemTemplate = (item: string) => {
        return <img src={`${getBaseURL()}/patientImages/${item}`} alt={item} style={{ width: '1000%', display: 'block' }} />;
    };

    const thumbnailTemplate = (item: string) => {
        return <img src={`${getBaseURL()}/patientImages/${item}`} alt={item} style={{ display: 'block', width: 80, height: 80 }} />;
    };

    const capture = React.useCallback(() => {
        const fName = `file_${Date.now()}.${'webp'}`;

        const imageSrc = webcamRef.current?.getScreenshot();

        const patientCurrentImage: TScannedFiles = { fileData: imageSrc!, fName };
        setStateValues!({ imgSRC: imageSrc!, patientImagesPath: [...state?.patientImagesPath!, fName], patientImages: [...state?.patientImages!, patientCurrentImage] });
    }, [webcamRef, state?.imgSRC]);

    const deleteCapturedImage = (e: React.MouseEvent<HTMLButtonElement>) => {
        const imageId = getTableRowId(e, 'id');
        if (imageId !== undefined) {
            const newPatientImages = _.filter(state?.patientImages, (obj: TScannedFiles) => !_.isEqual(obj, imageId));
            _.remove(state?.patientImagesPath, (fileName: string) => fileName === imageId);
            setStateValues!({ patientImages: newPatientImages, patientImagesPath: state?.patientImagesPath });
        }
    };

    const capturedImagesTemplate = (patientImage: TScannedFiles) => {
        return (
            <div>
                <Button icon="pi pi-trash" className="mb-2 p-button-danger" onClick={deleteCapturedImage} id={patientImage.fName} />
                <img src={patientImage.fileData} style={{ width: '100%', display: 'block' }} alt={'imageItem'} />
            </div>
        );
    };
    return (
        <>
            <div className="card h-30rem p-fluid max-h-30rem fadeinleft animation-duration-500 overflow-auto">
                <div className="grid">
                    <div className="col-4 max-h-30rem overflow-auto">
                        <FileUploader acceptMultipleFiles={true} onFileUpload={collectedFilesUpload} fileUploadRef={filesUploadRef} id="clinicalImages" acceptableFileTypes="image/*" fileType={'images'} />
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <Galleria
                                ref={galleria}
                                value={state?.imagesToShow}
                                responsiveOptions={responsiveOptions}
                                numVisible={9}
                                style={{ maxWidth: '50%' }}
                                circular
                                fullScreen
                                showItemNavigators
                                item={itemTemplate}
                                thumbnail={thumbnailTemplate}
                            />
                            <Button label="Show Patient Clinical Images" icon="pi pi-external-link" onClick={() => galleria.current?.show()} />
                        </div>
                        <div className="flex">
                            <div className="flex-4 pr-4">
                                <Webcam audio={false} ref={webcamRef} screenshotFormat="image/webp" videoConstraints={videoConstraints} screenshotQuality={0.92} />
                            </div>
                            <div className="flex-4">{state?.imgSRC && <img src={state.imgSRC} alt={'captureImage'} />}</div>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="card">
                            <Button onClick={capture}>Capture</Button>
                        </div>
                        {state?.patientImages.length ? (
                            <div className="card">
                                <Galleria
                                    value={state?.patientImages}
                                    responsiveOptions={responsiveOptions}
                                    numVisible={5}
                                    style={{ maxWidth: '640px' }}
                                    item={capturedImagesTemplate}
                                    showThumbnails={false}
                                    // thumbnail={thumbnailTemplate}
                                    transitionInterval={2000}
                                    showItemNavigators
                                    showItemNavigatorsOnHover
                                    showIndicators
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default ClinicalPictures;
