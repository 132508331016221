import { DataView } from 'primereact/dataview';
import React, { useContext, useState } from 'react';
import { CheckboxInput, FilterSelect } from '../../utils/components';
import { TDiagnosisContext } from '../../utils/typesUtil';
import { DiagnosisCenterContext } from '../DiagnosisCenter';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { onCheckBoxItemSelect } from '../../utils/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Chip } from 'primereact/chip';
import { InputText } from 'primereact/inputtext';
const _ = require('lodash');
const Treatment = () => {
    const [filterConditions, setFilterConditions] = useState('');

    const { setStateValues, state } = useContext<Partial<TDiagnosisContext>>(DiagnosisCenterContext!);
    const [immutableTreatmentsList, setImmutableTreatmentsList] = useState<string[]>(state?.treatmentsList!);
    const onTreatmentSelect = (e: CheckboxChangeEvent) => {
        onCheckBoxItemSelect(setStateValues!, 'selectedTreatments', state?.selectedTreatments!, e.target.id, e.checked!);
    };
    const treatmentTemplate = (value: string) => {
        return (
            <div className="col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start">
                    <CheckboxInput inputName={value} checkLabel={value} checkedState={state?.selectedTreatments.includes(value)!} inputId={value} onCheckChange={onTreatmentSelect} />
                </div>
            </div>
        );
    };
    const onOtherTreatmentValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setStateValues!({
            otherValue: e.target.value
        });
    };
    const onAddToOtherTreatment = () => {
        if (state?.otherValue === '') return;
        setStateValues!({ otherTreatment: [...state?.otherTreatment!, state?.otherValue!], otherValue: '' });
    };
    const onFilterMenuChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const thisValue = e.target.value;
        setFilterConditions(thisValue);
        setStateValues!({ treatmentsList: immutableTreatmentsList!.filter((item: string) => item.toLowerCase().includes(thisValue.toLowerCase())) });
    };
    return (
        <>
            <div className="card p-fluid max-h-30rem fadeinleft animation-duration-500">
                <div className="grid">
                    <div className="col-2">
                        <label htmlFor="numberOfUnits">Filter List</label>
                        <InputText type="text" value={filterConditions} onChange={onFilterMenuChange} id="numberOfUnits" min={0} />
                    </div>
                </div>
                <div className="grid">
                    <div className="col-7">
                        <div className="grid max-h-25rem overflow-scroll overflow-y-auto overflow-x-auto">
                            <div className="col-6">
                                <DataView value={_.chunk(state!.treatmentsList, 21)[0]} itemTemplate={treatmentTemplate} />
                            </div>
                            <div className="col-6">
                                <DataView value={_.chunk(state!.treatmentsList, 21)[1]} itemTemplate={treatmentTemplate} />
                            </div>
                        </div>
                    </div>
                    <div className="col-5 pl-4">
                        <h6>Others</h6>
                        <InputTextarea value={state?.otherValue} onChange={onOtherTreatmentValueChange} rows={2} cols={30} />
                        <div className="col-2">
                            <Button onClick={onAddToOtherTreatment}>Add</Button>
                        </div>
                        <Card className="card mt-4" subTitle="Other Treatment">
                            {state?.otherTreatment.map((other: string, index) => {
                                return <Chip className="text-cyan-200" id={other} label={other} key={`omv${index}`} removable />;
                            })}
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Treatment;
