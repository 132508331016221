import { Card } from 'primereact/card';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { updateCacheRecord, useAccumulatedDebtorsListFetch, useDebtorsListFetch } from '../utils/reactQueryUtils';
import { AppUserContext, displayMessage, getBaseURL, getTableRowId, pageDataValidation, promptUserAction, remakeDropdownSelects } from '../utils/utils';
import { FilterSelect, GeneralPageProps, Loader, SimpleTableWithMenu } from '../utils/components';
import { DropdownOption, MenuModel, TDebtor, TUsers } from '../utils/typesUtil';
import { Toast } from 'primereact/toast';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import Consultation from '../classes/Consultation';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { DropdownChangeEvent } from 'primereact/dropdown';
import Joi from 'joi';
import { useQueryClient } from '@tanstack/react-query';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';

type TDebtors = TDebtor & {
    isLoading: boolean;
    paymentChange: number;
    paymentTypes: DropdownOption[];
    selectedPaymentType: string;
    tableViewOptions: string[];
    selectedTableView: string;
};
const consultation = new Consultation();

const validateDebtorPayment = Joi.object({
    selectedPaymentType: Joi.string().messages({ 'string.empty': 'Please select a payment type' }),
    amountTendered: Joi.number().min(1).messages({ 'number.min': 'Ensure that payment amount is greater than 0' })
});

const Debtors = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [debtorsState, setDebtorsState] = useState<TDebtors>({
        amountPaid: 0,
        amountTendered: 0,
        balance: 0,
        billCost: 0,
        diagnosisDate: '',
        diagnosisId: '',
        fullName: '',
        isLoading: false,
        patientId: '',
        paymentChange: 0,
        paymentDetails: [],
        paymentId: '',
        paymentInstruments: [],
        selectedPaymentType: '',
        paymentTypes: remakeDropdownSelects([{ id: 'Cash' }, { id: 'Momo' }], 'id', 'id'),
        tableViewOptions: ['Debtors List', 'Accumulated Debtors'],
        selectedTableView: 'Debtors List'
    });
    const { data: debtorsList, isLoading, refetch, dataUpdatedAt } = useDebtorsListFetch({ urlLink: `${getBaseURL()}/bills/get_debtors_list` });
    const { data: accumulatedDebtorsList, isLoading: accumulatorsLoading, dataUpdatedAt: accumulatedDebtorsUpdatedAt } = useAccumulatedDebtorsListFetch({ urlLink: `${getBaseURL()}/bills/get_accumulated_debtors_list` });
    const toastRef = useRef<Toast>(null);
    const opRef = useRef<OverlayPanel>(null);
    const user = useContext<TUsers>(AppUserContext);

    useEffect(() => {
        if (!user) navigate('/login');
        setDebtorsState((prevState) => {
            return { ...prevState!, paymentTypes: remakeDropdownSelects([{ id: 'Cash' }, { id: 'Momo' }], 'id', 'id') };
        });
    }, []);
    const setStateValues = (stateValues: Partial<TDebtors>) => {
        setDebtorsState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    const debtorsMenu = (): MenuModel[] => {
        return [
            {
                label: 'Refresh Table',
                icon: 'pi pi-refresh',
                command: () => refetch()
            }
        ];
    };
    if (isLoading || accumulatorsLoading) return <Loader />;
    const onAcceptPayment = (e: React.MouseEvent<HTMLButtonElement>) => {
        const diagnosisId = getTableRowId(e, 'name');
        const selectedDiagnosis = debtorsList?.find((diagnosis: any) => diagnosis.diagnosisId === diagnosisId);
        const unpaidBalance = selectedDiagnosis?.balance;
        setDebtorsState((prevState) => {
            return { ...prevState!, diagnosisId, balance: unpaidBalance!, amountPaid: 0 };
        });
        opRef.current?.toggle(e);
    };
    const onPaymentAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const patientBalance = debtorsState?.balance!;
        const targetValue = e.target.value === '' ? 0 : parseFloat(e.target.value);
        const patientChange = debtorsState?.balance! - targetValue < 0 ? Math.abs(debtorsState?.balance! - targetValue) : 0;
        const amountPaying = targetValue > patientBalance ? debtorsState?.balance : targetValue;
        setDebtorsState((prevState) => {
            return { ...prevState!, amountPaid: amountPaying!, paymentChange: parseFloat(patientChange.toFixed(2)), amountTendered: targetValue };
        });
    };
    const onCompletePayment = async () => {
        try {
            const paymentDetails = {
                paymentId: uuidv4(),
                amountPaid: debtorsState?.amountPaid!,
                paymentDate: format(new Date(), 'yyyy-MM-dd'),
                diagnosisId: debtorsState?.diagnosisId,
                amountTendered: debtorsState?.amountTendered!,
                selectedPaymentType: debtorsState?.selectedPaymentType
            };
            setDebtorsState((prevState) => {
                return { ...prevState!, isLoading: true };
            });
            const morePaymentResponse = await consultation.onBillRepayment(paymentDetails);
            if (morePaymentResponse.data.status === 1) {
                displayMessage({
                    header: 'Payment Success',
                    message: 'Payment was successfully saved against patient',
                    infoType: 'success',
                    toastComponent: toastRef,
                    life: 5000
                });
                await updateCacheRecord(queryClient, ['debtorsList'], [morePaymentResponse.data.operatedData, debtorsState?.diagnosisId!, 'diagnosisId']);
                opRef.current?.hide();
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setDebtorsState((prevState) => {
                return { ...prevState!, isLoading: false };
            });
        }
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setDebtorsState((prevState) => {
            return { ...prevState!, [e.target.id]: e.target.value };
        });
    };
    const promptMoreBillPayment = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!pageDataValidation(validateDebtorPayment, { selectedPaymentType: debtorsState?.selectedPaymentType, amountTendered: debtorsState?.amountTendered }, toastRef)) return;
        promptUserAction({ yesAction: onCompletePayment, event, displayText: 'You are adding a new payment record to patient payments. Do you want to proceed?' });
    };
    const onTableViewChange = (e: SelectButtonChangeEvent) => {
        setStateValues({ selectedTableView: e.value });
    };
    return (
        <>
            {debtorsState?.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="card flex justify-content-center">
                <SelectButton className="mt-2" value={debtorsState?.selectedTableView} onChange={onTableViewChange} options={debtorsState?.tableViewOptions} />
            </div>
            <Card>
                <div className="p-fluid lg:pl-5">
                    {debtorsState.selectedTableView === 'Debtors List' ? (
                        <SimpleTableWithMenu
                            tableKey={'diagnosisId'}
                            columnsDef={[
                                { body: (rowData: any) => <div>{format(new Date(rowData.diagnosisDate), 'yyyy-MM-dd')}</div>, header: 'Diagnosis Date' },
                                { field: 'fullName', header: 'Name' },
                                { field: 'billCost', header: 'Bill' },
                                { field: 'discountAmount', header: 'Discount' },
                                { field: 'amountPaid', header: 'Paid' },
                                { field: 'balance', header: 'Balance' },
                                {
                                    body: (rowData: any) => (
                                        <Button className="bg-transparent" name={rowData.diagnosisId} onClick={onAcceptPayment}>
                                            Accept Payment
                                        </Button>
                                    ),
                                    header: 'Accept Payment'
                                }
                            ]}
                            tableData={debtorsList}
                            menuModel={debtorsMenu()}
                            hasMenuList={true}
                            tableTitle="Debtors List"
                            lastTableUpdate={dataUpdatedAt}
                            childTableDef={[]}
                            searchValues={['fullName', 'diagnosisDate', 'location']}
                            searchFieldPlaceHolder="Search by Full Name, Diagnosis Date"
                        />
                    ) : (
                        <SimpleTableWithMenu
                            tableKey={'diagnosisId'}
                            columnsDef={[
                                { field: 'fullName', header: 'Name' },
                                { field: 'billCost', header: 'Bill' },
                                { field: 'discountAmount', header: 'Discount' },
                                { field: 'amountPaid', header: 'Paid' },
                                { field: 'balance', header: 'Balance' }
                            ]}
                            tableData={accumulatedDebtorsList}
                            menuModel={debtorsMenu()}
                            hasMenuList={true}
                            tableTitle="Accumulated Debtors List"
                            lastTableUpdate={accumulatedDebtorsUpdatedAt}
                            childTableDef={[]}
                            searchValues={['fullName', 'diagnosisDate', 'location']}
                            searchFieldPlaceHolder="Search by Full Name, Diagnosis Date"
                        />
                    )}
                </div>
            </Card>
            <OverlayPanel ref={opRef} className="lg:col-4">
                <Card title="Accept Payment" className="shadow-8">
                    <div className="grid">
                        <div className="field lg:col-6 md:col-12 col-12">Balance:</div>
                        <div className="field lg:col-6 md:col-12 col-12">{debtorsState?.balance}</div>
                    </div>
                    <div className="grid">
                        <div className="field lg:col-6 md:col-12 col-12">Payment Type:</div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <FilterSelect selectableOptions={debtorsState!.paymentTypes} selectedOption={debtorsState!.selectedPaymentType} onSelectChange={onSelectChange} elementId="selectedPaymentType" defaultValue="" />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="field lg:col-6 md:col-12 col-12">Amount Tendered:</div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <InputText type="number" value={debtorsState?.amountTendered?.toString()} onChange={onPaymentAmountChange} id="paymentAmount" />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="field lg:col-6 md:col-12 col-12">Amount Paying:</div>
                        <div className="field lg:col-6 md:col-12 col-12">{debtorsState?.amountPaid}</div>
                    </div>
                    <div className="grid">
                        <div className="field lg:col-6 md:col-12 col-12 align-content-end">Change:</div>
                        <div className="field lg:col-6 md:col-12 col-12">{debtorsState?.paymentChange}</div>
                    </div>
                    <div className="grid mt-4">
                        <Button onClick={promptMoreBillPayment}>Save & Print</Button>
                    </div>
                </Card>
            </OverlayPanel>
        </>
    );
};
export default Debtors;
