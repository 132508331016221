import { FilterSelect, SimpleTableWithMenu, tableEditOption } from '../../utils/components';
import React, { useContext, useEffect } from 'react';
import { TDiagnosisContext, TPrescription } from '../../utils/typesUtil';
import { DiagnosisCenterContext } from '../DiagnosisCenter';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { getTime } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { displayMessage, getTableRowId, pageDataValidation, remakeDropdownSelects } from '../../utils/utils';
import Joi from 'joi';
const _ = require('lodash');

const validateDrugAdd: Joi.ObjectSchema<TPrescription> = Joi.object({
    itemId: Joi.string(),
    prescriptionId: Joi.string().required().messages({ 'any.required': 'Prescription ID cannot be null', 'string.empty': 'Prescription ID cannot be null' }),
    itemName: Joi.string().required().messages({ 'any.required': 'Make sure to select a prescribed drug from the list', 'string.empty': 'Make sure to select a prescribed drug from the list' }),
    frequency: Joi.string().required().messages({ 'any.required': 'Add a desired frequency', 'string.empty': 'Add a desired frequency' }),
    formulation: Joi.string().required().messages({ 'any.required': 'Select a formulation type for the prescribed drug', 'string.empty': 'Select a formulation type for the prescribed drug' }),
    duration: Joi.string().required().messages({ 'any.required': 'Add the duration for drug use', 'string.empty': 'Add the duration for drug use' }),
    dosage: Joi.string().required().messages({ 'any.required': 'Add dosage of use', 'string.empty': 'Add dosage of use' }),
    unitPrice: Joi.string().required().messages({ 'any.required': 'Unit price is required', 'string.empty': 'Unit price is required' }),
    quantityIntake: Joi.string().required().messages({ 'any.required': 'Add quantity intake to selection', 'strng.empty': 'Add quantity intake to selection' }),
    subTotal: Joi.string().optional()
});
const Prescription = () => {
    const { setStateValues, state, toastRef } = useContext<Partial<TDiagnosisContext>>(DiagnosisCenterContext!);
    useEffect(() => {
        setStateValues!({
            drugsList: remakeDropdownSelects(state!.itemsList, 'itemName', 'itemId')
        });
    }, []);
    const onSelectChange = (e: DropdownChangeEvent) => {
        // @ts-ignore
        const drugName = e.originalEvent?.target.innerText;
        const selectedItem = state?.itemsList.find((item) => item.itemId === e.value);

        setStateValues!({
            drugsPrescription: { ...state?.drugsPrescription!, itemId: e.value, itemName: drugName, unitPrice: selectedItem!.itemPrice.toString() }
        });
    };
    const onFrequencyChange = (e: DropdownChangeEvent) => {
        setStateValues!({
            drugsPrescription: { ...state?.drugsPrescription!, frequency: e.value }
        });
    };
    const onFormulationChange = (e: DropdownChangeEvent) => {
        setStateValues!({
            drugsPrescription: { ...state?.drugsPrescription!, formulation: e.value }
        });
    };
    const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateValues!({
            drugsPrescription: { ...state?.drugsPrescription!, [e.target.id]: e.target.value }
        });
    };
    const prescriptionValidation = () => {
        return pageDataValidation(validateDrugAdd, { ...state?.drugsPrescription! }, toastRef!);
    };
    const onAddDrug = () => {
        if (!prescriptionValidation()) return;
        const prescriptionForDiagnosis = state?.prescriptionForDiagnosis !== undefined ? [...state?.prescriptionForDiagnosis] : [];

        const newlyPrescribedDrug: TPrescription = { ...state?.drugsPrescription!, prescriptionId: uuidv4() };

        const existingItemIndex = _.findIndex(state?.prescriptionForDiagnosis, function (p: TPrescription) {
            return p.itemId === newlyPrescribedDrug.itemId && p.formulation === newlyPrescribedDrug.formulation;
        });

        if (existingItemIndex === -1) {
            setStateValues!({
                prescriptionForDiagnosis: [...prescriptionForDiagnosis, newlyPrescribedDrug],
                drugsPrescription: { ...state?.drugsPrescription!, prescriptionId: uuidv4() }
            });
        } else {
            displayMessage({
                toastComponent: toastRef!,
                header: 'Add Error',
                message: 'Drug with formulation type may already exist in already prescribed drugs!',
                infoType: 'error',
                life: 5000
            });
        }
    };
    const updateDrug = () => {
        const updatingItem = state?.prescriptionForDiagnosis.find((prescription: TPrescription) => prescription.prescriptionId === state?.drugsPrescription?.prescriptionId);

        const updatingItemIndex = _.findIndex(state?.prescriptionForDiagnosis, updatingItem);

        setStateValues!({
            prescriptionForDiagnosis: _.set(state?.prescriptionForDiagnosis, updatingItemIndex, state?.drugsPrescription),
            prescriptionEditState: false
        });
    };
    const onUnitPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const drugPrice = parseFloat(e.target.value);
        const quantity = state?.drugsPrescription?.quantityIntake === undefined ? 0 : parseInt(state?.drugsPrescription?.quantityIntake!);
        setStateValues!({
            drugsPrescription: { ...state?.drugsPrescription!, unitPrice: e.target.value, subTotal: (drugPrice * quantity).toFixed(2) }
        });
    };
    const onQuantityIntakeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const quantity = parseFloat(e.target.value);
        const priceAmount = state?.drugsPrescription?.unitPrice === undefined ? 0 : parseFloat(state.drugsPrescription.unitPrice);
        setStateValues!({
            drugsPrescription: { ...state?.drugsPrescription!, quantityIntake: e.target.value, subTotal: (priceAmount * quantity).toFixed(2) }
        });
    };
    const editPrescription = (e: React.MouseEvent<HTMLButtonElement>) => {
        const drugId = getTableRowId(e, 'id');
        const selectedDrug = state?.prescriptionForDiagnosis.find((prescription: TPrescription) => prescription.prescriptionId === drugId);

        setStateValues!({
            drugsPrescription: { ...selectedDrug! },
            prescriptionEditState: true
        });
    };
    const removePrescription = (e: React.MouseEvent<HTMLButtonElement>) => {
        const drugId = getTableRowId(e, 'name');
        const selectedDrug = state?.prescriptionForDiagnosis.find((prescription: TPrescription) => prescription.prescriptionId === drugId);
        const drugsNewList = _.without(state?.prescriptionForDiagnosis, selectedDrug);
        setStateValues!({
            prescriptionForDiagnosis: drugsNewList
        });
    };

    return (
        <>
            <div className="card p-fluid max-h-30rem fadeinleft animation-duration-500">
                <div className="grid">
                    <div className="col-12 lg:col-5 md:col-12">
                        <div className="grid">
                            <div className="col-12 lg:col-6 md:col-12">
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <FilterSelect selectableOptions={state?.drugsList!} selectedOption={state?.drugsPrescription?.itemId!} onSelectChange={onSelectChange} elementId="drug" defaultValue="Drug" showClearIcon={true} />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <FilterSelect
                                        selectableOptions={state?.formulationList!}
                                        selectedOption={state?.drugsPrescription?.formulation!}
                                        onSelectChange={onFormulationChange}
                                        elementId="formulation"
                                        defaultValue="Formulation"
                                        showClearIcon={true}
                                    />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <label htmlFor="dosage">Dosage</label>
                                    <InputText type="number" value={state?.drugsPrescription?.dosage} onChange={onInputChanged} id="dosage" min={0} />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <label htmlFor="quantityIntake">Quantity Intake</label>
                                    <InputText type="number" value={state?.drugsPrescription?.quantityIntake} onChange={onQuantityIntakeChange} id="quantityIntake" min={0} />
                                </div>
                            </div>

                            <div className="col-12 lg:col-6 md:col-12">
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <FilterSelect
                                        selectableOptions={state?.frequencyList!}
                                        selectedOption={state?.drugsPrescription?.frequency!}
                                        onSelectChange={onFrequencyChange}
                                        elementId="frequency"
                                        defaultValue="Frequency"
                                        showClearIcon={true}
                                    />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <label htmlFor="duration">Duration</label>
                                    <InputText type="number" value={state?.drugsPrescription?.duration} onChange={onInputChanged} id="duration" min={0} />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <label htmlFor="unitPrice">Unit Price</label>
                                    <InputText type="number" value={state?.drugsPrescription?.unitPrice} onChange={onUnitPriceChange} id="unitPrice" min={0} />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <label htmlFor="subTotal">Sub Total</label>
                                    <InputText type="number" value={state?.drugsPrescription?.subTotal} onChange={onInputChanged} id="subTotal" disabled />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <Button onClick={!state?.prescriptionEditState ? onAddDrug : updateDrug}>{!state?.prescriptionEditState ? `Add Drug` : `Update Drug`}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-7">
                        <div>
                            <SimpleTableWithMenu
                                tableKey={'prescriptionId'}
                                columnsDef={[
                                    { body: (rowData: TPrescription) => tableEditOption(editPrescription, removePrescription, rowData.prescriptionId), header: 'Edit', style: '8rem' },
                                    { field: 'itemName', header: 'Drug', style: '15rem' },
                                    { field: 'formulation', header: 'Formulation', style: '8rem' },
                                    { field: 'dosage', header: 'Dosage', style: '5rem' },
                                    { field: 'frequency', header: 'Frequency', style: '5rem' },
                                    { field: 'duration', header: 'Duration', style: '5rem' }
                                ]}
                                tableData={state?.prescriptionForDiagnosis}
                                hasMenuList={false}
                                tableTitle="Patients List"
                                lastTableUpdate={getTime(new Date())}
                                childTableDef={[]}
                                tableMinWidth={50}
                                searchValues={['drug', 'formulation', 'frequency']}
                                searchFieldPlaceHolder="Search by Drug Name, formulation or Frequency"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Prescription;
