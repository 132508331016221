import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { Badge } from 'primereact/badge';
import { IPatient, TAppointment, TCalenderSettings, TCustomisedSetting, TFullCalendar } from '../utils/typesUtil';
import { customReducer, displayMessage, getBaseURL, promptUserAction, REDUCER_ACTION_TYPE, remakeDropdownSelects, selectControlChange, useUserLocalStorage } from '../utils/utils';
import { Sidebar } from 'primereact/sidebar';
import { CheckboxInput, DatePicker, FilterSelect, GeneralPageProps, Loader, RadioButtonInput } from '../utils/components';
import { CalendarChangeEvent } from 'primereact/calendar';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { format } from 'date-fns';
import { Dialog } from 'primereact/dialog';
import { DayCellContentArg, EventClickArg, EventContentArg, EventDropArg, MoreLinkArg, WeekNumberContentArg } from '@fullcalendar/core';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { addRecordToCache, deleteCacheRecord, updateCacheRecord, useAppointmentsListFetch, usePatientsListFetch } from '../utils/reactQueryUtils';
import { v4 as uuidv4 } from 'uuid';
import Appointment from '../classes/Appointment';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';
import { confirmPopup } from 'primereact/confirmpopup';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { useNavigate } from 'react-router-dom';

const INITIAL_STATE: TFullCalendar = {
    toolBarMenu: ['dayGridMonth', 'timeGridDay'],
    userSettings: {
        showYearGrid: false,
        showWeekGrid: false,
        initialView: 'dayGridMonth',
        showWeekend: false,
        allowScroll: true,
        showListView: false
    },
    sideBarState: false,
    calendarEvents: [],
    calendarId: '',
    showDialog: false,
    appointmentDate: '',
    editState: false,
    id: '',
    title: '',
    patientsList: [],
    appointmentPatientId: '',
    appointmentId: '',
    isLoading: false
};
const appointment = new Appointment();
const Appointments = () => {
    const queryClient = useQueryClient();
    const [user] = useUserLocalStorage();
    const navigate = useNavigate();
    const calenderRef = useRef<FullCalendar>(null);
    const toastRef = useRef<Toast>(null);
    const [state, dispatch] = useReducer(customReducer<TFullCalendar>, INITIAL_STATE);
    const { data: patientsList, isLoading, refetch, dataUpdatedAt } = usePatientsListFetch({ urlLink: `${getBaseURL()}/patients/get_all_patients` });
    const { data: appointmentsList, isLoading: appointmentsLoading, refetch: appointmentsRefetch, dataUpdatedAt: appointmentsUpdateAt } = useAppointmentsListFetch({ urlLink: `${getBaseURL()}/appointments/get_all_appointments` });
    const { data: calendarSettings } = useQuery(['calendarSettings'], async () => {
        const savedSetting: TCustomisedSetting = await queryClient.getQueryData(['calendarSettings'])!;
        if (savedSetting) {
            return savedSetting;
        }
        return { toolBarMenu: state.toolBarMenu, userSettings: state.userSettings };
    });

    useEffect(() => {
        if (!user) navigate('/login');
        if (patientsList && calendarSettings) {
            setStateValues({
                patientsList: remakeDropdownSelects(patientsList, 'fullName', 'patientId'),
                toolBarMenu: calendarSettings?.toolBarMenu,
                userSettings: calendarSettings?.userSettings
            });
        }
    }, [patientsList]);

    useEffect(() => {
        if (appointmentsList !== undefined && patientsList !== undefined) {
            const calenderEvents: any = appointmentsList!.map((appointment: any) => {
                const patientAppointment: any = patientsList!.find((patient: IPatient) => patient.patientId === appointment.patientId);
                return {
                    start: new Date(appointment.appointmentDate),
                    textColorPicked: '#452c2c',
                    backgroundColorPicked: '#452c2c',
                    borderColorPicked: '#b04848',
                    title: patientAppointment?.fullName,
                    id: appointment.appointmentId,
                    patientId: appointment.patientId
                };
            });
            setStateValues({
                calendarEvents: calenderEvents
            });
        }
    }, [appointmentsList]);

    const setStateValues = (stateValues: Partial<TFullCalendar>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    if (isLoading || appointmentsLoading) return <Loader />;
    function renderDayNumber(arg: DayCellContentArg) {
        return <Badge value={arg.dayNumberText} size="large" severity="warning"></Badge>;
    }

    const setUserSetting = (e: CheckboxChangeEvent) => {
        setupVisibleOptions(e.target.id, e.target.name, e.checked!);
    };
    const setInitialView = (e: RadioButtonChangeEvent) => {
        const userSettings: TCalenderSettings = {
            ...state.userSettings,
            initialView: e.target.id
        };

        setStateValues({ userSettings: userSettings });
        // @ts-ignore
        setupVisibleOptions('initialView', '', e.target.id);
        calenderRef.current?.getApi().changeView(e.target.id);
    };

    const CalendarMenu = () => {
        return (
            <Fragment>
                <div className="flex align-items-center justify-content-between mb-4">
                    <h5>Make An Appointment</h5>
                    <div>
                        <Button type="button" className="p-button-rounded" onClick={(event) => setStateValues({ sideBarState: true })}>
                            Customise Calendar
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    };

    function renderEventContent(eventInfo: EventContentArg) {
        return (
            <>
                <Tag value={`${eventInfo.timeText} - ${eventInfo.event.title}`} severity="success"></Tag>
            </>
        );
    }

    const eventClicked = (args: EventClickArg) => {
        const { backgroundColor, borderColor, display, startStr, endStr, textColor, title, id } = args.event;

        setStateValues({
            appointmentId: id,
            appointmentDate: new Date(startStr),
            appointmentPatientId: args.event.extendedProps.patientId,
            showDialog: true,
            editState: true
        });
    };

    const dateClicked = (args: DateClickArg) => {
        confirmPopup({
            target: args.dayEl,
            message: 'Do you want to add new Appointment?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-success',
            rejectClassName: 'p-button-danger',
            acceptIcon: 'pi pi-check',
            rejectIcon: 'pi pi-times',
            accept: () => {
                setStateValues({
                    showDialog: true,
                    appointmentDate: new Date(args.dateStr)
                });
            },
            style: { width: '400px' }
        });
    };
    const onClickMoreLink = (args: MoreLinkArg) => {};
    const onCalendarDatesChange = (e: CalendarChangeEvent) => {
        setStateValues({ appointmentDate: e.value! });
    };
    const addNewAppointment = async () => {
        try {
            const appointmentInfo: TAppointment = {
                appointmentId: state.appointmentId,
                appointmentPatientId: state.appointmentPatientId,
                appointmentDate: `${format(new Date(state.appointmentDate as Date), 'yyyy-MM-dd')} ${format(new Date(state.appointmentDate as Date), 'HH:mm')}`
            };
            setStateValues({ isLoading: true });
            const savedAppointmentResponse = await appointment.createInstance(appointmentInfo);
            console.log(savedAppointmentResponse);
            if (savedAppointmentResponse.data.status === 1 && savedAppointmentResponse.data.operatedData !== undefined) {
                await addRecordToCache<TAppointment>(queryClient, ['appointmentsList'], savedAppointmentResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Appointment Confirmed',
                    message: 'Patient appointment was successfully saved against the selected date!',
                    infoType: 'success',
                    life: 5000
                });
            } else {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Appointment May Exists',
                    message: 'Patient appointment may exist for selected day.!',
                    infoType: 'info',
                    life: 5000
                });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const onEventDragDrop = async (eventDropInfo: EventDropArg) => {
        try {
            const newEvent = eventDropInfo.event;
            const appointmentDate = `${format(new Date(newEvent.startStr), 'yyyy-MM-dd')} ${format(new Date(newEvent.startStr), 'HH:mm')}`;
            const appointmentId = newEvent.id;
            const appointmentPatientId = newEvent.extendedProps.patientId;
            const appointmentInfo: TAppointment = { appointmentDate, appointmentId, appointmentPatientId };
            setStateValues({ isLoading: true });
            await performAppointmentUpdate(appointmentInfo);
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const updateAppointment = async () => {
        try {
            const appointmentInfo: TAppointment = {
                appointmentId: state.appointmentId,
                appointmentPatientId: state.appointmentPatientId,
                appointmentDate: `${format(new Date(state.appointmentDate as Date), 'yyyy-MM-dd')} ${format(new Date(state.appointmentDate as Date), 'HH:mm')}`
            };

            setStateValues({ isLoading: true });
            await performAppointmentUpdate(appointmentInfo);
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const performAppointmentUpdate = async (appointmentInfo: TAppointment) => {
        const updateAppointmentResponse = await appointment.updateInstance(appointmentInfo);

        if (updateAppointmentResponse.data.status === 1) {
            await updateCacheRecord<TAppointment>(queryClient, ['appointmentsList'], [updateAppointmentResponse.data.operatedData, appointmentInfo.appointmentId, 'appointmentId']);
            displayMessage({
                toastComponent: toastRef,
                header: 'Appointment Confirmed',
                message: 'Patient appointment was successfully updated against the selected date!',
                infoType: 'success',
                life: 5000
            });
        }
    };
    const deleteAppointment = async () => {
        try {
            setStateValues({ isLoading: true });
            const deleteAppointmentResponse = await appointment.deleteInstance(state.appointmentId);

            if (deleteAppointmentResponse.data.status === 1) {
                await deleteCacheRecord<TAppointment>(queryClient, ['appointmentsList'], [deleteAppointmentResponse.data.operatedData, state.appointmentId, 'appointmentId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Delete Success',
                    message: 'Appointment was successfully deleted!',
                    infoType: 'success',
                    life: 5000
                });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const onDialogHide = () => {
        setStateValues({ showDialog: false, editState: false });
    };
    const setupVisibleOptions = (stateProperty: string, stateValue: string, actionValue: boolean | string) => {
        const userSettings = {
            ...state.userSettings,
            [stateProperty]: actionValue
        };
        let menuArr = [];
        if (typeof actionValue === 'boolean' && actionValue) {
            menuArr = [stateValue, ...state.toolBarMenu];
        } else {
            menuArr = state.toolBarMenu.filter((menu: any) => menu !== stateValue);
        }
        const toolBarMenu = arrangeArray(menuArr);
        queryClient.setQueryData(['calendarSettings'], { userSettings, toolBarMenu });
        setStateValues({ ...state, userSettings, toolBarMenu });
    };
    const arrangeArray = (arr: string[]) => {
        // Define the desired order of elements
        const desiredOrder = ['multiMonthYear', 'dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listWeek'];

        // Filter the input array to include only the elements in the desired order
        const arrangedArray = desiredOrder.filter((item) => arr.includes(item));

        // Add any remaining elements from the input array
        arr.forEach((item) => {
            if (!arrangedArray.includes(item)) {
                arrangedArray.push(item);
            }
        });

        return arrangedArray;
    };
    const setShowWeekend = (e: CalendarChangeEvent) => {
        const userSettings: TCalenderSettings = { ...state.userSettings, showWeekend: e.checked! };

        setStateValues({ ...state, userSettings });
    };
    const setAllowDragDrop = (e: CalendarChangeEvent) => {
        const userSettings: TCalenderSettings = { ...state.userSettings, allowScroll: e.checked! };
        setStateValues({ ...state, userSettings });
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        selectControlChange(e, dispatch);
    };
    const onDialogShow = () => {
        if (!state.editState) {
            setStateValues({
                appointmentId: uuidv4()
            });
        }
    };
    const promptAppointmentSave = (event: React.MouseEvent<HTMLButtonElement>) => {
        promptUserAction({ yesAction: addNewAppointment, event, displayText: 'You are creating new appointment for patient. Do you want to proceed?' });
    };
    const promptAppointmentUpdate = (event: React.MouseEvent<HTMLButtonElement>) => {
        promptUserAction({ yesAction: updateAppointment, event, displayText: 'You are updating the selected appointment. Do you want to proceed?' });
    };
    const promptAppointmentDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        promptUserAction({ yesAction: deleteAppointment, event, displayText: 'You are deleting the selected appointment. Do you want to proceed?' });
    };
    const renderWeekNumberContent = (args: WeekNumberContentArg) => {
        return <span className="text-2xl">{args.num}</span>;
    };
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="card">
                <div className="grid">
                    <div className="lg:col-12">
                        <CalendarMenu />
                        <FullCalendar
                            ref={calenderRef}
                            plugins={[multiMonthPlugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                            initialView={state.userSettings.initialView}
                            headerToolbar={{
                                left: 'prev,next today', // Left-aligned buttons
                                center: 'title', // Centered title
                                right: state.toolBarMenu.join(',') // Right-aligned buttons
                            }}
                            views={{
                                multiMonthYear: { buttonText: 'Year' },
                                dayGridMonth: { buttonText: 'Month' },
                                timeGridWeek: { buttonText: 'Week' },
                                timeGridDay: { buttonText: 'Day' },
                                listWeek: { buttonText: 'List' }
                            }}
                            weekends={state.userSettings.showWeekend}
                            events={state.calendarEvents}
                            eventContent={renderEventContent}
                            weekNumberContent={renderWeekNumberContent}
                            eventClick={eventClicked}
                            eventColor={'#8c4dc7'}
                            eventDrop={onEventDragDrop}
                            droppable={true}
                            dateClick={dateClicked}
                            editable={true}
                            selectable={state.userSettings.allowScroll}
                            dragScroll={state.userSettings.allowScroll}
                            showNonCurrentDates={false}
                            fixedWeekCount={false}
                            eventMaxStack={3}
                            dayMaxEvents={2}
                            height="auto"
                            dayCellContent={renderDayNumber}
                            moreLinkClick={onClickMoreLink}
                        />
                    </div>
                </div>
            </div>
            <Sidebar visible={state.sideBarState} position="right" onHide={() => setStateValues({ sideBarState: false })}>
                <h6>Calendar Settings</h6>
                <div className="card p-fluid">
                    <h5>Plugins</h5>
                    <CheckboxInput inputName="multiMonthYear" checkLabel="Show Year Option" checkedState={state.userSettings.showYearGrid} inputId="showYearGrid" onCheckChange={setUserSetting} />
                    <CheckboxInput inputName="timeGridWeek" checkLabel="Show Week Option" checkedState={state.userSettings.showWeekGrid} inputId="showWeekGrid" onCheckChange={setUserSetting} />
                    <CheckboxInput inputName="listWeek" checkLabel="Show List View" checkedState={state.userSettings.showListView} inputId="showListView" onCheckChange={setUserSetting} />
                </div>
                <div className="card p-fluid">
                    <h5>Initial View</h5>
                    <RadioButtonInput radioLabel="Year" inputId="multiMonthYear" inputName="initialYear" onRadioChange={setInitialView} checkedValue={state.userSettings.initialView === 'multiMonthYear'} customClasses="mt-2" />
                    <RadioButtonInput radioLabel="Month" inputId="dayGridMonth" inputName="initialMonth" onRadioChange={setInitialView} checkedValue={state.userSettings.initialView === 'dayGridMonth'} customClasses="mt-2" />
                    <RadioButtonInput radioLabel="Week" inputId="timeGridWeek" inputName="initialWeek" onRadioChange={setInitialView} checkedValue={state.userSettings.initialView === 'timeGridWeek'} customClasses="mt-2" />
                    <RadioButtonInput radioLabel="Day" inputId="timeGridDay" inputName="initialDay" onRadioChange={setInitialView} checkedValue={state.userSettings.initialView === 'timeGridDay'} customClasses="mt-2" />
                </div>
                <div className="card p-fluid">
                    <h5>Other Options</h5>
                    <CheckboxInput inputName="visibleWeekendRadio" checkLabel="Show Weekends" checkedState={state.userSettings.showWeekend} inputId="showWeekend" onCheckChange={setShowWeekend} />
                    <CheckboxInput inputName="chkAllowDragDrop" checkLabel="Allow Drag/Drop" checkedState={state.userSettings.allowScroll} inputId="allowDragDrop" onCheckChange={setAllowDragDrop} />
                </div>
            </Sidebar>
            <Dialog
                header={!state.editState ? `Schedule An Appointment` : `Update An Appointment`}
                focusOnShow={true}
                className="lg:w-5"
                onHide={onDialogHide}
                onShow={onDialogShow}
                visible={state.showDialog}
                position="top-right"
                closeOnEscape={true}
                closable={true}
                dismissableMask={true}
            >
                <div className="formgrid grid">
                    <div className="field lg:col-6 md:col-12 col-12">
                        <label htmlFor="appointmentDate">Appointment Date</label>
                        <DatePicker dateValue={state.appointmentDate} onDateChange={onCalendarDatesChange} labelText="Appointment Date" controlId="appointmentDate" selectionType="single" displayButtonBar={true} displayTime={true} />
                    </div>
                    <div className="field lg:col-6 md:col-12 col-12">
                        <FilterSelect selectableOptions={state.patientsList} selectedOption={state.appointmentPatientId} onSelectChange={onSelectChange} elementId="appointmentPatientId" defaultValue="Patients Available" />
                    </div>
                </div>

                <div className="flex align-content-evenly flex-wrap">
                    <Button className="flex align-items-center justify-content-center" onClick={state.editState === false ? promptAppointmentSave : promptAppointmentUpdate}>
                        {state.editState === false ? `Book Appointment` : `Update Appointment`}
                    </Button>
                    {state.editState === true && (
                        <Button className="flex align-items-center justify-content-center ml-3 p-button-danger" onClick={promptAppointmentDelete}>
                            Delete Appointment
                        </Button>
                    )}
                </div>
            </Dialog>
        </>
    );
};
export default Appointments;
