import { CheckboxInput, DiscountComponent, FilterSelect, IconTextInput } from '../../utils/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { TDiagnosisContext, TPaymentInstrument, TTreatmentProcedure } from '../../utils/typesUtil';
import { InputText } from 'primereact/inputtext';
import { DiagnosisCenterContext } from '../DiagnosisCenter';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { SelectButtonChangeEvent } from 'primereact/selectbutton';
import { displayMessage } from '../../utils/utils';
import { Toast } from 'primereact/toast';

const _ = require('lodash');

const Billing = () => {
    const { setStateValues, state } = useContext<Partial<TDiagnosisContext>>(DiagnosisCenterContext!);
    const opRef = useRef<OverlayPanel | null>(null);
    const toastRef = useRef<Toast>(null);
    const discountOPRef = useRef<OverlayPanel | null>(null);
    const [billingState, setBillingState] = useState<TPaymentInstrument>({
        item: '',
        price: '0'
    });

    useEffect(() => {
        getProcedureCost();
    }, [state?.selectedProcedurePayer]);
    const getProcedureCost = () => {
        let registrationFee = 0;
        let consultationFee = 0;
        //Get all selected procedures from the treatment procedures checked by user;
        const selectedProcedures: TTreatmentProcedure[] = _.filter(state?.treatmentProcedures, (treatmentProcedure: TTreatmentProcedure) => _.includes(state?.selectedTreatments, treatmentProcedure.procedureDescription));

        //find the drug and procedure fee payment instrument
        const drugPaymentInstrument = state?.paymentInstruments.find((instrument: TPaymentInstrument) => instrument.item === 'Drugs Fee')!;
        const procedureFeePaymentInstrument = state?.paymentInstruments.find((instrument: TPaymentInstrument) => instrument.item === 'Procedure Fee')!;
        const registrationInstrument = state?.paymentInstruments.find((instrument: TPaymentInstrument) => instrument.item === 'Registration Fee')!;
        const consultationInstrument = state?.paymentInstruments.find((instrument: TPaymentInstrument) => instrument.item === 'Consultation Fee')!;

        //calculate the accumulated cost for each of the payment instrument selected above
        const drugCost = state?.prescriptionForDiagnosis.reduce((previousValue, currentValue) => {
            return previousValue + parseFloat(currentValue.subTotal);
        }, 0);
        const procedureFee = selectedProcedures.reduce((previousValue, currentValue) => {
            let procedureCost = 0;
            switch (state?.selectedProcedurePayer) {
                case 'Walk-In':
                    procedureCost = parseFloat(currentValue.walkInFees);
                    break;
                case 'Insurance':
                    procedureCost = parseFloat(currentValue.insuranceFees);
                    break;
                case 'Company':
                    procedureCost = parseFloat(currentValue.companyFees);
                    break;
                default:
                    procedureCost = parseFloat(currentValue.walkInFees);
            }
            return previousValue + procedureCost;
        }, 0);
        switch (state?.selectedProcedurePayer) {
            case 'Walk-In':
                registrationFee = 30;
                consultationFee = 50;
                break;
            case 'Insurance':
                registrationFee = 50;
                consultationFee = 100;
                break;
            case 'Company':
                registrationFee = 100;
                consultationFee = 200;
                break;
            default:
                registrationFee = 30;
                consultationFee = 50;
        }
        //set the price on their objects as reference are still being held to them
        drugPaymentInstrument.price = drugCost!.toFixed(2);
        procedureFeePaymentInstrument.price = procedureFee.toFixed(2);
        registrationInstrument.price = registrationFee.toFixed(2);
        consultationInstrument.price = consultationFee.toFixed(2);

        setStateValues!({
            paymentInstruments: state?.paymentInstruments,
            selectedInstruments: [...state?.selectedInstruments!, drugPaymentInstrument.item, procedureFeePaymentInstrument.item, 'Registration Fee', 'Consultation Fee']
        });
    };
    const onPaymentInstrumentChange = (e: CheckboxChangeEvent) => {
        if (e.checked) {
            setStateValues!({
                selectedInstruments: _.concat(state?.selectedInstruments, e.value)
            });
        } else {
            setStateValues!({
                selectedInstruments: _.without(state?.selectedInstruments, e.value)
            });
        }
    };

    const paymentPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const changingItem = state?.paymentInstruments.find((instrument: TPaymentInstrument) => instrument.item === e.target.id)!;
        changingItem.price = e.target.value;
        setStateValues!({ paymentInstruments: state?.paymentInstruments });
    };
    const onAddInstrumentItem = () => {
        if (
            _.findIndex(state?.selectedInstruments, function (o: string) {
                return o === billingState.item;
            }) === -1
        ) {
            setStateValues!({
                paymentInstruments: [...state?.paymentInstruments!, { item: billingState.item, price: billingState.price }],
                selectedInstruments: [...state?.selectedInstruments!, billingState.item]
            });
        }
    };
    const onItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBillingState((prevState) => {
            return { ...prevState, [e.target.id]: e.target.value };
        });
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setStateValues!({ patientStatus: e.value });
    };

    const patientBillSummary = () => {
        return state?.paymentInstruments.reduce((previousValue, currentValue) => {
            return previousValue + parseFloat(currentValue.price);
        }, 0);
    };
    const onDiscountOptionChange = (e: SelectButtonChangeEvent) => {
        setStateValues!({ billDiscount: { ...state?.billDiscount!, discountOption: e.value } });
    };
    const applyDiscount = () => {
        const totalBill = patientBillSummary()!;
        const discountPercent = state?.billDiscount.discountOption === 'Cash Discount' ? (parseFloat(state.discountValue) / totalBill) * 100 : parseFloat(state?.discountValue!);
        const discountAmount = state?.billDiscount.discountOption === 'Cash Discount' ? state.discountValue : ((parseFloat(state?.discountValue!) / 100) * totalBill).toFixed(2);
        setStateValues!({ billDiscount: { ...state?.billDiscount!, discountAmount, discountPercentage: discountPercent } });
        displayMessage({
            toastComponent: toastRef,
            header: 'Discount Applied',
            message: 'Discount was successfully applied to Bill',
            infoType: 'success',
            life: 5000
        });
    };
    return (
        <>
            <div className="card p-fluid max-h-30rem fadeinleft animation-duration-500 overflow-auto">
                <div className="flex mb-3">
                    <div className="flex align-items-start justify-content-start">
                        <Button icon="pi pi-plus" className="bg-transparent" rounded tooltip="Add New Billing Item" onClick={(e) => opRef.current?.toggle(e)} />
                        <Button icon="pi pi-calendar" className="bg-transparent ml-3" rounded tooltip="Make Appoint With Patient" onClick={() => setStateValues!({ showAppointmentDialog: true })} />
                        <Button icon="pi pi-dollar" className="bg-transparent ml-3" rounded tooltip="Give Patient Discount" onClick={(e) => discountOPRef.current?.toggle(e)} />
                    </div>
                    <div className="flex align-items-start justify-content-start"></div>
                    <div className="flex align-items-start justify-content-start"></div>
                </div>
                <div className="grid">
                    <div className="col-4">
                        {state!.paymentInstruments.length > 0 &&
                            state?.paymentInstruments.map((payInstrument: TPaymentInstrument, index: number) => {
                                return (
                                    <div className="grid" key={`sfds${index}`}>
                                        <div className="col-6">
                                            <CheckboxInput
                                                inputName={payInstrument.item}
                                                checkLabel={payInstrument.item}
                                                checkedState={state!.selectedInstruments.includes(payInstrument.item)}
                                                inputId={`b${payInstrument.item}`}
                                                onCheckChange={onPaymentInstrumentChange}
                                                disabled={payInstrument.item === 'Drugs Fee'}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <InputText type="number" value={payInstrument?.price?.toString()} onChange={paymentPriceChange} id={`${payInstrument.item}`} disabled={payInstrument.item === 'Drugs Fee'} />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="col-4">
                        <h6 className="underline">Bill Summary</h6>
                        <div className="p-fluid">
                            <div className="grid p-formgrid">
                                <div className="field lg:col-6 md:col-12 col-12">Arrears</div>
                                <div className="field lg:col-6 md:col-12 col-12">{state?.patientDebtAmount}</div>
                            </div>
                            <div className="grid p-formgrid">
                                <div className="field lg:col-6 md:col-12 col-12">Total Bill Cost</div>
                                <div className="field lg:col-6 md:col-12 col-12">{patientBillSummary()}</div>
                            </div>
                            <div className="grid p-formgrid">
                                <div className="field lg:col-6 md:col-12 col-12">Discount Allowed</div>
                                <div className="field lg:col-6 md:col-12 col-12">{state?.billDiscount?.discountAmount}</div>
                            </div>
                            <div className="grid p-formgrid">
                                <div className="field lg:col-6 md:col-12 col-12">Patient Bill</div>
                                <div className="field lg:col-6 md:col-12 col-12">{patientBillSummary()! + state?.patientDebtAmount! - parseFloat(state?.billDiscount?.discountAmount!)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="p-fluid">
                            <div className="grid p-formgrid">
                                <IconTextInput
                                    value={state?.diagnosisDescription}
                                    onInputChange={(e) => setStateValues!({ diagnosisDescription: e.target.value })}
                                    placeholderValue="Diagnosis Description"
                                    iconText="pi pi-pencil"
                                    componentId="diagnosisDescription"
                                    customClasses="lg:col-12 md:col-12 col-12"
                                />
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <FilterSelect selectableOptions={state?.diagnosisStatus!} selectedOption={state?.patientStatus!} onSelectChange={onSelectChange} elementId="patientStatus" defaultValue="Patient Status" />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <FilterSelect
                                        selectableOptions={state?.procedurePayerList!}
                                        selectedOption={state?.selectedProcedurePayer!}
                                        onSelectChange={(e) => setStateValues!({ selectedProcedurePayer: e.value })}
                                        elementId="selectedProcedurePayer"
                                        defaultValue="Bills Funded by:"
                                        tooltip="This selection helps in determing the procedure cost for patient."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OverlayPanel className="lg:w-5" ref={opRef}>
                <Card title="New Billing Item">
                    <div className="grid">
                        <IconTextInput value={billingState.item} onInputChange={onItemChange} placeholderValue="New Item" iconText="pi pi-briefcase" componentId="item" customClasses="lg:col-6 md:col-12 col-12" />
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="price">Item Cost</label>
                            <br />
                            <InputText type="number" value={billingState.price} onChange={onItemChange} id="price" />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <Button onClick={onAddInstrumentItem}>Add Item</Button>
                        </div>
                    </div>
                </Card>
            </OverlayPanel>
            <DiscountComponent
                discountOPRef={discountOPRef}
                discountOptions={state?.discountOptions!}
                onDiscountOptionChange={onDiscountOptionChange}
                discountSelectedOption={state?.billDiscount.discountOption!}
                discountAmount={state?.discountValue!}
                onDiscountAmountChange={(e) => setStateValues!({ discountValue: e.target.value })}
                applyDiscountAction={applyDiscount}
            />
        </>
    );
};
export default Billing;
