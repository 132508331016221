import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import React, { useContext } from 'react';
import { TDiagnosisContext } from '../../utils/typesUtil';
import { DiagnosisCenterContext } from '../DiagnosisCenter';
import { Chip } from 'primereact/chip';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
const _ = require('lodash');

const MedicalDiagnosis = () => {
    const { setStateValues, state } = useContext<Partial<TDiagnosisContext>>(DiagnosisCenterContext!);
    const onConditionSelect = (e: MultiSelectChangeEvent) => {
        setStateValues!({ selectedMedicalConditions: e.value });
    };
    const onConditionRemoved = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        // @ts-ignore/
        setStateValues!({ selectedMedicalConditions: _.without(state?.selectedMedicalConditions, e.target.parentNode.id) });
    };
    const onOtherMedicalDiagnosisChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateValues!({
            otherValue: e.target.value
        });
    };
    const onAddOtherMedicalDiagnosis = () => {
        if (state?.otherValue === '') return;
        setStateValues!({ otherMedicalDiagnosis: [...state?.otherMedicalDiagnosis!, state?.otherValue!], otherValue: '' });
    };
    return (
        <>
            <div className="card p-fluid max-h-30rem h-30rem fadeinleft animation-duration-500">
                <div className="grid">
                    <div className="col-8">
                        <div className="grid">
                            <div className="col-5">
                                <h6>List of Diagnosis</h6>
                                <MultiSelect
                                    value={state?.selectedMedicalConditions}
                                    onChange={onConditionSelect}
                                    options={state?.medicalDiagnosisConditions}
                                    optionLabel="name"
                                    filter
                                    placeholder="Select Conditions"
                                    maxSelectedLabels={10}
                                    className="w-full"
                                    scrollHeight="300px"
                                />
                            </div>
                            <div className="col-7">
                                <h6>Selections</h6>
                                <div className="card flex flex-wrap gap-2 max-h-25rem overflow-scroll overflow-y-auto overflow-x-auto">
                                    {state?.selectedMedicalConditions.map((medicalCondition: string, index: number) => {
                                        return (
                                            <div key={`key${index}`}>
                                                <Chip className="text-cyan-200" id={medicalCondition} label={medicalCondition} key={index} removable onRemove={onConditionRemoved} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <h6>Others</h6>
                        <InputText value={state?.otherValue} onChange={onOtherMedicalDiagnosisChange} />
                        <div className="col-3">
                            <Button onClick={onAddOtherMedicalDiagnosis} className="mt-2">
                                Add
                            </Button>
                        </div>
                        <Card className="card mt-2" subTitle="Other Medical Diagnosis">
                            {state?.otherMedicalDiagnosis.map((other: string, index) => {
                                return <Chip className="text-cyan-200" id={other} label={other} key={`omv${index}`} removable />;
                            })}
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MedicalDiagnosis;
