import { DropdownOption, IEmergencyContact, IPatient, IPatientAndEmergencyContact, MenuModel, TDebtor, TGeneralPage, TPatientAndEmergency, TUsers } from '../utils/typesUtil';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import {
    AppUserContext,
    configureExcelUpload,
    customReducer,
    displayCountries,
    displayGenders,
    displayMessage,
    displayReligions,
    displayStatusType,
    displayTitles,
    getBaseURL,
    getRandomDateOfBirth,
    getTableRowId,
    inputChange,
    pageDataValidation,
    promptUserAction,
    REDUCER_ACTION_TYPE,
    selectControlChange,
    sliceObject
} from '../utils/utils';
import FileUploader, { DatePicker, FilterSelect, GeneralPageProps, IconTextInput, Loader, SimpleTableWithMenu, tableEditOption } from '../utils/components';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { CalendarChangeEvent } from 'primereact/calendar';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { v4 as uuidv4 } from 'uuid';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import Joi from 'joi';
import { Button } from 'primereact/button';
import PatientsInfo from '../classes/PatientsInfo';
import { useQueryClient } from '@tanstack/react-query';
import { addRecordsToCache, addRecordToCache, updateCacheRecord, useAccumulatedDebtorsListFetch, usePatientsListFetch } from '../utils/reactQueryUtils';
import { useNavigate } from 'react-router-dom';
import { FileUploadHandlerEvent } from 'primereact/fileupload';
const _ = require('lodash');

type TPatient = IPatient &
    TGeneralPage<IPatient> &
    IEmergencyContact & { genders: DropdownOption[]; insuredStatusTypes: DropdownOption[]; activeIndex: number; titles: DropdownOption[]; countriesList: DropdownOption[]; showUploadDialog: boolean; religions: DropdownOption[] };
const INITIAL_STATE: TPatient = {
    editingObjectId: '',
    editingState: false,
    isLoading: false,
    showDialog: false,
    company: '',
    dateOfBirth: new Date(),
    emailAddress: '',
    firstName: '',
    insuranceStatus: 'Insured',
    insuranceType: '',
    lastName: '',
    location: '',
    nationality: '',
    otherNames: '',
    patientId: '',
    religion: '',
    title: '',
    genders: [],
    gender: '',
    phoneNumber: '',
    occupation: '',
    insuredStatusTypes: [],
    sponsorshipLimit: '',
    activeIndex: 0,
    emergencyContactTitle: '',
    emergencyFirstName: '',
    emergencyLastName: '',
    emergencyOtherName: '',
    emergencyPhoneNumber: '',
    emergencyRelationship: '',
    titles: [],
    countriesList: [],
    showUploadDialog: false,
    religions: [],
    patientCode: ''
};

const validatePatient: Joi.ObjectSchema<IPatient> = Joi.object({
    patientId: Joi.string().messages({ 'string.empty': 'Patient ID is missing. Retry again' }),
    title: Joi.string().allow(''),
    firstName: Joi.string().messages({ 'string.empty': 'Patient first name is required. Enter a valid first Name' }),
    lastName: Joi.string().messages({ 'string.empty': 'Patient Last name is required. Enter a valid last name' }),
    otherNames: Joi.string().optional().allow(''),
    dateOfBirth: Joi.date().messages({ 'date.base': 'Select date of birth for patient' }),
    gender: Joi.string().messages({ 'string.empty': 'Select patient gender' }),
    phoneNumber: Joi.string().messages({ 'string.empty': 'Add patient Phone Number' }),
    emailAddress: Joi.string().allow(''),
    nationality: Joi.string().messages({ 'string.empty': 'Enter Patient Nationality' }),
    location: Joi.string().messages({ 'string.empty': 'Add patient location address' }),
    occupation: Joi.string().allow(''),
    religion: Joi.string().allow(''),
    insuranceStatus: Joi.string().allow(''),
    company: Joi.string().allow(''),
    sponsorshipLimit: Joi.number().allow(''),
    patientCode: Joi.string().messages({ 'string.empty': 'Patient Code should be entered before proceeding' })
});
const validateEmergencyContact: Joi.ObjectSchema<IPatient> = Joi.object({
    emergencyContactTitle: Joi.string().messages({ 'string.empty': 'Select a title for emergency contact' }),
    emergencyFirstName: Joi.string().messages({ 'string.empty': 'Add first name for emergency contact' }),
    emergencyLastName: Joi.string().messages({ 'string.empty': 'Add last name for emergency contact' }),
    emergencyOtherName: Joi.string().optional().allow(''),
    emergencyPhoneNumber: Joi.string().messages({ 'string.empty': 'Enter phone number for emergency contact' }),
    emergencyRelationship: Joi.string().messages({ 'string.empty': 'Enter emergency contact relation to patient' })
});

const patient = new PatientsInfo();
const Patient = () => {
    const navigate = useNavigate();
    const user = useContext<TUsers>(AppUserContext);
    const queryClient = useQueryClient();
    const uploadComponentRef = useRef(null);
    const [state, dispatch] = useReducer(customReducer<TPatient>, INITIAL_STATE);
    const toastRef = useRef<Toast>(null);
    const { data: debtorsList, isLoading: debtorsLoading } = useAccumulatedDebtorsListFetch({ urlLink: `${getBaseURL()}/bills/get_accumulated_debtors_list` });
    const { data: patientsList, isLoading, refetch, dataUpdatedAt } = usePatientsListFetch({ urlLink: `${getBaseURL()}/patients/get_all_patients` });

    useEffect(() => {
        if (!user) navigate('/login');
        setStateValues({
            genders: displayGenders(),
            insuredStatusTypes: displayStatusType(),
            titles: displayTitles(),
            countriesList: displayCountries(),
            patientId: uuidv4(),
            religions: displayReligions()
        });
    }, []);
    const setStateValues = (stateValues: Partial<TPatient>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };
    if (isLoading) return <Loader />;
    const getStateValues = (): Partial<IPatient> => {
        const props: Array<keyof IPatient> = [
            'title',
            'patientId',
            'firstName',
            'lastName',
            'otherNames',
            'dateOfBirth',
            'gender',
            'phoneNumber',
            'location',
            'emailAddress',
            'nationality',
            'occupation',
            'religion',
            'insuranceStatus',
            'company',
            'sponsorshipLimit',
            'patientCode'
        ];
        return sliceObject(props, state);
    };
    const getEmergencyStateValues = (): Partial<TPatient> => {
        const props: Array<keyof TPatient> = ['emergencyContactTitle', 'emergencyFirstName', 'emergencyLastName', 'emergencyOtherName', 'emergencyPhoneNumber', 'emergencyRelationship'];
        return sliceObject(props, state);
    };
    const validatePatientData = () => {
        return pageDataValidation(validatePatient, getStateValues(), toastRef);
    };
    const validateEmergencyContactData = () => {
        if (state.activeIndex === 0) return true;
        return pageDataValidation(validateEmergencyContact, getEmergencyStateValues(), toastRef);
    };
    const patientsMenu = (): MenuModel[] => {
        return [
            {
                label: 'New Patient',
                icon: 'pi pi-plus',
                command: () => setStateValues({ showDialog: true })
            },
            {
                label: 'Refresh Table',
                icon: 'pi pi-refresh',
                command: () => refetch()
            },
            {
                label: 'Upload Patients',
                icon: 'pi pi-upload',
                command: () => setStateValues({ showUploadDialog: true })
            }
        ];
    };
    const controlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        inputChange(e, dispatch);
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        selectControlChange(e, dispatch);
    };
    const onTabViewChange = (e: TabViewTabChangeEvent) => {};
    const goToEmergencyContact = (e: React.MouseEvent<HTMLButtonElement>) => {
        setStateValues({ activeIndex: 1 });
    };
    const goBack = (e: React.MouseEvent<HTMLButtonElement>) => {
        setStateValues({ activeIndex: 0 });
    };
    const savePatient = async () => {
        try {
            const patientData = { patientData: getStateValues(), emergencyContact: getEmergencyStateValues() } as IPatientAndEmergencyContact;
            setStateValues({ isLoading: true });
            const savePatientResponse = await patient.createInstance(patientData);
            if (savePatientResponse.data.status === 1) {
                await addRecordToCache<IPatientAndEmergencyContact>(queryClient, ['patientsList'], savePatientResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'New patient was successfully saved with the supplied credentials',
                    infoType: 'success',
                    life: 5000
                });
                resetPatientPage();
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false, patientId: uuidv4() });
        }
    };
    const resetPatientPage = () => {
        setStateValues({
            patientId: uuidv4(),
            title: '',
            firstName: '',
            lastName: '',
            otherNames: '',
            dateOfBirth: new Date(),
            gender: '',
            emailAddress: '',
            nationality: '',
            location: '',
            insuranceStatus: '',
            company: '',
            emergencyFirstName: '',
            emergencyLastName: '',
            emergencyOtherName: '',
            emergencyPhoneNumber: '',
            emergencyRelationship: '',
            emergencyContactTitle: '',
            patientCode: '',
            isLoading: false
        });
    };
    const setupEditPatient = (e: React.MouseEvent<HTMLButtonElement>) => {
        const clickedPatient = getTableRowId(e, 'id');
        const selectedPatient = patientsList?.find((patient: TPatientAndEmergency) => patient.patientId === clickedPatient);
        if (selectedPatient) {
            const {
                patientId,
                title,
                firstName,
                lastName,
                otherNames,
                dateOfBirth,
                gender,
                phoneNumber,
                emailAddress,
                nationality,
                location,
                occupation,
                religion,
                insuranceStatus,
                company,
                sponsorshipLimit,
                patientCode,
                emergencyFirstName,
                emergencyLastName,
                emergencyOtherName,
                emergencyPhoneNumber,
                emergencyRelationship,
                emergencyContactTitle
            } = selectedPatient;
            setStateValues({
                patientId,
                title,
                firstName,
                lastName,
                otherNames,
                dateOfBirth: new Date(dateOfBirth as Date),
                gender,
                phoneNumber,
                emailAddress,
                nationality,
                location,
                occupation,
                religion,
                insuranceStatus,
                company,
                sponsorshipLimit,
                patientCode: patientCode ? patientCode : '',
                emergencyContactTitle,
                emergencyFirstName,
                emergencyLastName,
                emergencyOtherName,
                emergencyPhoneNumber,
                emergencyRelationship,
                editingState: true,
                editingObjectId: clickedPatient,
                showDialog: true
            });
        }
    };
    const setupDeletePatient = () => {};
    const updatePatient = async () => {
        try {
            if (!validatePatientData() || !validateEmergencyContactData()) return;
            const patientData = { patientData: getStateValues(), emergencyContact: getEmergencyStateValues() } as IPatientAndEmergencyContact;
            setStateValues({ isLoading: true });
            const updateResponse = await patient.updateInstance(patientData);

            if (updateResponse.data.status === 1) {
                await updateCacheRecord(queryClient, ['patientsList'], [updateResponse.data.operatedData, state.editingObjectId, 'patientId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Selected Patient was successfully updated with the supplied credentials',
                    infoType: 'success',
                    life: 3000
                });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const promptPatientSave = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!validatePatientData() || !validateEmergencyContactData()) return;
        promptUserAction({ yesAction: savePatient, event, displayText: 'This will add patient to storage. Are you sure you want to proceed?' });
    };
    const promptPatientUpdate = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!validatePatientData() || !validateEmergencyContactData()) return;
        promptUserAction({ yesAction: updatePatient, event, displayText: 'This will update currently selected patient. Are you sure you want to proceed?' });
    };
    const promptDebtorPatient = (event: React.MouseEvent<HTMLButtonElement>, debtAmount: number) => {
        promptUserAction({
            yesAction: () => bookPatientConsultation(event),
            event,
            displayText: `This patient has an unpaid debt balance of ${debtAmount}. Click on Yes to book patient for consultation.`,
            acceptLabel: 'Ok. Allow for consultation',
            rejectLabel: 'Reject Booking Process'
        });
    };
    const setupConsultation = (event: React.MouseEvent<HTMLButtonElement>) => {
        const patientId = getTableRowId(event, 'id');
        const patientDebtor = _.find(debtorsList, (listItem: TDebtor) => listItem.patientId === patientId);
        if (patientDebtor) {
            promptDebtorPatient(event, patientDebtor.balance);
        } else {
            promptUserAction({ yesAction: () => bookPatientConsultation(event), event, displayText: 'Patient will be added to consultation to list to see Doctor Today. Proceed?' });
        }
    };
    const bookPatientConsultation = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const patientId = getTableRowId(e, 'id');
        try {
            setStateValues({ isLoading: true });
            const bookConsultationResponse = await patient.bookConsultation(patientId);
            if (bookConsultationResponse.data.status === 1) {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Booking Success',
                    message: 'Patient was successfully schedule for consultation today',
                    infoType: 'success',
                    life: 5000
                });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const generateRandomThings = (randomList: string[]) => {
        return randomList[Math.floor(Math.random() * 2)];
    };
    const onUploadFile = async (event: FileUploadHandlerEvent) => {
        try {
            const file = event.files[0];
            const uploadFileHeaders = [
                'title',
                'firstName',
                'lastName',
                'otherNames',
                'dateOfBirth',
                'gender',
                'phoneNumber',
                'location',
                'emailAddress',
                'nationality',
                'occupation',
                'company',
                'sponsorshipLimit',
                'emergencyContactTitle',
                'emergencyFirstName',
                'emergencyLastName',
                'emergencyOtherName',
                'emergencyPhoneNumber',
                'emergencyEmailAddress'
            ];

            const uploadData: TPatientAndEmergency[] = await configureExcelUpload(file, uploadFileHeaders, uploadFileHeaders);

            const refinedUploadData: Partial<TPatientAndEmergency>[] = uploadData.map((patient: Partial<TPatientAndEmergency>) => {
                const {
                    title,
                    firstName,
                    lastName,
                    otherNames,
                    gender,
                    phoneNumber,
                    location,
                    emailAddress,
                    company,
                    nationality,
                    sponsorshipLimit,
                    occupation,
                    emergencyContactTitle,
                    emergencyFirstName,
                    emergencyLastName,
                    emergencyOtherName,
                    emergencyRelationship,
                    emergencyPhoneNumber
                } = patient;
                const patientId = uuidv4();
                return {
                    patientData: {
                        title,
                        patientId,
                        firstName,
                        lastName,
                        otherNames,
                        dateOfBirth: getRandomDateOfBirth(),
                        gender,
                        phoneNumber,
                        location,
                        emailAddress,
                        company,
                        nationality,
                        sponsorshipLimit,
                        occupation,
                        religion: generateRandomThings(['Christian', 'Muslim']),
                        insuranceStatus: generateRandomThings(['Insured', 'Uninsured']),
                        emergencyRelationship: generateRandomThings(['Husband', 'Wife'])
                    },
                    emergencyContact: {
                        emergencyContactTitle,
                        emergencyFirstName,
                        emergencyLastName,
                        emergencyOtherName,
                        emergencyRelationship,
                        emergencyPhoneNumber
                    },
                    patientId: patientId
                };
            });

            setStateValues({ isLoading: true });
            const responseData = await patient.uploadPatientsData(refinedUploadData);

            if (responseData.data.status === 1) {
                await addRecordsToCache(queryClient, ['patientsList'], responseData.data.operatedData);
                displayMessage({
                    header: 'Upload Success',
                    message: 'Patients List were successfully uploaded',
                    infoType: 'success',
                    toastComponent: toastRef,
                    life: 5000
                });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    return (
        <>
            {state.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={'patientId'}
                    columnsDef={[
                        { field: 'patientCode', header: 'Code' },
                        { field: 'fullName', header: 'Name' },
                        { field: 'gender', header: 'Gender' },
                        { field: 'phoneNumber', header: 'Phone' },
                        { field: 'location', header: 'Location' },
                        { field: 'insuranceStatus', header: 'Insurance' },
                        // {field: 'emailAddress', header: 'Email'},
                        {
                            body: (row: IPatient) => (
                                <div>
                                    <Button id={row.patientId} icon="pi pi-external-link" className="p-button-rounded p-button-success mr-2" onClick={setupConsultation} />
                                </div>
                            ),
                            header: 'Booking'
                        },
                        { body: (rowData: TPatientAndEmergency) => tableEditOption(setupEditPatient, setupDeletePatient, rowData.patientId), header: 'Edit' }
                    ]}
                    tableData={patientsList}
                    menuModel={patientsMenu()}
                    hasMenuList={true}
                    tableTitle="Patients List"
                    lastTableUpdate={dataUpdatedAt}
                    childTableDef={[
                        { field: 'emergencyFullName', header: 'Name' },
                        { field: 'emergencyPhoneNumber', header: 'Phone' },
                        { field: 'emergencyRelationship', header: 'Relation' }
                    ]}
                    searchValues={['fullName', 'phoneNumber', 'patientId', 'location']}
                    searchFieldPlaceHolder="Search by Full Name, Phone Number, Location, or Patient Code"
                    childTableHeading="Emergency Contact"
                />
            </div>
            <Dialog onHide={() => setStateValues({ showDialog: false, editingState: false })} visible={state.showDialog} closeOnEscape={false} header="New Patient" position="top-right" className="lg:w-7">
                <TabView activeIndex={state.activeIndex} onTabChange={onTabViewChange}>
                    <TabPanel header="Patient Info" rightIcon="pi pi-user ml-2">
                        <div className="p-fluid">
                            <div className="grid p-formgrid">
                                <div className="field lg:col-4 md:col-12 col-12">
                                    <FilterSelect selectableOptions={state.titles} selectedOption={state.title} onSelectChange={onSelectChange} elementId="title" defaultValue="Title" />
                                </div>
                                <IconTextInput value={state.firstName} onInputChange={controlChange} placeholderValue="First Name" iconText="pi pi-user" componentId="firstName" customClasses="lg:col-4 md:col-12 col-12" />
                                <IconTextInput value={state.lastName} onInputChange={controlChange} placeholderValue="Last Name" iconText="pi pi-user" componentId="lastName" customClasses="lg:col-4 md:col-12 col-12" />
                                <IconTextInput value={state.otherNames} onInputChange={controlChange} placeholderValue="Other Names" iconText="pi pi-user" componentId="otherNames" customClasses="lg:col-4 md:col-12 col-12" />
                                <div className="field lg:col-4 md:col-12 col-12">
                                    <label htmlFor="dateOfBirth">Date of Birth</label>
                                    <DatePicker
                                        dateValue={state.dateOfBirth}
                                        onDateChange={(e: CalendarChangeEvent) => setStateValues({ dateOfBirth: e.value! })}
                                        labelText="Date Of Birth"
                                        controlId="dateOfBirth"
                                        selectionType="single"
                                        displayButtonBar={true}
                                        displayTime={false}
                                    />
                                </div>
                                <div className="field lg:col-4 md:col-12 col-12">
                                    <FilterSelect selectableOptions={state.genders} selectedOption={state.gender} onSelectChange={onSelectChange} elementId="gender" defaultValue="Genders" />
                                </div>
                                <IconTextInput value={state.phoneNumber} onInputChange={controlChange} placeholderValue="Phone Number" iconText="pi pi-phone" componentId="phoneNumber" customClasses="lg:col-4 md:col-12 col-12" />
                                <IconTextInput value={state.emailAddress} onInputChange={controlChange} placeholderValue="Email Address" iconText="pi pi-at" componentId="emailAddress" customClasses="lg:col-4 md:col-12 col-12" />
                                <div className="field lg:col-4 md:col-12 col-12">
                                    <FilterSelect selectableOptions={state.countriesList} selectedOption={state.nationality} onSelectChange={onSelectChange} elementId="nationality" defaultValue="Nationality" />
                                </div>
                                <IconTextInput value={state.location} onInputChange={controlChange} placeholderValue="Location" iconText="pi pi-map-marker" componentId="location" customClasses="lg:col-4 md:col-12 col-12" />
                                <IconTextInput value={state.occupation} onInputChange={controlChange} placeholderValue="Occupation" iconText="pi pi-user" componentId="occupation" customClasses="lg:col-4 md:col-12 col-12" />
                                <div className="field lg:col-4 md:col-12 col-12">
                                    <FilterSelect selectableOptions={state.religions} selectedOption={state.religion} onSelectChange={onSelectChange} elementId="religion" defaultValue="Religions" showClearIcon={true} />
                                </div>
                                <div className="field lg:col-4 md:col-12 col-12">
                                    <FilterSelect selectableOptions={state.insuredStatusTypes} selectedOption={state.insuranceStatus} onSelectChange={onSelectChange} elementId="insuranceStatus" defaultValue="Insurance Status" />
                                </div>
                                <IconTextInput value={state.company} onInputChange={controlChange} placeholderValue="Company" iconText="pi pi-building" componentId="company" customClasses="lg:col-4 md:col-12 col-12" />
                                <div className="field lg:col-4 md:col-12 col-12">
                                    <label htmlFor="sponsorshipLimit">Sponsorship Limit</label>
                                    <InputText type="number" value={state.sponsorshipLimit} onChange={(e) => setStateValues({ sponsorshipLimit: e.target.value! })} id="sponsorshipLimit" />
                                </div>
                                <div className="field lg:col-4 md:col-12 col-12">
                                    <label htmlFor="sponsorshipLimit">Patient Code</label>
                                    <InputText type="number" value={state.patientCode} onChange={(e) => setStateValues({ patientCode: e.target.value! })} id="patientCode" />
                                </div>
                                <div className="field lg:col-4 md:col-12 col-12 mt-4">
                                    <Button onClick={goToEmergencyContact}>Add Emergency Contact</Button>
                                </div>
                                <div className="field lg:col-3 md:col-12 col-12 mt-4">
                                    <Button onClick={!state.editingState ? promptPatientSave : promptPatientUpdate}>{!state.editingState ? `Save Patient` : `Update Patient`}</Button>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Emergency Contact" rightIcon="pi pi-arrows-alt ml-2">
                        <div className="p-fluid">
                            <div className="grid p-formgrid">
                                <div className="field lg:col-6 md:col-12 col-12">
                                    <FilterSelect selectableOptions={state.titles} selectedOption={state.emergencyContactTitle} onSelectChange={onSelectChange} elementId="emergencyContactTitle" defaultValue="Title" />
                                </div>
                                <IconTextInput value={state.emergencyFirstName} onInputChange={controlChange} placeholderValue="First Name" iconText="pi pi-user" componentId="emergencyFirstName" customClasses="lg:col-6 md:col-12 col-12" />
                                <IconTextInput value={state.emergencyLastName} onInputChange={controlChange} placeholderValue="Last Name" iconText="pi pi-user" componentId="emergencyLastName" customClasses="lg:col-6 md:col-12 col-12" />
                                <IconTextInput value={state.emergencyOtherName} onInputChange={controlChange} placeholderValue="Other Names" iconText="pi pi-user" componentId="emergencyOtherName" customClasses="lg:col-6 md:col-12 col-12" />
                                <IconTextInput value={state.emergencyPhoneNumber} onInputChange={controlChange} placeholderValue="Phone Number" iconText="pi pi-phone" componentId="emergencyPhoneNumber" customClasses="lg:col-6 md:col-12 col-12" />
                                <IconTextInput value={state.emergencyRelationship} onInputChange={controlChange} placeholderValue="Relation" iconText="pi pi-shield" componentId="emergencyRelationship" customClasses="lg:col-6 md:col-12 col-12" />
                                <div className="field lg:col-3 md:col-12 col-12 mt-4">
                                    <Button onClick={goBack}>Back</Button>
                                </div>
                                <div className="field lg:col-3 md:col-12 col-12 mt-4">
                                    <Button onClick={!state.editingState ? promptPatientSave : promptPatientUpdate}>{!state.editingState ? `Save Patient` : `Update Patient`}</Button>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </Dialog>
            <Dialog onHide={() => setStateValues({ showUploadDialog: false })} visible={state.showUploadDialog} position="top-right">
                <FileUploader onFileUpload={onUploadFile} fileUploadRef={uploadComponentRef} id="membersUploader" acceptableFileTypes=".xlsx,.csv" />
            </Dialog>
        </>
    );
};
export default Patient;
