import { DatePicker, FilterSelect, GeneralPageProps, IconTextInput, Loader, NumberInputWithButtons, SimpleTableWithMenu, tableEditOption } from '../utils/components';
import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DropdownOption, TBrand, TItem, TPriceEditItem } from '../utils/typesUtil';
import { displayMessage, getBaseURL, getTableRowId, pageDataValidation, remakeDropdownSelects, updateCacheItem } from '../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { CalendarChangeEvent } from 'primereact/calendar';
import { Button } from 'primereact/button';
import Items from '../classes/Items';
import Joi from 'joi';
import { format } from 'date-fns';
import { addRecordToCache, deleteCacheRecord, updateCacheRecord, useItemBrandsListFetch, useItemsListFetch } from '../utils/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumberValueChangeEvent } from 'primereact/inputnumber';

const _ = require('lodash');

type TItemState = TItem & {
    isLoading: boolean;
    showDialog: boolean;
    itemManufacturers: DropdownOption[] | undefined;
    editingState: boolean;
    showPurchasesDialog: boolean;
    showPriceAdjustmentDialog: boolean;
    priceEditItems: DropdownOption[];
    selectedPriceEditItem: string;
    selectedPriceEditItems: TPriceEditItem[];
    showManufacturersDialog: boolean;
    brandDescription: string;
    brandId: number | string;
    isBrandEditState: boolean;
    isNewBrandItem: boolean;
};

const INITIAL_STATE: TItemState = {
    itemId: uuidv4(),
    itemName: '',
    itemDescription: '',
    itemManufacturer: 0,
    itemCost: '0',
    itemPrice: '0',
    quantityInStock: '0',
    expirationDate: new Date(),
    itemManufacturers: [],
    isLoading: false,
    showDialog: false,
    editingState: false,
    showPurchasesDialog: false,
    showPriceAdjustmentDialog: false,
    priceEditItems: [],
    selectedPriceEditItem: '',
    selectedPriceEditItems: [],
    showManufacturersDialog: false,
    brandDescription: '',
    brandId: 0,
    isBrandEditState: false,
    isNewBrandItem: true
};

const items = new Items();

const validateItemsState: Joi.ObjectSchema<TItem> = Joi.object({
    itemId: Joi.string().required().messages({ 'any.required': 'Item Id is supposed to have a value before proceeding' }),
    itemName: Joi.string().messages({ 'string.empty': 'Enter a valid value for item name' }),
    itemDescription: Joi.string().optional().allow(''),
    itemManufacturer: Joi.number().optional().allow(''),
    itemCost: Joi.number().min(1).messages({ 'number.min': 'Item cost should be greater than zero (0)' }),
    itemPrice: Joi.number().min(1).messages({ 'number.min': 'Item Price should be greater than zero (0)' }),
    quantityInStock: Joi.number().messages({ 'number.min': 'Item quantity should be 0 or more' }),
    expirationDate: Joi.date()
});

const ItemStatePage = () => {
    const queryClient = useQueryClient();
    const [itemState, setItemState] = useState<TItemState>(INITIAL_STATE);
    const toastRef = useRef<Toast>(null);
    const { data: itemsList, isLoading, refetch, dataUpdatedAt } = useItemsListFetch({ urlLink: `${getBaseURL()}/items/get_all_items` });
    const { data: brandsList, isLoading: isBrandsLoading, refetch: refetchBrands } = useItemBrandsListFetch({ urlLink: `${getBaseURL()}/items/get_item_brands` });

    useEffect(() => {
        if (itemsList !== undefined && brandsList !== undefined) {
            setItemState((prevState) => {
                return {
                    ...prevState,
                    itemId: uuidv4(),
                    itemManufacturers: remakeDropdownSelects(brandsList, 'brandDescription', 'brandId'),
                    priceEditItems: remakeDropdownSelects(itemsList!, 'itemName', 'itemId')
                };
            });
        }
    }, [itemsList, brandsList]);
    const setStateValue = (stateValues: Partial<TItemState>) => {
        setItemState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    if (isLoading || isBrandsLoading) return <Loader />;
    const controlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateValue({ [e.target.id]: e.target.value! });
    };
    const itemMenu = () => {
        return [
            {
                label: 'New Item',
                icon: 'pi pi-plus',
                command: () => setStateValue({ showDialog: true })
            },
            {
                label: 'Adjust Prices',
                icon: 'pi pi-pencil',
                command: () => setStateValue({ showPriceAdjustmentDialog: true })
            },
            {
                label: 'Add Brand',
                icon: 'pi pi-plus',
                command: () => setStateValue({ showManufacturersDialog: true, isNewBrandItem: true, isBrandEditState: false })
            },
            {
                label: 'Brands List',
                icon: 'pi pi-list',
                command: () => setStateValue({ showManufacturersDialog: true, isNewBrandItem: false })
            },
            {
                label: 'Refresh Table',
                icon: 'pi pi-refresh',
                command: () => refetch()
            }
        ];
    };
    const getStateValues = (): TItem => {
        const { itemId, itemName, itemDescription, itemManufacturer, itemCost, itemPrice, quantityInStock, expirationDate }: TItem = itemState!;
        return {
            itemId,
            itemName,
            itemDescription,
            itemManufacturer,
            itemCost,
            itemPrice,
            quantityInStock,
            expirationDate: format(new Date(expirationDate as Date), 'yyyy-MM-dd')
        };
    };
    const hideDialog = () => {
        setStateValue({ showDialog: false, editingState: false });
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setStateValue({ itemManufacturer: e.value });
    };
    const onExpirationDateChange = (e: CalendarChangeEvent) => {
        setStateValue({ expirationDate: e.value! });
    };
    const validateItemPage = () => {
        return pageDataValidation<TItem>(validateItemsState, getStateValues(), toastRef);
    };
    const saveItem = async () => {
        try {
            if (!validateItemPage()) return;
            const stateValues = getStateValues();
            setStateValue({ isLoading: true });
            const itemSaveResponse = await items.createInstance(stateValues);
            if (itemSaveResponse.data.status === 1) {
                await addRecordToCache<TItem>(queryClient, ['itemsList'], itemSaveResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'New Item was successfully saved to list',
                    infoType: 'success',
                    life: 5000
                });
            }
        } catch (error: any) {
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const updateItem = async () => {
        if (!validateItemPage()) return;
        try {
            const stateValues = getStateValues();
            setStateValue({ isLoading: true });
            const itemUpdateResponse = await items.updateInstance(stateValues);
            if (itemUpdateResponse.data.status === 1) {
                await updateCacheRecord<TItem>(queryClient, ['itemsList'], [itemUpdateResponse.data.operatedData, stateValues.itemId, 'itemId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Item was successfully updated!',
                    infoType: 'success',
                    life: 3000
                });
            }
        } catch (error: any) {
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const setupItemEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedItemId = getTableRowId(e, 'id');
        const selectedItem = itemsList?.find((item) => item.itemId === selectedItemId);
        setStateValue({ ...selectedItem, showDialog: true, expirationDate: new Date(selectedItem?.expirationDate as Date), editingState: true });
    };
    const deleteItem = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const itemId = getTableRowId(e, 'id');
            setStateValue({ isLoading: true });
            const deleteItemResponse = await items.deleteInstance(itemId);
            if (deleteItemResponse.data.status === 1) {
                await deleteCacheRecord<TItem>(queryClient, ['itemsList'], [deleteItemResponse.data.operatedData, itemId, 'itemId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Delete Success',
                    message: 'Item was successfully deleted!',
                    infoType: 'success',
                    life: 5000
                });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const onItemSelectionChange = (e: DropdownChangeEvent) => {
        const selectedItem: TItem = _.find(itemsList, (listItem: TItem) => listItem.itemId === e.value);
        const { itemId, itemName, itemPrice } = selectedItem;
        setStateValue({
            selectedPriceEditItem: e.value,
            selectedPriceEditItems: [
                ...itemState.selectedPriceEditItems,
                {
                    itemId,
                    itemName,
                    itemOldPrice: itemPrice,
                    itemNewPrice: 0
                }
            ]
        });
    };
    const onPriceAdjustmentDialogHide = async () => {
        setStateValue({ showPriceAdjustmentDialog: false });
    };
    const onItemNewPriceChange = async (e: InputNumberValueChangeEvent) => {
        const editingItem: TPriceEditItem = _.find(itemState.selectedPriceEditItems, (listItem: TPriceEditItem) => listItem.itemId === e.target.id);
        if (editingItem !== undefined) {
            const updatingItem = { ...editingItem, itemNewPrice: e.target.value! };
            const selectedPriceEditItems = await updateCacheItem<TPriceEditItem>(updatingItem, editingItem, itemState.selectedPriceEditItems);
            setStateValue({ selectedPriceEditItems });
        }
    };
    const itemSellPriceInput = (rowData: TPriceEditItem) => {
        return <NumberInputWithButtons inputValue={rowData.itemNewPrice} inputValueChange={onItemNewPriceChange} numberInputId={rowData.itemId} />;
    };
    const updateItemsPrices = async () => {
        try {
            setStateValue({ isLoading: true });
            const pricesUpdateResponse = await items.updatePrices(itemState.selectedPriceEditItems);
            if (pricesUpdateResponse.data.status === 1) {
                await refetch();
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const deletePriceListItem = (e: React.MouseEvent<HTMLButtonElement>) => {
        const itemId = getTableRowId(e, 'id');
        const selectedPriceEditItems = itemState.selectedPriceEditItems.filter((listItem: TPriceEditItem) => listItem.itemId !== itemId);
        setStateValue({ selectedPriceEditItems });
    };
    const addNewManufacturer = async () => {
        try {
            const brandDescription = itemState.brandDescription;
            if (brandDescription === '') {
                displayMessage({
                    header: 'Error',
                    message: 'Add a valid description for new brand',
                    infoType: 'error',
                    toastComponent: toastRef,
                    life: 5000
                });
                return;
            }
            setStateValue({ isLoading: true });
            const newBrandResponse = await items.newItemBrand(brandDescription);
            if (newBrandResponse.data.status === 1) {
                await addRecordToCache<TBrand>(queryClient, ['brandsList'], newBrandResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'New Brand was successfully saved to list',
                    infoType: 'success',
                    life: 5000
                });
                setStateValue({ brandDescription: '', showManufacturersDialog: false });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const updateManufacturer = async () => {
        try {
            const brandDescription = itemState.brandDescription;
            if (brandDescription === '' || itemState.brandId === 0) {
                displayMessage({
                    header: 'Error',
                    message: 'Add a valid description for new brand',
                    infoType: 'error',
                    toastComponent: toastRef,
                    life: 5000
                });
                return;
            }
            setStateValue({ isLoading: true });
            const newBrandResponse = await items.updateItemBrand({ brandId: itemState.brandId as number, brandDescription: itemState.brandDescription });

            if (newBrandResponse.data.status === 1) {
                await updateCacheRecord<TBrand>(queryClient, ['brandsList'], [newBrandResponse.data.operatedData, newBrandResponse.data.operatedData.brandId!, 'brandId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'New Brand was successfully saved to list',
                    infoType: 'success',
                    life: 5000
                });
                setStateValue({ brandDescription: '', showManufacturersDialog: false });
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const NewItemComponent = (
        <div className="p-fluid">
            <div className="grid p-formgrid">
                <IconTextInput
                    value={itemState.brandDescription}
                    onInputChange={(e) => setStateValue({ brandDescription: e.target.value })}
                    placeholderValue="Brand Description"
                    iconText="pi pi-phone"
                    componentId="brandDescription"
                    customClasses="lg:col-12 md:col-12 col-12"
                />
            </div>
            <Button onClick={!itemState.isBrandEditState ? addNewManufacturer : updateManufacturer}>{!itemState.isBrandEditState ? 'Add New Brand' : 'Update Brand'}</Button>
        </div>
    );
    const setupBrandEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedBrandId = getTableRowId(e, 'id');
        const selectedBrand = _.find(brandsList, (listItem: TBrand) => listItem.brandId === parseInt(selectedBrandId));
        setStateValue({ brandDescription: selectedBrand.brandDescription, brandId: selectedBrand.brandId, isBrandEditState: true, isNewBrandItem: true });
    };
    return (
        <>
            {itemState?.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={'itemId'}
                    columnsDef={[
                        { field: 'itemName', header: 'Name' },
                        { field: 'itemCost', header: 'Cost' },
                        { field: 'itemPrice', header: 'Price' },
                        { field: 'quantityInStock', header: 'Quantity' },
                        { body: (rowData: TItem) => <div>{format(new Date(rowData.expirationDate as Date), 'yyyy-MM-dd')}</div>, header: 'Expiring' },
                        { body: (rowData: TItem) => tableEditOption(setupItemEdit, deleteItem, rowData.itemId), header: 'Edit' }
                    ]}
                    tableData={itemsList}
                    menuModel={itemMenu()}
                    hasMenuList={true}
                    tableTitle="Items List"
                    lastTableUpdate={dataUpdatedAt}
                    childTableDef={[]}
                    searchValues={['itemName', 'itemDescription', 'expirationDate']}
                    searchFieldPlaceHolder="Search by Full Name, Phone Number or Location"
                />
            </div>
            <Dialog onHide={hideDialog} visible={itemState?.showDialog} className="lg:w-7" position={'top-right'} header="Add New Item">
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <IconTextInput value={itemState?.itemName} onInputChange={controlChange} placeholderValue="Item Name" iconText="pi pi-user" componentId="itemName" customClasses="lg:col-6 md:col-12 col-12" />
                        <IconTextInput value={itemState?.itemDescription} onInputChange={controlChange} placeholderValue="Item Description" iconText="pi pi-user" componentId="itemDescription" customClasses="lg:col-6 md:col-12 col-12" />
                        <div className="field lg:col-5 md:col-10 col-10">
                            <FilterSelect selectableOptions={itemState?.itemManufacturers!} selectedOption={itemState?.itemManufacturer!} onSelectChange={onSelectChange} elementId="itemManufacturer" defaultValue="Manufacturer" />
                        </div>
                        <div className="field lg:col-1 md:col-2 col-2">
                            <Button icon="pi pi-plus" className="p-button-rounded p-button-success mt-4" size="small" onClick={() => setStateValue({ showManufacturersDialog: true, isNewBrandItem: true, isBrandEditState: false })} />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="itemCost">Item Cost</label>
                            <InputText type="number" value={itemState?.itemCost} onChange={controlChange} id="itemCost" />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="itemPrice">Item Price</label>
                            <InputText type="number" value={itemState?.itemPrice} onChange={controlChange} id="itemPrice" />
                        </div>
                        {!itemState.editingState && (
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="quantityInStock">Quantity</label>
                                <InputText type="number" value={itemState?.quantityInStock} onChange={controlChange} id="quantityInStock" />
                            </div>
                        )}
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="expirationDate">Event Dates</label>
                            <DatePicker dateValue={itemState?.expirationDate!} onDateChange={onExpirationDateChange} labelText="Expiration Date" controlId="expirationDate" selectionType="single" displayButtonBar={true} displayTime={false} />
                        </div>
                        <div className="field lg:col-2 md:col-12 col-12 mt-4">
                            <Button onClick={!itemState?.editingState ? saveItem : updateItem}>{!itemState?.editingState ? `Save Item` : `Update Item`}</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog maximizable header="Adjust Item Prices" onHide={onPriceAdjustmentDialogHide} visible={itemState.showPriceAdjustmentDialog} className="lg:w-auto" position="top-right">
                <div className="card">
                    <div className="p-fluid">
                        <div className="grid p-formgrid">
                            <div className="field lg:col-12 md:col-12 col-12">
                                <FilterSelect selectableOptions={itemState.priceEditItems} selectedOption={itemState.selectedPriceEditItem} onSelectChange={onItemSelectionChange} elementId="selectedSaleItem" defaultValue="Items" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <DataTable value={itemState.selectedPriceEditItems} emptyMessage="Select an item">
                        <Column field="itemName" header="Item" style={{ width: '30rem' }}></Column>
                        <Column field="itemOldPrice" header="Old Price" style={{ width: '10rem' }}></Column>
                        <Column header="New Price" style={{ width: '10rem' }} body={itemSellPriceInput}></Column>
                        <Column
                            header="Del"
                            style={{ width: '5rem' }}
                            body={(rowData: TPriceEditItem) => (
                                <div>
                                    <Button icon="pi pi-trash" className="p-button-danger" id={rowData.itemId} onClick={deletePriceListItem} />
                                </div>
                            )}
                        ></Column>
                    </DataTable>
                </div>
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <div className="field lg:col-3 md:col-12 col-12">
                            <Button onClick={updateItemsPrices}>Update Items Prices</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog onHide={() => setStateValue({ showManufacturersDialog: false })} visible={itemState.showManufacturersDialog} className={itemState.isNewBrandItem ? 'w-4' : 'w-5'} header="Add New Brand" position="top-right">
                {itemState.isNewBrandItem ? (
                    NewItemComponent
                ) : (
                    <DataTable value={brandsList} emptyMessage="Select an item" paginator rows={5}>
                        <Column field="brandDescription" header="Brand" style={{ width: '70rem' }}></Column>
                        <Column
                            body={(rowData: TBrand) => <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={setupBrandEdit} id={rowData.brandId!.toString()} />}
                            header="Edit Brand"
                            style={{ width: '30rem' }}
                        ></Column>
                    </DataTable>
                )}
            </Dialog>
        </>
    );
};
export default ItemStatePage;
