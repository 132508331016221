import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Button } from 'primereact/button';
import { useAConsultationWaitListFetch, useGetPaymentsList } from '../utils/reactQueryUtils';
import { formatDate, getBaseURL, getSelectedDayDates, getThisMonthDates, getThisWeekDates, getThisYearDates, rectangularShape, straightLine } from '../utils/utils';
import { TAgesBracket, TConsultationWaitList, TPaymentInstrument, TPaymentListItem, TPaymentsList, TRevenuesBracket, TSummaryReport } from '../utils/typesUtil';
import { DatePicker, Loader } from '../utils/components';
import { CalendarChangeEvent } from 'primereact/calendar';
import React, { useState } from 'react';
import { endOfMonth, isThisMonth, isWithinInterval, startOfMonth } from 'date-fns';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const _ = require('lodash');

type genderAggregates = {
    Male: number;
    Female: number;
};
type TReport = {
    reportDate: Date | Date[] | string;
};
const Reporting = () => {
    const [reportState, setReportState] = useState<TReport>({
        reportDate: new Date()
    });
    const { data, isLoading } = useAConsultationWaitListFetch({ urlLink: `${getBaseURL()}/consultation/get_wait_list` });
    const { data: paymentsList, isLoading: paymentsLoading } = useGetPaymentsList({ urlLink: `${getBaseURL()}/bills/get_payments_list` });

    const createReport = () => {
        const thisWeekPatientsTreated = getThisWeekDates<TConsultationWaitList>(data!, 'appointmentDate', reportState.reportDate as Date);
        const thisMonthPatientsTreated = getThisMonthDates<TConsultationWaitList>(data!, 'appointmentDate', reportState.reportDate as Date);
        const todayPatientsTreated = getSelectedDayDates<TConsultationWaitList>(data!, 'appointmentDate', reportState.reportDate as Date);
        const thisYearPatients = getThisYearDates<TConsultationWaitList>(data!, 'appointmentDate', reportState.reportDate as Date);

        const todayPayments = getSelectedDayDates<TPaymentListItem>(paymentsList!, 'paymentDate', reportState.reportDate as Date);
        const thisWeekPayments = getThisWeekDates<TPaymentListItem>(paymentsList!, 'paymentDate', reportState.reportDate as Date);
        const thisMonthPayments = getThisMonthDates<TPaymentListItem>(paymentsList!, 'paymentDate', reportState.reportDate as Date);
        const thisYearPayments = getThisYearDates<TPaymentListItem>(paymentsList!, 'paymentDate', reportState.reportDate as Date);

        const todayRevenue = getFeesBracket(todayPayments);
        const thisWeekRevenue = getFeesBracket(thisWeekPayments);
        const thisMonthRevenue = getFeesBracket(thisMonthPayments);
        const thisYearRevenue = getFeesBracket(thisYearPayments);

        const todayReport = getTodayReportDetails(todayPatientsTreated);
        const thisWeekReport = getTodayReportDetails(thisWeekPatientsTreated);
        const thisMonthReport = getTodayReportDetails(thisMonthPatientsTreated);
        const thisYearReport = getTodayReportDetails(thisYearPatients!);

        const documentDefinition: any = {
            content: [
                {
                    text: 'Activities Summary Report',
                    alignment: 'center',
                    bold: true,
                    fontSize: 14
                },
                {
                    stack: [
                        rectangularShape(),
                        {
                            stack: expandedReport(`Daily Report (${formatDate(new Date(reportState.reportDate as Date))})`, 'Today', todayReport.treatmentsTotal, todayReport.maleTotal, todayReport.femaleTotal, todayReport.ageBrackets!, todayRevenue),
                            absolutePosition: { x: 50, y: 55 },
                            marginBottom: 10
                        },
                        rectangularShape(),
                        {
                            stack: expandedReport('Weekly Report', 'Date Week', thisWeekReport.treatmentsTotal, thisWeekReport.maleTotal, thisWeekReport.femaleTotal, thisWeekReport.ageBrackets!, thisWeekRevenue),
                            absolutePosition: { x: 50, y: 265 },
                            marginBottom: 10
                        },
                        rectangularShape(),
                        {
                            stack: expandedReport('Monthly Report', 'Date Month', thisMonthReport.treatmentsTotal, thisMonthReport.maleTotal, thisMonthReport.femaleTotal, thisMonthReport.ageBrackets!, thisMonthRevenue),
                            absolutePosition: { x: 50, y: 475 },
                            marginBottom: 20
                        },
                        rectangularShape(),
                        {
                            stack: expandedReport('Yearly Report', 'Date Year', thisYearReport.treatmentsTotal, thisYearReport.maleTotal, thisYearReport.femaleTotal, thisYearReport.ageBrackets!, thisYearRevenue),
                            absolutePosition: { x: 50, y: 40 }
                        }
                    ]
                }
            ]
        };
        pdfMake.createPdf(documentDefinition).open();
    };
    const setStateValues = (stateValues: Partial<TReport>) => {
        setReportState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    if (isLoading || paymentsLoading) return <Loader />;
    const getTodayReportDetails = (todayPatientsTreated: TConsultationWaitList[]): TSummaryReport => {
        const { Male, Female }: genderAggregates = _.countBy(todayPatientsTreated, 'gender');

        return {
            treatmentsTotal: todayPatientsTreated.length,
            maleTotal: Male === undefined ? 0 : Male,
            femaleTotal: Female === undefined ? 0 : Female,
            ageBrackets: getAgesBracket(todayPatientsTreated)
        };
    };
    const getAgesBracket = (patientsData: TConsultationWaitList[]): any => {
        const ageGroups = _.groupBy(patientsData, (patient: TConsultationWaitList) => {
            const age = patient.age;

            if (age <= 6) {
                return 'lessThanSix';
            } else if (age >= 7 && age <= 10) {
                return 'sevenToTen';
            } else if (age >= 11 && age <= 20) {
                return 'elevenToTwenty';
            } else if (age >= 21 && age <= 30) {
                return 'twentyOneToThirty';
            } else if (age >= 31 && age <= 40) {
                return 'thirtyOneToForty';
            } else if (age >= 41 && age <= 50) {
                return 'fortyOneToFifty';
            } else if (age >= 51 && age <= 60) {
                return 'fiftyOneToSixty';
            } else {
                return 'sixtyPlus';
            }
        });
        return _.mapValues(ageGroups, (group: TConsultationWaitList[]) => group.length);
    };
    const getFeesBracket = (paymentListItems: TPaymentListItem[]): TRevenuesBracket => {
        const billingInstruments = paymentListItems.map((listItem: TPaymentListItem) => listItem.billingInstrument);

        const paymentsGroups = _.groupBy(_.flatMap(billingInstruments), (instrument: TPaymentInstrument) => {
            const item = instrument.item;

            if (item.toLowerCase() === 'procedure fee') {
                return 'procedureFee';
            } else if (item.toLowerCase() === 'consultation fee') {
                return 'consultationFee';
            } else if (item.toLowerCase() === 'registration fee') {
                return 'registrationFee';
            } else if (item.toLowerCase() === 'drugs fee') {
                return 'drugsFee';
            } else {
                return 'others';
            }
        });

        return _.mapValues(paymentsGroups, (group: any[]) =>
            group.reduce((previousValue, currentValue, currentIndex) => {
                return previousValue + parseFloat(currentValue.price);
            }, 0)
        );
    };
    const expandedReport = (reportTitle: string, reportType: string, treatmentsTotal: number, maleTreated: number, femaleTreated: number, ageBracket: TAgesBracket, revenueBrackets: TRevenuesBracket) => {
        return [
            { text: reportTitle, style: { marginTop: 10, bold: true } },
            { text: 'Treatments', style: { bold: true, decoration: 'underline' } },
            {
                columns: [
                    {
                        text: reportType,
                        bold: true,
                        fontSize: 12
                    },
                    {
                        text: treatmentsTotal
                    }
                ]
            },
            straightLine(),
            {
                columns: [
                    {
                        text: 'Male'
                    },
                    {
                        text: maleTreated
                    },
                    {
                        text: 'Female'
                    },
                    {
                        text: femaleTreated
                    }
                ],
                style: { bold: true, marginBottom: 50 }
            },
            straightLine(),
            { text: 'Age Brackets', bold: true, marginBottom: 5 },
            {
                table: {
                    widths: ['*', '*', '*', '*', '*', '*', '*', '*'],
                    body: [
                        ['<=6', '7-10', '11-20', '21-30', '31-40', '41-50', '51-60', '60 Plus'],
                        [
                            checkValuePerfect(ageBracket.lessThanSix),
                            checkValuePerfect(ageBracket.sevenToTen),
                            checkValuePerfect(ageBracket.elevenToTwenty),
                            checkValuePerfect(ageBracket.twentyOneToThirty),
                            checkValuePerfect(ageBracket.thirtyOneToForty),
                            checkValuePerfect(ageBracket.fortyOneToFifty),
                            checkValuePerfect(ageBracket.fiftyOneToSixty),
                            checkValuePerfect(ageBracket.sixtyPlus)
                        ]
                    ],
                    style: { marginBottom: 20 }
                }
            },
            { text: 'Revenue', marginBottom: 5, marginTop: 5, bold: true, fontSize: 14 },
            {
                table: {
                    widths: ['*', '*', '*', '*', '*'],
                    body: [
                        ['Registration', 'Consultation', 'Procedures', 'Drugs/Retail', 'Others'],
                        [
                            checkValuePerfect(revenueBrackets.registrationFee),
                            checkValuePerfect(revenueBrackets.consultationFee),
                            checkValuePerfect(revenueBrackets.procedureFee),
                            checkValuePerfect(revenueBrackets.drugsFee),
                            checkValuePerfect(revenueBrackets.others)
                        ]
                    ]
                }
            }
        ];
    };
    const companiesRevenueReport = (companiesData: any) => {
        const reportMonth = new Date(reportState.reportDate as Date).toLocaleDateString('default', { month: 'long' }).toUpperCase();
        const reportYear = new Date(reportState.reportDate as Date).getFullYear();
        const documentDefinition: any = {
            content: [
                {
                    text: `COMPANIES SUMMARY ${reportMonth}, ${reportYear}`,
                    marginBottom: 15,
                    bold: true,
                    fontSize: 16,
                    decoration: 'underline',
                    alignment: 'center'
                },
                {
                    columns: [
                        {
                            text: 'COMPANY',
                            bold: true,
                            width: '*'
                        },
                        {
                            text: 'EARNING',
                            bold: true,
                            width: '*',
                            alignment: 'right'
                        }
                    ]
                },
                straightLine(),
                companiesData.map((companyData: any) => {
                    return {
                        stack: [
                            {
                                columns: [
                                    {
                                        text: companyData.payer,
                                        width: '*'
                                    },
                                    {
                                        text: companyData.sumPaid.toFixed(2),
                                        width: '*',
                                        alignment: 'right'
                                    }
                                ]
                            },
                            straightLine()
                        ]
                    };
                })
            ]
        };
        pdfMake.createPdf(documentDefinition).open();
    };
    const checkValuePerfect = (evaluatingValue: number | undefined) => {
        return evaluatingValue === undefined ? 0 : evaluatingValue;
    };
    const displayRevenueByCompany = () => {
        const startDate = startOfMonth(new Date(reportState.reportDate as Date)); // Start of the current month
        const endDate = endOfMonth(new Date(reportState.reportDate as Date)); // End of the current month
        // Step 1: Exclude objects with payingEntity as 'Walk-In'
        const filteredTransactions = _.filter(paymentsList, (transaction: TPaymentListItem) => transaction.payingEntity !== 'Walk-In' && isWithinInterval(new Date(transaction.paymentDate), { start: startDate, end: endDate }));
        const groupedMoney: { payer: string; sumPaid: number }[] = _.chain(filteredTransactions)
            .groupBy('payer')
            .map((payerGroup: any, payer: any) => ({
                payer,
                sumPaid: _.sumBy(payerGroup, (transaction: any) => _.sumBy(transaction.billingInstrument, (item: any) => parseFloat(item.price)))
            }))
            .value();

        companiesRevenueReport(groupedMoney);
    };

    return (
        <>
            <div className="p-fluid">
                <div className="grid p-formgrid">
                    <div className="field lg:col-4 md:col-12">
                        <label htmlFor="reportDate">Reporting Date</label>
                        <DatePicker
                            dateValue={reportState.reportDate}
                            onDateChange={(e: CalendarChangeEvent) => setStateValues({ reportDate: e.value! })}
                            labelText="Report Date"
                            controlId="reportDate"
                            selectionType="single"
                            displayButtonBar={true}
                            displayTime={false}
                        />
                    </div>
                    <div className="field lg:col-4 md:col-12 mt-4">
                        <Button onClick={createReport}>Activities Summary Report</Button>
                    </div>
                    <div className="field lg:col-4 md:col-12 mt-4">
                        <Button onClick={displayRevenueByCompany}>Companies Revenue Report</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Reporting;
