import { IOneForAll, TExpenditure } from '../utils/typesUtil';
import { AxiosResponse } from 'axios';
import { fetchAction, getBaseURL } from '../utils/utils';

class CExpenditure implements IOneForAll<TExpenditure>, TExpenditure {
    expenditureDate: Date | string | Date[] = new Date();
    expenditureId: string = '';
    expenditureNotes: string = '';
    expenditureTypeId: string = '';
    expensesAmount: number = 0;
    modifiedBy: string = '';

    createInstance(stateValues: TExpenditure): Promise<AxiosResponse<{ status: number; operatedData: TExpenditure; error?: string }>> {
        return fetchAction('post', `${getBaseURL()}/bills/new_expenditure`, { expenditureData: stateValues });
    }

    deleteInstance(deleteId: string): Promise<AxiosResponse<{ status: number; operatedData: TExpenditure; error?: string }>> {
        return fetchAction('delete', `${getBaseURL()}/bills/delete_expenditure`, { expenditureId: deleteId });
    }

    updateInstance(stateValues: TExpenditure): Promise<AxiosResponse<{ status: number; operatedData: TExpenditure; error?: string }>> {
        return fetchAction('patch', `${getBaseURL()}/bills/update_expenditure`, { expenditureData: stateValues });
    }
}
export default CExpenditure;
