import React, { useContext } from 'react';
import { TDiagnosisContext } from '../../utils/typesUtil';
import { DiagnosisCenterContext } from '../DiagnosisCenter';
import { DataView } from 'primereact/dataview';
import { CheckboxInput } from '../../utils/components';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { onCheckBoxItemSelect } from '../../utils/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Chip } from 'primereact/chip';

const DentalHistory = () => {
    const { setStateValues, state } = useContext<Partial<TDiagnosisContext>>(DiagnosisCenterContext!);
    const dentalHistoryTemplate = (value: string) => {
        return (
            <div className="col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start">
                    <CheckboxInput inputName={value} checkLabel={value} checkedState={state?.dentalHistory.includes(value)!} inputId={value} onCheckChange={onDentalHistorySelect} />
                </div>
            </div>
        );
    };
    const onDentalHistorySelect = (e: CheckboxChangeEvent) => {
        onCheckBoxItemSelect(setStateValues!, 'dentalHistory', state?.dentalHistory!, e.target.id, e.checked!);
    };
    const onOtherDentalHistoryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setStateValues!({
            otherValue: e.target.value
        });
    };
    const addToOtherDentalHistory = () => {
        if (state?.otherValue === '') return;
        setStateValues!({ otherDentalHistory: [...state?.otherDentalHistory!, state?.otherValue!], otherValue: '' });
    };
    return (
        <>
            <div className="card p-fluid max-h-30rem fadeinleft animation-duration-500">
                <div className="grid">
                    <div className="col-4">
                        <DataView value={state!.dentalHistoryValues} itemTemplate={dentalHistoryTemplate} />
                    </div>
                    <div className="col-8">
                        <h6>Others</h6>
                        <InputTextarea value={state?.otherValue} onChange={onOtherDentalHistoryChange} rows={2} cols={30} />
                        <div className="col-1">
                            <Button onClick={addToOtherDentalHistory}>Add</Button>
                        </div>
                        <Card className="card mt-4" subTitle="Other Medical History Items">
                            {state?.otherDentalHistory.map((other: string, index) => {
                                return <Chip className="text-cyan-200" id={other} label={other} key={`omv${index}`} removable />;
                            })}
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DentalHistory;
