import { TPayment, TPaymentListItem } from '../utils/typesUtil';
import { AxiosResponse } from 'axios';
import { fetchAction, getBaseURL } from '../utils/utils';

class Consultation {
    async acceptBillPayment(stateValues: TPayment): Promise<AxiosResponse<{ status: number; operatedData: TPayment; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/bills/accept_payment`, { paymentInfo: stateValues });
    }

    async updateBillPayment(stateValues: Partial<TPaymentListItem>): Promise<AxiosResponse<{ status: number; operatedData: TPaymentListItem; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/bills/update_payment`, { paymentInfo: stateValues });
    }

    async onBillRepayment(stateValues: any): Promise<AxiosResponse<{ status: number; operatedData: TPayment; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/bills/accept_more_payment`, { paymentInfo: stateValues });
    }
}

export default Consultation;
