import { IOneForAll, TUsers } from '../utils/typesUtil';
import { AxiosResponse } from 'axios';
import { fetchAction, getBaseURL } from '../utils/utils';

class UsersModel implements IOneForAll<TUsers>, TUsers {
    emailAddress: string = '';
    fullName: string = '';
    gender: string = '';
    password: string = '';
    phoneNumber: string = '';
    role: string = '';
    userId: string = '';
    username: string = '';
    async createInstance(stateValues: TUsers): Promise<AxiosResponse<{ status: number; operatedData: TUsers; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/users/create_new_user`, { userData: stateValues });
    }

    async deleteInstance(deleteId: string): Promise<AxiosResponse<{ status: number; operatedData: TUsers; error?: string }>> {
        return await fetchAction('delete', `${getBaseURL()}/users/delete_user`, { userData: deleteId });
    }

    async updateInstance(stateValues: TUsers): Promise<AxiosResponse<{ status: number; operatedData: TUsers; error?: string }>> {
        return await fetchAction('patch', `${getBaseURL()}/users/update_user`, { userData: stateValues });
    }
}
export default UsersModel;
