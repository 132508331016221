import React, { useContext } from 'react';
import { TDiagnosisContext } from '../../utils/typesUtil';
import { DiagnosisCenterContext } from '../DiagnosisCenter';
import { DataView } from 'primereact/dataview';
import { CheckboxInput } from '../../utils/components';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { onCheckBoxItemSelect } from '../../utils/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Chip } from 'primereact/chip';
const _ = require('lodash');
const Allergies = () => {
    const { setStateValues, state } = useContext<Partial<TDiagnosisContext>>(DiagnosisCenterContext!);
    const allergiesTemplate = (value: string) => {
        return (
            <div className="col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start">
                    <CheckboxInput inputName={value} checkLabel={value} checkedState={state?.allergies.includes(value)!} inputId={value} onCheckChange={onAllergySelect} />
                </div>
            </div>
        );
    };
    const onAllergySelect = (e: CheckboxChangeEvent) => {
        onCheckBoxItemSelect(setStateValues!, 'allergies', state?.allergies!, e.target.id, e.checked!);
    };
    const onAddOtherAllergyValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setStateValues!({
            otherValue: e.target.value
        });
    };
    const addToOtherAllergies = () => {
        if (state?.otherValue === '') return;
        setStateValues!({ otherAllergies: [...state?.otherAllergies!, state?.otherValue!], otherValue: '' });
    };

    return (
        <>
            <div className="card p-fluid max-h-30rem h-30rem fadeinleft animation-duration-500">
                <div className="grid">
                    <div className="col-4">
                        <DataView value={state!.allergiesValues} itemTemplate={allergiesTemplate} />
                    </div>
                    <div className="col-8">
                        <h6>Others</h6>
                        <InputTextarea value={state?.otherValue} onChange={onAddOtherAllergyValueChange} rows={2} cols={30} />
                        <div className="col-1">
                            <Button onClick={addToOtherAllergies}>Add</Button>
                        </div>
                        <Card className="card mt-4" subTitle="Other Allergies">
                            {state?.otherAllergies.map((other: string, index) => {
                                return <Chip className="text-cyan-200" id={other} label={other} key={`omv${index}`} removable />;
                            })}
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Allergies;
