import { DatePicker, FilterSelect, GeneralPageProps, Loader, NumberInputWithButtons, SimpleTableWithMenu, tableEditOption } from '../utils/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { DropdownOption, TAdjustment, TAdjustmentItems, TItem, TSelectedPurchaseListItem, TUsers } from '../utils/typesUtil';
import { v4 as uuidv4 } from 'uuid';
import { AppUserContext, displayMessage, formatDate, getBaseURL, getTableRowId, pageDataValidation, remakeDropdownSelects } from '../utils/utils';
import { CalendarChangeEvent } from 'primereact/calendar';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { addRecordToCache, useAdjustmentListFetch, useItemsListFetch } from '../utils/reactQueryUtils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import Joi from 'joi';
import Items from '../classes/Items';
import { useQueryClient } from '@tanstack/react-query';
const _ = require('lodash');

type TAdjustmentState = TAdjustment & {
    isLoading: boolean;
    showAdjustmentDialog: boolean;
    adjustmentTypes: DropdownOption[];
    adjustmentItemsList: DropdownOption[];
    selectedItem: string;
    editState: boolean;
};
const INITIAL_STATE: TAdjustmentState = {
    adjustedItems: [],
    adjustmentDate: new Date(),
    adjustmentId: '',
    adjustmentType: '',
    modifiedBy: '',
    isLoading: false,
    showAdjustmentDialog: false,
    adjustmentTypes: [],
    adjustmentItemsList: [],
    selectedItem: '',
    editState: false,
    adjustmentComment: ''
};
const validateAdjustment: Joi.ObjectSchema<TAdjustment> = Joi.object({
    adjustmentId: Joi.string(),
    adjustedItems: Joi.array().min(1).messages({ 'array.min': 'Ensure that at least one item is selected for adjustment' }),
    adjustmentDate: Joi.date(),
    adjustmentType: Joi.string(),
    adjustmentComment: Joi.string(),
    modifiedBy: Joi.string().messages({ 'string.empty': 'Adjustment comment cannot be empty. Write something to remind of the adjustment reason.' })
});
const items = new Items();
const Adjustments = () => {
    const queryClient = useQueryClient();
    const toastRef = useRef<Toast>(null);
    const [adjustmentState, setAdjustmentState] = useState<TAdjustmentState>(INITIAL_STATE);
    const user = useContext<TUsers>(AppUserContext);
    const { data: itemsList, isLoading: itemsLoading, refetch: refetchItems, dataUpdatedAt: itemsUpdatedAt } = useItemsListFetch({ urlLink: `${getBaseURL()}/items/get_all_items` });
    const { data: adjustmentsList, isLoading: adjustmentsLoading, refetch: refetchAdjustments, dataUpdatedAt: adjustmentsUpdatedAt } = useAdjustmentListFetch({ urlLink: `${getBaseURL()}/items/get_all_adjustments` });

    useEffect(() => {
        if (itemsList !== undefined) {
            setAdjustmentState((prevState) => {
                return {
                    ...prevState!,
                    adjustmentId: uuidv4(),
                    adjustmentTypes: remakeDropdownSelects([{ adjustmentType: 'Adjustment In' }, { adjustmentType: 'Adjustment Out' }], 'adjustmentType', 'adjustmentType'),
                    modifiedBy: user.userId,
                    adjustmentItemsList: remakeDropdownSelects(itemsList!, 'itemName', 'itemId')
                };
            });
        }
    }, [itemsList]);
    const setStateValue = (stateValues: Partial<TAdjustmentState>) => {
        setAdjustmentState((prevState) => {
            return { ...prevState!, ...stateValues };
        });
    };
    const getStateValues = () => {
        const { adjustmentId, adjustedItems, adjustmentDate, adjustmentType, adjustmentComment, modifiedBy }: TAdjustment = adjustmentState!;
        return {
            adjustmentId,
            adjustedItems,
            adjustmentDate: formatDate(adjustmentDate as Date),
            adjustmentType,
            adjustmentComment,
            modifiedBy
        };
    };
    const adjustmentMenu = () => {
        return [
            {
                label: 'New Adjustment',
                icon: 'pi pi-plus',
                command: () => setStateValue({ showAdjustmentDialog: true, adjustedItems: [], adjustmentType: '' })
            }
        ];
    };
    const onHideAdjustmentDialog = () => {
        setStateValue({ showAdjustmentDialog: false, editState: false });
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setStateValue({ [e.target.id]: e.value });
    };
    const onItemSelection = (e: DropdownChangeEvent) => {
        const selectedItem = itemsList!.find((item: TItem) => item.itemId === e.value);

        const { itemName } = selectedItem!;
        setStateValue({
            selectedItem: e.value,
            adjustedItems: [
                ...adjustmentState.adjustedItems,
                {
                    itemId: e.value,
                    adjustmentQty: 1,
                    itemName: itemName
                }
            ]
        });
    };
    const onItemQuantityValueChange = (e: InputNumberValueChangeEvent) => {
        const editingItem = adjustmentState.adjustedItems.find((listItem: TAdjustmentItems) => listItem.itemId === e.target.name);
        if (editingItem !== undefined) {
            editingItem.adjustmentQty = e.value!;
        }
        setStateValue({ adjustedItems: [...adjustmentState.adjustedItems] });
    };
    const itemQuantityInput = (rowData: TAdjustmentItems) => {
        return <NumberInputWithButtons allowDecimalValues={false} disableState={adjustmentState.editState} inputValue={rowData.adjustmentQty} inputValueChange={onItemQuantityValueChange} numberInputName={rowData.itemId} />;
    };
    const deleteFromList = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (adjustmentState.editState) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Delete Avoided',
                message: 'Delete is not currently available',
                infoType: 'warn',
                life: 3000
            });
            return;
        }
        const itemId = getTableRowId(e, 'id');
        const listItems = adjustmentState.adjustedItems.filter((item) => item.itemId !== itemId);
        setStateValue({ adjustedItems: listItems });
    };
    const completeAdjustment = async () => {
        try {
            if (adjustmentState.editState) {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Aborted',
                    message: 'Save action is not currently available',
                    infoType: 'warn',
                    life: 3000
                });
                return;
            }
            if (!pageDataValidation<TAdjustment>(validateAdjustment, getStateValues(), toastRef)) return;
            setStateValue({ isLoading: true });
            const adjustmentResponse = await items.newAdjustment(getStateValues());

            if (adjustmentResponse.data.status === 1) {
                await addRecordToCache<TAdjustment>(queryClient, ['adjustmentsList'], adjustmentResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'Adjustment was successfully saved!',
                    infoType: 'success',
                    life: 5000
                });
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const setupEditAdjustment = (e: React.MouseEvent<HTMLButtonElement>) => {
        const adjustmentId = getTableRowId(e, 'id');
        const selectedAdjustment: TAdjustment = _.find(adjustmentsList, (listItem: TAdjustment) => listItem.adjustmentId === adjustmentId);
        if (selectedAdjustment !== undefined) {
            const { adjustmentId, adjustmentType, adjustedItems, adjustmentDate, adjustmentComment, modifiedBy } = selectedAdjustment;
            const parsedListItems = typeof adjustedItems !== 'object' ? JSON.parse(adjustedItems) : adjustedItems;
            setStateValue({ adjustmentId, adjustmentType, adjustmentDate: new Date(adjustmentDate as Date), adjustedItems: parsedListItems, modifiedBy, adjustmentComment, showAdjustmentDialog: true, editState: true });
        }
    };
    const deleteAdjustment = () => {};
    const resetState = () => {
        setStateValue({ adjustmentId: uuidv4(), adjustedItems: [], adjustmentType: '', adjustmentDate: new Date(), adjustmentComment: '' });
    };
    return (
        <>
            {adjustmentState.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={'adjustmentId'}
                    columnsDef={[
                        { body: (rowData: TAdjustment) => <div>{formatDate(rowData.adjustmentDate as Date)}</div>, header: 'Date', style: '10rem' },
                        { field: 'adjustmentType', header: 'Adjustment Type', style: '10rem' },
                        { field: 'username', header: 'User', style: '10rem' },
                        { body: (rowData: TAdjustment) => tableEditOption(setupEditAdjustment, deleteAdjustment, rowData.adjustmentId), header: 'See More', style: '5rem' }
                    ]}
                    tableData={adjustmentsList}
                    menuModel={adjustmentMenu()}
                    hasMenuList={true}
                    tableTitle="Adjustments List"
                    lastTableUpdate={adjustmentsUpdatedAt}
                    childTableDef={[]}
                    searchValues={['adjustmentType', 'adjustmentDate', 'modifiedBy']}
                    searchFieldPlaceHolder="Search by Adjustment Type, Adjustment Date or Adjustment Person"
                />
            </div>
            <Dialog onHide={onHideAdjustmentDialog} visible={adjustmentState.showAdjustmentDialog} maximized>
                <div className="card">
                    <div className="p-fluid">
                        <div className="grid p-formgrid">
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="adjustmentDate">Adjustment Date</label>
                                <DatePicker
                                    dateValue={adjustmentState.adjustmentDate}
                                    onDateChange={(e: CalendarChangeEvent) => setStateValue({ adjustmentDate: e.value! })}
                                    labelText="Adjustment Date"
                                    controlId="adjustmentDate"
                                    selectionType="single"
                                    displayButtonBar={true}
                                    displayTime={false}
                                />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <FilterSelect selectableOptions={adjustmentState?.adjustmentTypes!} selectedOption={adjustmentState?.adjustmentType!} onSelectChange={onSelectChange} elementId="adjustmentType" defaultValue="Adjustment Types" />
                            </div>
                            <div className="field lg:col-12 md:col-12 col-12">
                                <FilterSelect selectableOptions={adjustmentState?.adjustmentItemsList!} selectedOption={adjustmentState?.selectedItem!} onSelectChange={onItemSelection} elementId="selectedItem" defaultValue="Items List" />
                            </div>
                        </div>
                    </div>
                    <DataTable value={adjustmentState.adjustedItems} emptyMessage="Select at least one item" key="itemId" stripedRows={true}>
                        <Column field="itemName" header="Item" style={{ width: '30rem' }}></Column>
                        <Column header="Adjusting Quantity" style={{ width: '20rem' }} body={itemQuantityInput}></Column>
                        <Column
                            body={(rowData: TSelectedPurchaseListItem) => (
                                <div>
                                    <Button icon="pi pi-trash" className="p-button-danger" id={rowData.itemId} onClick={deleteFromList} />
                                </div>
                            )}
                            header="Del"
                            style={{ width: '10rem' }}
                        ></Column>
                    </DataTable>
                </div>
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <div className="field lg:col-12 md:col-12 col-12">
                            <label htmlFor="adjustmentComment">Adjustment Comment</label>
                            <InputTextarea value={adjustmentState.adjustmentComment} onChange={(e) => setStateValue({ adjustmentComment: e.target.value })} rows={3} cols={170} />
                        </div>
                        <div className="field lg:col-2 md:col-12 col-12">
                            <Button onClick={completeAdjustment}>Complete Adjustment</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};
export default Adjustments;
