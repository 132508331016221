import { GeneralPageProps, Loader, SimpleTableWithMenu } from '../utils/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { deleteCacheRecord, useAccumulatedDebtorsListFetch, useAConsultationWaitListFetch, useGetHangedDiagnosisData } from '../utils/reactQueryUtils';
import { AppUserContext, displayMessage, formatDate, getBaseURL, getLastMonthDates, getLastWeekAppointments, getTableRowId, getThisMonthDates, getThisWeekDates, getTodayDates, promptUserAction } from '../utils/utils';
import { TAppointment, TConsultationWaitList, TDebtor, TDiagnosis, TGeneralBag, TUsers } from '../utils/typesUtil';
import { Button } from 'primereact/button';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { Dialog } from 'primereact/dialog';
import Appointment from '../classes/Appointment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
const _ = require('lodash');

const items = [
    { name: 'Today', value: 1 },
    { name: 'This Week', value: 2 },
    { name: 'Last Week', value: 3 },
    { name: 'This Month', value: 4 },
    { name: 'Last Month', value: 5 },
    { name: 'All Time', value: 6 }
];
type TConsultationState = {
    consultingList: TConsultationWaitList[];
    showListValue: number;
    pausedDiagnosis: TDiagnosis[] | undefined;
    showDialog: boolean;
    isLoading: boolean;
    patientDebt: number;
};
const consultation = new Appointment();
const ConsultationRoom = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const toastRef = useRef<Toast>(null);
    const { data: waitList, isLoading, refetch, dataUpdatedAt } = useAConsultationWaitListFetch({ urlLink: `${getBaseURL()}/consultation/get_wait_list` });
    const { data: debtorsList, isLoading: debtorsLoading } = useAccumulatedDebtorsListFetch({ urlLink: `${getBaseURL()}/bills/get_accumulated_debtors_list` });

    const user = useContext<TUsers>(AppUserContext);

    const { data: generalBag, refetch: fetchHangedDiagnosisAgain } = useGetHangedDiagnosisData(queryClient);

    const [consultationState, setConsultationState] = useState<TConsultationState>({
        consultingList: [],
        showListValue: 1,
        pausedDiagnosis: [],
        showDialog: false,
        isLoading: false,
        patientDebt: 0
    });

    useEffect(() => {
        if (!user) navigate('/login');
        setConsultationState((prevState) => {
            return { ...prevState, consultingList: getTodayDates<TConsultationWaitList>(waitList!, 'appointmentDate') };
        });
    }, [waitList]);

    useEffect(() => {
        const generalBagData = { ...generalBag };

        if (generalBagData.pausedDiagnosis) {
            const modifiedBagData = _.omit(generalBagData, 'pausedDiagnosis');
            queryClient.setQueryData(['generalBag'], { ...modifiedBagData });
        }
    }, []);

    const setStateValues = (stateValues: Partial<TConsultationState>) => {
        setConsultationState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };

    if (isLoading || debtorsLoading) return <Loader />;
    const consultationMenu = () => {
        return [
            {
                label: 'New Patient',
                icon: 'pi pi-plus',
                command: () => {}
            },
            {
                label: 'Refresh Table',
                icon: 'pi pi-refresh',
                command: () => refetch()
            },
            {
                label: 'Paused Diagnosis',
                icon: 'pi pi-pause',
                command: () => checkPausedDiagnosis()
            }
        ];
    };
    const checkPausedDiagnosis = () => {
        const pausedLocalList: TDiagnosis[] | undefined = queryClient.getQueryData(['hangedDiagnosis']);
        setConsultationState((prevState) => {
            return { ...prevState, pausedDiagnosis: pausedLocalList, showDialog: true };
        });
    };
    const promptDebtorPatient = (event: React.MouseEvent<HTMLButtonElement>, debtAmount: number) => {
        promptUserAction({
            yesAction: () => goToDiagnosisCenter(event),
            event,
            displayText: `This patient has an unpaid debt balance of ${debtAmount}. Click on Yes to proceed to diagnosis.`,
            acceptLabel: 'Ok. Proceed to Diagnosis',
            rejectLabel: 'Reject Consultation Process'
        });
    };
    const checkPatientDebtorStatus = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const patientId = getTableRowId(event, 'id');
        const patientDebtor = _.find(debtorsList, (listItem: TDebtor) => listItem.patientId === patientId);
        if (patientDebtor) {
            // setStateValues({ patientDebt: patientDebtor.balance });
            consultationState.patientDebt = patientDebtor.balance; // since set state does not refresh value before running the goToDiagnosisCenter function,
            //we set the patientDebt value on state directly so that it is accessible by the next utilizing function.
            promptDebtorPatient(event, patientDebtor.balance);
        } else {
            await goToDiagnosisCenter(event);
        }
    };
    const goToDiagnosisCenter = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const patientId = getTableRowId(e, 'id');

        const tdRow = (e.target as HTMLElement).closest('tr') as HTMLElement;
        const currentRoom = (tdRow.querySelector('td:nth-child(8)')! as Node).textContent;
        const appointmentDate = (tdRow.querySelector('td:first-child')! as Node).textContent;
        // if (currentRoom !== 'Consulting') {
        //     displayMessage({
        //         toastComponent: toastRef,
        //         header: 'Error',
        //         message: 'Consultation cannot be done for patient currently!',
        //         infoType: 'error',
        //         life: 3000
        //     });
        //     return;
        // }

        const selectedPatient = waitList?.find((waitingPatient: TConsultationWaitList) => waitingPatient.patientId === patientId && formatDate(appointmentDate!) === formatDate(waitingPatient.appointmentDate as Date));
        const alreadyBagData: TGeneralBag = await queryClient.getQueryData(['generalBag'])!;
        const refinedData = _.omit(alreadyBagData, 'pausedDiagnosis');
        queryClient.setQueryData(['generalBag'], { ...refinedData!, diagnosingPatient: selectedPatient, patientDebtAmount: consultationState.patientDebt });
        navigate(`/diagnosis`, { state: { key: patientId } });
    };
    const goToDiagnosisWithHangedData = (e: React.MouseEvent<HTMLButtonElement>) => {
        // @ts-ignore
        // const currentRoom = e.target.closest('td').previousSibling.innerText;
        // if (currentRoom !== 'Consulting') {
        //     displayMessage({
        //         toastComponent: toastRef,
        //         header: 'Error',
        //         message: 'Consultation cannot be done for patient currently!',
        //         infoType: 'error',
        //         life: 3000
        //     });
        //     return;
        // }

        const patientId = getTableRowId(e, 'id');
        const diagnosisDate = ((e.target as HTMLElement).closest('tr')?.querySelector('td:nth-child(2)') as HTMLElement).innerText;

        const selectedPatient = waitList?.find((waitingPatient: TConsultationWaitList) => waitingPatient.patientId === patientId && formatDate(new Date(diagnosisDate!)) === formatDate(waitingPatient.appointmentDate! as Date));
        const pausedDiagnosis = consultationState.pausedDiagnosis?.find((pausedDiagnosis: TDiagnosis) => pausedDiagnosis.patientId === patientId && formatDate(new Date(diagnosisDate!)) === formatDate(pausedDiagnosis.diagnosisDate as Date));

        queryClient.setQueryData(['generalBag'], { diagnosingPatient: selectedPatient, pausedDiagnosis });
        navigate(`/diagnosis`, { state: { key: patientId } });
    };
    const onSelectButtonChange = (e: SelectButtonChangeEvent): TConsultationWaitList[] | null | undefined => {
        switch (e.value) {
            case 1:
                setConsultationState((prevState) => {
                    return { ...prevState, consultingList: getTodayDates<TConsultationWaitList>(waitList!, 'appointmentDate'), showListValue: e.value };
                });
                break;
            case 2:
                setConsultationState((prevState) => {
                    return { ...prevState, consultingList: getThisWeekDates<TConsultationWaitList>(waitList!, 'appointmentDate'), showListValue: e.value };
                });
                break;
            case 3:
                setConsultationState((prevState) => {
                    return { ...prevState, consultingList: getLastWeekAppointments<TConsultationWaitList>(waitList!, 'appointmentDate'), showListValue: e.value };
                });
                break;
            case 4:
                setConsultationState((prevState) => {
                    return { ...prevState, consultingList: getThisMonthDates<TConsultationWaitList>(waitList!, 'appointmentDate'), showListValue: e.value };
                });
                break;
            case 5:
                setConsultationState((prevState) => {
                    return { ...prevState, consultingList: getLastMonthDates<TConsultationWaitList>(waitList!, 'appointmentDate'), showListValue: e.value };
                });
                break;
            case 6:
                setConsultationState((prevState) => {
                    return { ...prevState, consultingList: waitList!, showListValue: e.value };
                });
                break;
            default:
                return waitList;
        }
    };
    const onHideDialog = () => {
        setConsultationState((prevState) => {
            return { ...prevState, showDialog: false };
        });
    };
    const promptConsultationDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        promptUserAction({ yesAction: () => removeConsultation(event), event, displayText: 'You are deleting a schedule consultation. Action cannot be undone. Proceed?' });
    };
    const removeConsultation = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const consultationId = getTableRowId(event, 'id');
        try {
            setConsultationState((prevState) => {
                return { ...prevState, isLoading: true };
            });
            const consultationDeleteResponse = await consultation.deleteInstance(consultationId);
            if (consultationDeleteResponse.data.status === 1) {
                await deleteCacheRecord<TAppointment>(queryClient, ['consultationList'], [consultationDeleteResponse.data.operatedData, consultationId, 'appointmentId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Delete Success',
                    message: 'Consultation data was successfully removed from list!',
                    infoType: 'success',
                    life: 5000
                });
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setConsultationState((prevState) => {
                return { ...prevState, isLoading: false };
            });
        }
    };
    const deletePausedDiagnosis = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const diagnosisId = getTableRowId(e, 'id');
        const deletingHangedDiagnosis = consultationState.pausedDiagnosis?.find((listItem: TDiagnosis) => listItem.diagnosisId === diagnosisId);
        await deleteCacheRecord<TDiagnosis>(queryClient, ['hangedDiagnosis'], [deletingHangedDiagnosis!, diagnosisId, 'diagnosisId']);

        const pausedLocalList: TDiagnosis[] | undefined = queryClient.getQueryData(['hangedDiagnosis']); //reset table list;
        setStateValues({ pausedDiagnosis: pausedLocalList });

        displayMessage({
            toastComponent: toastRef,
            header: 'Removal Success',
            message: 'Hanged diagnosis removed from list',
            infoType: 'success',
            life: 3000
        });
    };

    return (
        <>
            {consultationState.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} toastPosition="top-right" />
            <div className="card flex justify-content-center">
                <SelectButton value={consultationState.showListValue} onChange={onSelectButtonChange} optionLabel="name" options={items} />
            </div>
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={'appointmentId'}
                    columnsDef={[
                        { body: (rowData: TConsultationWaitList) => <div>{rowData.appointmentDate as string}</div>, header: 'Date' },
                        { field: 'fullName', header: 'Name' },
                        { field: 'gender', header: 'Gender' },
                        { field: 'phoneNumber', header: 'Phone' },
                        { field: 'location', header: 'Location' },
                        { field: 'appointmentType', header: 'Type' },
                        { field: 'currentRoom', header: 'Room' },
                        {
                            body: (row: TConsultationWaitList) => (
                                <div>
                                    <Button id={row.patientId} icon="pi pi-external-link" className="p-button-rounded p-button-success mr-2" onClick={checkPatientDebtorStatus} />
                                </div>
                            ),
                            header: 'Diagnose'
                        },
                        {
                            body: (row: TConsultationWaitList) => (
                                <div>
                                    <Button id={row.appointmentId} icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={promptConsultationDelete} />
                                </div>
                            ),
                            header: 'Del'
                        }
                    ]}
                    tableData={consultationState.consultingList}
                    menuModel={consultationMenu()}
                    hasMenuList={true}
                    tableTitle="Consultation Room Wait List"
                    lastTableUpdate={dataUpdatedAt}
                    childTableDef={[]}
                    searchValues={['fullName', 'phoneNumber', 'location']}
                    searchFieldPlaceHolder="Search by Full Name, Phone Number or Location"
                />
            </div>
            <Dialog position="top-right" onHide={onHideDialog} visible={consultationState.showDialog} className="lg:w-5" header="List of Paused Diagnosis Today" closeOnEscape>
                <DataTable value={consultationState.pausedDiagnosis} showGridlines>
                    <Column header="Patient" field="fullName" />
                    <Column header="Date" body={(rowData: TDiagnosis) => <div>{formatDate(rowData.diagnosisDate as Date)}</div>}></Column>
                    <Column
                        header="More..."
                        body={(rowData: TDiagnosis) => (
                            <div>
                                <Button icon="pi pi-external-link" id={rowData.patientId} className="p-button-success" onClick={goToDiagnosisWithHangedData} />
                                <Button icon="pi pi-trash" className="p-button-danger ml-2" onClick={deletePausedDiagnosis} id={rowData.diagnosisId} />
                            </div>
                        )}
                    ></Column>
                </DataTable>
            </Dialog>
        </>
    );
};
export default ConsultationRoom;
