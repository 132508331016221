import { DatePicker, DiscountComponent, FilterSelect, GeneralPageProps, Loader, NumberInputWithButtons, SimpleTableWithMenu, tableEditOption } from '../../utils/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DropdownOption, TItem, TSales, TSalesItem, TSelectedPurchaseListItem, TUsers } from '../../utils/typesUtil';
import { Toast } from 'primereact/toast';
import { AppUserContext, displayMessage, formatDate, getBaseURL, getTableRowId, pageDataValidation, remakeDropdownSelects, updateCacheItem } from '../../utils/utils';
import { Dialog } from 'primereact/dialog';
import { CalendarChangeEvent } from 'primereact/calendar';
import { addRecordToCache, updateCacheRecord, useItemsListFetch, usePatientsListFetch, useSalesListFetch } from '../../utils/reactQueryUtils';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import Items from '../../classes/Items';
import Joi from 'joi';
import { v4 as uuidv4 } from 'uuid';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useQueryClient } from '@tanstack/react-query';
const _ = require('lodash');

type TSalesState = TSales & {
    isLoading: boolean;
    showSalesDialog: boolean;
    patientsList: DropdownOption[];
    salesItemsList: DropdownOption[];
    selectedSaleItem: string;
    editState: boolean;
    paymentChange: number;
    editingItemId: string;
    quantityEditOptions: string[];
    selectedEditOption: string;
    editQuantityValue: number;
    editingItemOriginalQty: number;
    itemsInEdit: TSalesItem[];
    discountOptions: string[];
    discountValue: string;
};
const INITIAL_STATE: TSalesState = {
    amountPaid: 0,
    modifiedBy: '',
    patientId: '',
    salesDate: new Date(),
    salesId: '',
    salesItems: [],
    isLoading: false,
    showSalesDialog: false,
    patientsList: [],
    salesItemsList: [],
    selectedSaleItem: '',
    editState: false,
    paymentChange: 0,
    editingItemId: '',
    quantityEditOptions: ['Add', 'Return'],
    selectedEditOption: '',
    editQuantityValue: 0,
    editingItemOriginalQty: 0,
    itemsInEdit: [],
    salesDiscount: { discountOption: 'Cash Discount', discountPercentage: 0, discountAmount: '0' },
    discountOptions: ['Cash Discount', 'Percentage Discount'],
    discountValue: '0'
};
const items = new Items();
const validateSales: Joi.ObjectSchema<TSales> = Joi.object({
    salesId: Joi.string(),
    salesDate: Joi.date(),
    patientId: Joi.string().messages({ 'string.empty': 'Select a patient to proceed to save' }),
    salesItems: Joi.array().min(1).messages({ 'array.min': 'Select at least one item to sell' }),
    amountPaid: Joi.number(),
    modifiedBy: Joi.string(),
    salesDiscount: Joi.object()
});
const validateItemQuantityAdd = Joi.object({
    selectedEditOption: Joi.string().messages({ 'string.empty': 'Select Action for quantity edit!' }),
    editQuantityValue: Joi.number().min(1).messages({ 'number.min': 'Quantity value should be more than 0' })
});
const Sales = () => {
    const queryClient = useQueryClient();
    const [salesState, setSalesState] = useState<TSalesState>(INITIAL_STATE);
    const toastRef = useRef<Toast>(null);
    const { data: patientsList, isLoading } = usePatientsListFetch({ urlLink: `${getBaseURL()}/patients/get_all_patients` });
    const { data: itemsList, isLoading: itemsLoading } = useItemsListFetch({ urlLink: `${getBaseURL()}/items/get_all_items` });
    const { data: salesList, isLoading: isSalesLoading, dataUpdatedAt: salesUpdatedAt } = useSalesListFetch({ urlLink: `${getBaseURL()}/items/get_all_sales` });
    const user = useContext<TUsers>(AppUserContext);
    const quantityEditRef = useRef<OverlayPanel>(null);
    const discountOPRef = useRef<OverlayPanel>(null);

    useEffect(() => {
        if (patientsList)
            setStateValue({
                patientsList: remakeDropdownSelects(patientsList!, 'fullName', 'patientId'),
                salesItemsList: remakeDropdownSelects(itemsList!, 'itemName', 'itemId'),
                modifiedBy: user.userId,
                salesId: uuidv4()
            });
    }, [patientsList]);
    const setStateValue = (stateValues: Partial<TSalesState>) => {
        setSalesState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };

    if (isLoading || itemsLoading || isSalesLoading) return <Loader />;
    const getStateValues = (): TSales => {
        const { salesId, patientId, salesDate, salesItems, modifiedBy, amountPaid, salesDiscount }: TSales = salesState;
        return {
            salesId,
            patientId,
            salesDate: formatDate(salesDate as Date),
            salesItems,
            modifiedBy,
            amountPaid,
            salesDiscount
        };
    };
    const salesMenu = () => {
        return [
            {
                label: 'New Sales',
                icon: 'pi pi-plus',
                command: () => setStateValue({ showSalesDialog: true })
            }
        ];
    };
    const onSalesDialogHide = () => {
        setStateValue({ showSalesDialog: false, editState: false, salesItems: [], patientId: '' });
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setStateValue({ [e.target.id]: e.value });
    };
    const onItemSelectionChange = (e: DropdownChangeEvent) => {
        const selectedItem: TItem = _.find(itemsList, (listItem: TItem) => listItem.itemId === e.value);
        const { itemId, itemName, itemCost, itemPrice } = selectedItem;
        setStateValue({
            selectedSaleItem: e.value,
            salesItems: [
                ...salesState.salesItems,
                {
                    itemId,
                    itemName,
                    itemQty: 1,
                    itemCost: parseFloat(itemCost),
                    itemPrice: parseFloat(itemPrice),
                    qtyAdded: 0,
                    qtyReturned: 0,
                    itemTotalCost: parseFloat(itemPrice)
                }
            ]
        });
    };
    const onItemSellPriceValueChange = async (e: InputNumberValueChangeEvent) => {
        const editingItem = salesState.salesItems.find((listItem: TSalesItem) => listItem.itemId === e.target.name);
        if (editingItem !== undefined) {
            const updatingItem = { ...editingItem, itemPrice: e.value!, itemTotalCost: e.value! * editingItem.itemQty };
            const salesItems = await updateCacheItem<TSalesItem>(updatingItem, editingItem, salesState.salesItems);
            setStateValue({ salesItems });
        }
    };
    const itemSellPriceInput = (rowData: TSalesItem) => {
        return <NumberInputWithButtons inputValue={rowData.itemPrice} inputValueChange={onItemSellPriceValueChange} numberInputName={rowData.itemId} />;
    };
    const onItemQuantityValueChange = async (e: InputNumberValueChangeEvent) => {
        const editingItem = salesState.salesItems.find((listItem: TSalesItem) => listItem.itemId === e.target.name);
        if (editingItem !== undefined) {
            const updatingItem: TSalesItem = { ...editingItem, itemQty: parseInt(e.value!.toString()), itemTotalCost: editingItem.itemPrice * e.value! };
            const newSaleItems = await updateCacheItem<TSalesItem>(updatingItem, editingItem, salesState.salesItems);
            setStateValue({ salesItems: newSaleItems, editQuantityValue: 0 });
        }
    };
    const itemQuantityInput = (rowData: TSalesItem) => {
        return <NumberInputWithButtons allowDecimalValues={false} disableState={salesState.editState} inputValue={rowData.itemQty} inputValueChange={onItemQuantityValueChange} numberInputName={rowData.itemId} />;
    };
    const deleteItemFromList = (e: React.MouseEvent<HTMLButtonElement>) => {
        const itemId = getTableRowId(e, 'id');
        if (salesState.editState) {
            if (_.find(salesState.itemsInEdit, (listItem: TSalesItem) => listItem.itemId === itemId)) {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Remove Lock',
                    message: 'Item cannot be removed from list. You can choose to return all the quantities!',
                    infoType: 'warn',
                    life: 5000
                });
                return;
            }
        }

        const listItems = salesState.salesItems.filter((item) => item.itemId !== itemId);
        setStateValue({ salesItems: listItems });
    };
    const getItemsTotalCost = () => {
        return salesState.salesItems.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.itemTotalCost;
        }, 0);
    };
    const onSalesAmountPaidChange = (e: InputNumberValueChangeEvent) => {
        setStateValue({ amountPaid: e.value! });
    };
    const getPaymentChange = () => {
        const paymentChange = getItemsTotalCost() - (salesState.amountPaid + parseFloat(salesState.salesDiscount.discountAmount));
        return paymentChange > 0 ? 0 : Math.abs(paymentChange);
    };
    const onCompleteSale = async () => {
        try {
            if (!pageDataValidation(validateSales, getStateValues(), toastRef)) return;
            setStateValue({ isLoading: true });
            const salesResponse = await items.newSales(getStateValues());

            if (salesResponse.data.status === 1) {
                await addRecordToCache<TSales>(queryClient, ['salesList'], salesResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'Sales was successfully saved!',
                    infoType: 'success',
                    life: 5000
                });
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const setupSalesEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const salesId = getTableRowId(e, 'id');
        const selectedSales: TSales = _.find(salesList, (listItem: TSales) => listItem.salesId === salesId);
        if (selectedSales !== undefined) {
            const { salesId, salesDate, salesItems, patientId, modifiedBy, amountPaid, discountAmount } = selectedSales;
            const parsedSaleItems = typeof salesItems !== 'object' ? JSON.parse(salesItems) : salesItems;
            const listItems = parsedSaleItems.map((listItem: TSalesItem) => {
                return { ...listItem, itemTotalCost: (listItem.itemQty - listItem.qtyReturned + listItem.qtyAdded) * listItem.itemPrice };
            });
            setStateValue({
                salesId,
                salesDate: new Date(salesDate as Date),
                patientId,
                salesItems: listItems,
                amountPaid,
                modifiedBy,
                showSalesDialog: true,
                editState: true,
                itemsInEdit: listItems,
                salesDiscount: { ...salesState.salesDiscount, discountAmount: discountAmount! }
            });
        }
    };
    const deleteSales = () => {};
    const editItemQty = (e: React.MouseEvent<HTMLButtonElement>) => {
        const itemId = getTableRowId(e, 'name');
        const selectedItem: TSalesItem = _.find(salesState.salesItems, (listItem: TSalesItem) => listItem.itemId === itemId);
        setStateValue({ editingItemId: itemId, editingItemOriginalQty: selectedItem.itemQty });
        quantityEditRef.current?.toggle(e);
    };
    const completeQuantityEditAction = async () => {
        if (!pageDataValidation(validateItemQuantityAdd, { selectedEditOption: salesState.selectedEditOption, editQuantityValue: salesState.editQuantityValue }, toastRef)) {
            return;
        }
        const affectingItem: TSalesItem = _.find(salesState.salesItems, (listItem: TSalesItem) => listItem.itemId === salesState.editingItemId);
        const addedQty = salesState.selectedEditOption === 'Add' ? salesState.editQuantityValue : 0;
        const returnedQty = salesState.selectedEditOption === 'Return' ? salesState.editQuantityValue : 0;
        const itemTotalCost = (affectingItem.itemQty - (affectingItem.qtyReturned + returnedQty) + (affectingItem.qtyAdded + addedQty)) * affectingItem.itemPrice;

        //check if item exists among editing items;
        if (!_.find(salesState.itemsInEdit, (saleItem: TSalesItem) => saleItem.itemId === salesState.editingItemId)) {
            const updatedItem: TSalesItem = { ...affectingItem, itemQty: addedQty, itemTotalCost: affectingItem.itemPrice * addedQty };
            const newItems = await updateCacheItem<TSalesItem>(updatedItem, affectingItem, salesState.salesItems);
            setStateValue({ salesItems: newItems, editQuantityValue: 0 });
            return;
        }
        const affectingItemEdited: TSalesItem = { ...affectingItem, qtyReturned: returnedQty + affectingItem.qtyReturned, qtyAdded: addedQty + affectingItem.qtyAdded, itemTotalCost };
        const newSalesItems = await updateCacheItem<TSalesItem>(affectingItemEdited, affectingItem, salesState.salesItems);
        setStateValue({ salesItems: newSalesItems, editQuantityValue: 0 });
    };
    const updateSales = async () => {
        try {
            if (!pageDataValidation<TSales>(validateSales, getStateValues(), toastRef)) return;
            setStateValue({ isLoading: true });
            const salesUpdateResponse = await items.updateSales(getStateValues());
            if (salesUpdateResponse.data.status === 1) {
                await updateCacheRecord<TSales>(queryClient, ['salesList'], [salesUpdateResponse.data.operatedData, salesState.salesId, 'salesId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Sale was successfully updated!',
                    infoType: 'success',
                    life: 5000
                });
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const resetState = () => {
        setStateValue({ salesId: uuidv4(), salesDate: new Date(), patientId: '', salesItems: [], amountPaid: 0, salesDiscount: { discountAmount: '0', discountPercentage: 0, discountOption: '' } });
    };
    const onSelectButtonChange = (e: SelectButtonChangeEvent) => {
        if (e.value === 'Return' && !_.find(salesState.itemsInEdit, (listItem: TSelectedPurchaseListItem) => listItem.itemId === salesState.editingItemId)) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: 'You cannot issue return for this item. It is just being added!',
                infoType: 'warn',
                life: 5000
            });
            return;
        }
        setStateValue({ selectedEditOption: e.value });
    };
    const onDiscountOptionChange = (e: SelectButtonChangeEvent) => {
        setStateValue({ salesDiscount: { ...salesState.salesDiscount, discountOption: e.value } });
    };
    const onDiscountAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateValue({ discountValue: e.target.value });
    };
    const applyDiscount = () => {
        const totalSales = getItemsTotalCost();
        const discountPercent = salesState.salesDiscount.discountOption === 'Cash Discount' ? (parseFloat(salesState.discountValue) / totalSales) * 100 : parseFloat(salesState?.discountValue!);
        const discountAmount = salesState.salesDiscount.discountOption === 'Cash Discount' ? salesState.discountValue : ((parseFloat(salesState?.discountValue!) / 100) * totalSales).toFixed(2);
        setStateValue({ salesDiscount: { ...salesState.salesDiscount, discountPercentage: discountPercent, discountAmount } });
        displayMessage({
            toastComponent: toastRef,
            header: 'Discount Applied',
            message: 'Discount was successfully applied to sales',
            infoType: 'success',
            life: 5000
        });
    };
    return (
        <>
            {salesState.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={'salesId'}
                    columnsDef={[
                        { body: (rowData: TSales) => <div>{formatDate(rowData.salesDate as Date)}</div>, header: 'Date' },
                        { field: 'fullName', header: 'Patient' },
                        { field: 'username', header: 'User' },
                        { body: (rowData: TSales) => tableEditOption(setupSalesEdit, deleteSales, rowData.salesId), header: 'Edit' }
                    ]}
                    tableData={salesList}
                    menuModel={salesMenu()}
                    hasMenuList={true}
                    tableTitle="Sales List"
                    lastTableUpdate={salesUpdatedAt}
                    childTableDef={[]}
                    searchValues={['fullName', 'salesDate', 'modifiedBy']}
                    searchFieldPlaceHolder="Search by Patient Name, Sales Date or Sales Person"
                />
            </div>
            <Dialog onHide={onSalesDialogHide} visible={salesState.showSalesDialog} maximized>
                <div className="lg:min-h-full">
                    <div className="card">
                        <div className="p-fluid">
                            <div className="grid p-formgrid">
                                <div className="field lg:col-6 md:col-12 col-12">
                                    <label htmlFor="salesDate">Sales Date</label>
                                    <DatePicker
                                        dateValue={salesState.salesDate}
                                        onDateChange={(e: CalendarChangeEvent) => setStateValue({ salesDate: e.value! })}
                                        labelText="Sales Date"
                                        controlId="salesDate"
                                        selectionType="single"
                                        displayButtonBar={true}
                                        displayTime={false}
                                    />
                                </div>
                                <div className="field lg:col-6 md:col-12 col-12">
                                    <FilterSelect selectableOptions={salesState.patientsList} selectedOption={salesState.patientId!} onSelectChange={onSelectChange} elementId="patientId" defaultValue="Patients" />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <FilterSelect selectableOptions={salesState.salesItemsList} selectedOption={salesState.selectedSaleItem!} onSelectChange={onItemSelectionChange} elementId="selectedSaleItem" defaultValue="Items" />
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="grid">
                                <DataTable value={salesState.salesItems} emptyMessage="Select an item to continue">
                                    <Column
                                        header="Item"
                                        style={{ width: '30rem' }}
                                        body={(rowData: TSalesItem) => (
                                            <div>
                                                {`${rowData.itemName}`}{' '}
                                                <span>
                                                    {rowData.qtyReturned > 0 || rowData.qtyAdded > 0 ? (
                                                        <>
                                                            <Badge value={rowData.qtyReturned} severity="danger" className="mr-2" />
                                                            <Badge value={rowData.qtyAdded} severity="success" />
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    ></Column>
                                    <Column header="Sell Price" style={{ width: '20rem' }} body={itemSellPriceInput}></Column>
                                    <Column header="Qty" style={{ width: '20rem' }} body={itemQuantityInput}></Column>
                                    <Column field="itemTotalCost" header="Total Cost" style={{ width: '20rem' }}></Column>
                                    <Column
                                        body={(rowData: TSalesItem) => (
                                            <div>
                                                <Button icon="pi pi-trash" className="p-button-danger" id={rowData.itemId} onClick={deleteItemFromList} />

                                                <Button icon="pi pi-pencil" className="ml-2 p-button-warning" name={rowData.itemId} onClick={editItemQty} />
                                            </div>
                                        )}
                                        header="Edit"
                                        style={{ width: '10rem' }}
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="p-fluid mt-4">
                            <div className="grid">
                                <div className="col-4">Total Cost</div>
                                <div className="col-4">{getItemsTotalCost()}</div>
                            </div>
                            <div className="grid mt-3">
                                <div className="col-4">Total Paid:</div>
                                <div className="col-2 align-items-start">
                                    <NumberInputWithButtons inputValue={salesState.amountPaid} inputValueChange={onSalesAmountPaidChange} numberInputId="salesAmountPaid" />
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-4">Discount</div>
                                <div className="col-4">{salesState.salesDiscount.discountAmount}</div>
                            </div>
                            <div className="grid">
                                <div className="col-4">Payment Change</div>
                                <div className="col-4">{getPaymentChange()}</div>
                            </div>
                            <div className="grid">
                                <div className="col-2">
                                    <Button onClick={(e) => discountOPRef.current?.toggle(e)}>Allow Discount</Button>
                                </div>
                                <div className="col-2">
                                    <Button onClick={!salesState.editState ? onCompleteSale : updateSales}>{!salesState.editState ? `Complete Sales` : `Update Sales`}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <OverlayPanel ref={quantityEditRef} className="lg:w-3">
                <div className="card">
                    <div className="grid">
                        <div className="col-12">
                            <label>Select What to do</label>
                            <SelectButton className="mt-2" value={salesState.selectedEditOption} onChange={onSelectButtonChange} options={salesState.quantityEditOptions} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <label>Editing Quantity</label>
                            <InputNumber className="mt-2" value={salesState.editQuantityValue} onValueChange={(e) => setStateValue({ editQuantityValue: e.value! })} />
                        </div>
                    </div>
                    <Button className="mt-2 w-7" onClick={completeQuantityEditAction}>{`${salesState.selectedEditOption} Quantity`}</Button>
                </div>
            </OverlayPanel>
            <DiscountComponent
                discountOPRef={discountOPRef}
                discountOptions={salesState.discountOptions}
                onDiscountOptionChange={onDiscountOptionChange}
                discountSelectedOption={salesState.salesDiscount.discountOption}
                discountAmount={salesState.discountValue}
                onDiscountAmountChange={onDiscountAmountChange}
                applyDiscountAction={applyDiscount}
            />
        </>
    );
};
export default Sales;
