import { ConfirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { DatePickerProps, FileUploaderProps, FilterSelectProps, GeneralPagePropsProps, MenuModel, SelectableOptions, TIconTextInput, TPatientAndEmergency } from './typesUtil';
import { Calendar } from 'primereact/calendar';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { useRef, Fragment, useState, MutableRefObject } from 'react';
import { DataTable, DataTableExpandedRows, DataTableFilterMeta, DataTableValueArray } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent, ItemTemplateOptions } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
export const GeneralPageProps: React.FC<GeneralPagePropsProps> = ({ toastRef, toastPosition = 'bottom-right' }) => {
    return (
        <>
            <Toast ref={toastRef} position={toastPosition} />
            <ConfirmPopup />
        </>
    );
};

export const DatePicker: React.FC<DatePickerProps> = ({
    dateValue,
    onDateChange,
    labelText,
    controlId,
    dateFormat = 'yy-mm-dd',
    displayTime = false,
    selectionType = 'single',
    displayButtonBar = false,
    minimumDateValue,
    maximumDateValue,
    view = 'date'
}) => {
    return (
        <div className="">
            <Calendar
                name={controlId}
                id={controlId}
                placeholder={labelText}
                className="expanded-control mr-3"
                value={dateValue}
                onChange={onDateChange}
                dateFormat={dateFormat}
                selectionMode={selectionType}
                showButtonBar={displayButtonBar}
                showTime={displayTime}
                minDate={minimumDateValue}
                maxDate={maximumDateValue}
                view={view}
            />
        </div>
    );
};
type TCheckBoxProps = {
    checkLabel: string;
    checkedState: boolean;
    onCheckChange: (e: CheckboxChangeEvent) => void;
    inputId: string;
    inputName: string;
    disabled?: boolean;
};
export const CheckboxInput = ({ checkLabel, checkedState, onCheckChange, inputId, inputName, disabled = false }: TCheckBoxProps) => {
    return (
        <div className="field grid">
            <div className="col-12 md:col-12">
                <div className="field-checkbox">
                    <Checkbox id={inputId} name={inputName} value={checkLabel} checked={checkedState} onChange={onCheckChange} disabled={disabled} />
                    <label htmlFor={inputId}>{checkLabel}</label>
                </div>
            </div>
        </div>
    );
};
type TRadioButtonProps = {
    inputId: string;
    inputName: string;
    checkedValue: boolean;
    onRadioChange: (e: RadioButtonChangeEvent) => void;
    radioLabel: string;
    customClasses: string;
};
export const RadioButtonInput = ({ inputId, inputName, checkedValue, onRadioChange, radioLabel, customClasses }: TRadioButtonProps) => {
    return (
        <div className="flex align-items-center">
            <RadioButton id={inputId} className={customClasses} name={inputName} value={radioLabel} onChange={onRadioChange} checked={checkedValue} />
            <label htmlFor={inputId} className="ml-2">
                {radioLabel}
            </label>
        </div>
    );
};

interface ColumnDefinition {
    field?: string;
    header: string;
    body?: (rowData: any) => JSX.Element;
    style?: string;
}

interface SimpleTableWithMenuProps {
    tableKey: string;
    columnsDef: ColumnDefinition[];
    tableData: any[] | undefined;
    showTableLines?: boolean;
    tableMinWidth?: number;
    menuModel?: MenuModel[];
    tableTitle?: string;
    hasMenuList?: boolean;
    lastTableUpdate?: number;
    childTableDef: any;
    searchValues: string[];
    searchFieldPlaceHolder?: string;
    childTableHeading?: string;
    refreshTableAction?: () => void;
}

export const SimpleTableWithMenu = ({
    tableKey,
    columnsDef,
    tableData,
    showTableLines = true,
    tableMinWidth = 80,
    menuModel,
    tableTitle = 'Table Data',
    hasMenuList = false,
    lastTableUpdate,
    childTableDef,
    searchValues = [],
    searchFieldPlaceHolder = '',
    childTableHeading,
    refreshTableAction
}: SimpleTableWithMenuProps) => {
    const menuRef = useRef<Menu>(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
    const onClickToDisplayMenu = (e: React.MouseEvent) => {
        const menuElement = menuRef.current;
        if (menuElement && typeof menuElement.toggle === 'function') {
            menuElement.toggle(e);
        } else {
            console.error('toggle function is not available on the menu element.');
        }
    };

    const MenuComponent = () => {
        return (
            <Fragment>
                <div className="flex align-items-center justify-content-between mb-2">
                    <h5>{tableTitle}</h5>
                    <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={onClickToDisplayMenu} />
                    <Menu ref={menuRef} popup model={menuModel} />
                </div>
            </Fragment>
        );
    };
    const RowExpansionTemplate = (dataArr: any) => {
        return (
            <React.Fragment>
                <h6 className="text-cyan-200">{childTableHeading}</h6>
                <DataTable value={[dataArr]} responsiveLayout="scroll">
                    {childTableDef!.length > 0 &&
                        childTableDef!.map((childTable: any, index: number) => {
                            return <Column key={index} field={childTable.field} header={childTable.header}></Column>;
                        })}
                </DataTable>
            </React.Fragment>
        );
    };
    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const renderHeader = () => {
        return (
            <div className="flex flex-wrap justify-content-evenly align-content-start">
                <div className="flex align-items-center justify-content-center w-25rem font-bold">
                    <Button icon="pi pi-refresh" onClick={refreshTableAction} />
                </div>
                <div className="flex align-items-center justify-content-center w-25rem font-bold">
                    <span className="p-input-icon-right">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={searchFieldPlaceHolder} autoComplete="off" />
                    </span>
                </div>
            </div>
        );
    };
    return (
        <React.Fragment>
            {hasMenuList && <MenuComponent />}
            <DataTable
                value={tableData}
                paginator
                scrollable
                showGridlines={showTableLines}
                dataKey={tableKey}
                tableStyle={{ minWidth: `${tableMinWidth}rem` }}
                expandedRows={expandedRows}
                rowExpansionTemplate={RowExpansionTemplate}
                onRowToggle={(e) => setExpandedRows(e.data)}
                globalFilterFields={searchValues}
                filters={filters}
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                header={renderHeader()}
            >
                {childTableDef.length > 0 && <Column expander={true} style={{ width: '3em' }} />}
                {columnsDef.length > 0 && columnsDef.map((columnDef, index) => <Column key={index} field={columnDef.field} header={columnDef.header} body={columnDef.body} style={{ width: columnDef.style }} />)}
            </DataTable>
            <span className="small">{`${new Date(lastTableUpdate!)}`}</span>
            {/*<Tag value={`${new Date(lastTableUpdate!)}`} severity="success" className="mt-3" icon="pi pi-refresh"/>*/}
        </React.Fragment>
    );
};
export const IconTextInput = ({ value, onInputChange, placeholderValue, iconText, componentId, customClasses }: TIconTextInput) => {
    return (
        <>
            <div className={`expanded-control ${customClasses}`}>
                <div className="mb-2">
                    <label htmlFor={componentId} className="text-blue-200">
                        {placeholderValue}
                    </label>
                </div>
                <span className="p-input-icon-left">
                    <i className={`pi ${iconText}`} />
                    <InputText type="text" id={componentId} value={value} placeholder={placeholderValue} onChange={onInputChange} autoComplete="off" />
                </span>
            </div>
        </>
    );
};
export const FilterSelect: React.FC<FilterSelectProps> = ({ selectableOptions = [], selectedOption = '', onSelectChange, customClasses = '', elementId = '', defaultValue = '', showClearIcon = false, tooltip = '' }) => {
    const selectedValueTemplate = (option: SelectableOptions) => {
        if (option) {
            return (
                <div className="item-value flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }
        return <span>Select</span>;
    };

    const optionsTemplate = (option: { name: string }) => {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div className={`filter-select ${customClasses}`}>
            <div className="mb-2">
                <label htmlFor={elementId} className="text-blue-200">
                    {defaultValue}
                </label>
            </div>
            <Dropdown
                value={selectedOption}
                options={selectableOptions}
                onChange={onSelectChange}
                optionLabel="name"
                filter
                filterBy="name"
                placeholder="Select"
                id={elementId}
                defaultValue={defaultValue}
                valueTemplate={selectedValueTemplate}
                itemTemplate={optionsTemplate}
                name={elementId}
                className="w-full"
                showClear={showClearIcon}
                tooltip={tooltip}
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    );
};
export const Loader = () => {
    return (
        <div className="loader-container">
            <ProgressSpinner style={{ width: '100px', height: '100px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration="1.5s" />
        </div>
    );
};
export const tableEditOption = (editClick: (e: React.MouseEvent<HTMLButtonElement>) => void, deleteClick: (e: React.MouseEvent<HTMLButtonElement>) => void, idProperty = '', recordStatus?: 'online' | 'offline') => {
    return (
        <React.Fragment>
            <Button id={idProperty} icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={editClick} />
            <Button icon="pi pi-trash" name={idProperty} className="p-button-rounded p-button-warning" onClick={deleteClick} />
        </React.Fragment>
    );
};

export default function FileUploader({ onFileUpload, fileUploadRef, acceptableFileTypes = 'image/*', id, maximumFileSize = 1000000, acceptMultipleFiles = false, fileType = 'otherFiles' }: FileUploaderProps) {
    const toast = useRef<Toast>(null);
    const [totalSize, setTotalSize] = useState(0);

    const onTemplateSelect = (e: FileUploadSelectEvent) => {
        let _totalSize = totalSize;
        let files = e.files;

        for (let i = 0; i < files.length; i++) {
            _totalSize += files[i].size || 0;
        }

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e: FileUploadUploadEvent) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current?.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file: File, callback: Function) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
        const file = inFile as File;
        // @ts-ignore
        const imageFilePath = file.objectURL;
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    {fileType === 'otherFiles' ? <i className="pi pi-file-excel"></i> : <img alt={file.name} role="presentation" src={imageFilePath} width={100} />}
                    <span className="flex flex-column text-left ml-3">
                        {`${file.name} ${props.formatSize}`}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                {/*<Tag value={props.formatSize} severity="warning" className="px-3 py-2" />*/}
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop File Here
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

            <FileUpload
                ref={fileUploadRef}
                name={id}
                uploadHandler={onFileUpload}
                customUpload={true}
                accept={acceptableFileTypes}
                maxFileSize={maximumFileSize}
                onUpload={onTemplateUpload}
                onSelect={onTemplateSelect}
                onError={onTemplateClear}
                onClear={onTemplateClear}
                headerTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                multiple={acceptMultipleFiles}
                chooseOptions={chooseOptions}
                uploadOptions={uploadOptions}
                cancelOptions={cancelOptions}
            />
        </div>
    );
}

type TNumberInputWithButtons = {
    numberInputId?: string;
    numberInputName?: string;
    inputValue: number;
    inputValueChange: (e: InputNumberValueChangeEvent) => void;
    disableState?: boolean;
    allowDecimalValues?: boolean;
};
export const NumberInputWithButtons = ({ inputValue, inputValueChange, numberInputName, numberInputId, disableState = false, allowDecimalValues = true }: TNumberInputWithButtons) => {
    return (
        <InputNumber
            inputId="horizontal-buttons"
            id={numberInputId}
            name={numberInputName}
            value={inputValue}
            onValueChange={inputValueChange}
            showButtons
            buttonLayout="horizontal"
            step={1}
            decrementButtonClassName="p-button-danger"
            incrementButtonClassName="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            mode="decimal"
            min={0}
            disabled={disableState}
            minFractionDigits={0}
            maxFractionDigits={allowDecimalValues ? 2 : undefined}
        />
    );
};

type TDiscountComponentProps = {
    discountOPRef: MutableRefObject<OverlayPanel | null>;
    discountOptions: string[];
    onDiscountOptionChange: (e: SelectButtonChangeEvent) => void;
    discountSelectedOption: string;
    discountAmount: string;
    onDiscountAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    applyDiscountAction: () => void;
};
export const DiscountComponent = ({ discountOPRef, discountOptions, onDiscountOptionChange, discountSelectedOption, discountAmount, onDiscountAmountChange, applyDiscountAction }: TDiscountComponentProps) => {
    return (
        <OverlayPanel ref={discountOPRef} className="lg:w-4">
            <h6>Billing Discount</h6>
            <div className="p-fluid">
                <div className="grid p-formgrid">
                    <div className="field lg:col-12 md:col-12 col-12">
                        <label>Select Discount Type</label>
                        <SelectButton className="mt-2" value={discountSelectedOption} onChange={onDiscountOptionChange} options={discountOptions} />
                    </div>
                    <div className="field lg:col-12 md:col-12 col-12">
                        <label>Discount Amount</label>
                        <InputText type="number" value={discountAmount} onChange={onDiscountAmountChange} id="price" min={0} />
                    </div>
                    <div className="field lg:col-4 md:col-12 col-12">
                        <Button onClick={applyDiscountAction}>Give Discount</Button>
                    </div>
                </div>
            </div>
        </OverlayPanel>
    );
};
