import { Card } from 'primereact/card';
import FileUploader, { DatePicker, GeneralPageProps, Loader, SimpleTableWithMenu } from '../utils/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { useLabRequestsListFetch } from '../utils/reactQueryUtils';
import { AppUserContext, displayMessage, encodeFilesToData, getBaseURL, getTableRowId, getThisMonthDates, getTodayDates } from '../utils/utils';
import { Button } from 'primereact/button';
import { Item, TLabRequest, TPatientsLabRequests, TScannedFiles, TUsers } from '../utils/typesUtil';
import { OverlayPanel } from 'primereact/overlaypanel';
import { format } from 'date-fns';
import { CalendarChangeEvent } from 'primereact/calendar';
import Diagnosis from '../classes/Diagnosis';
import { Dialog } from 'primereact/dialog';
import { FileUploadFilesEvent } from 'primereact/fileupload';
import { DataView } from 'primereact/dataview';
import { Divider } from 'primereact/divider';
import { useNavigate } from 'react-router-dom';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';

type TLabRequestPage = {
    requestsList: TLabRequest[];
    impressionDate: string | Date | Date[];
    dispatchedDate: string | Date | Date[];
    receivedDate: string | Date | Date[];
    fittedDate: string | Date | Date[];
    editingObject?: TLabRequest;
    diagnosisId: string;
    showDialog: boolean;
    scannedFiles: TScannedFiles[];
    scannedFileNames: string[];
    isLoading: boolean;
    requestFiles: string[];
    requestId: string;
};
const items = [
    { name: 'Today', value: 1 },
    { name: 'This Month', value: 2 },
    { name: 'All Time', value: 3 }
];
const labs = new Diagnosis();
const LabRequests = () => {
    const toastRef = useRef<Toast>(null);
    const navigate = useNavigate();
    const { data: labRequests, isLoading, refetch, dataUpdatedAt } = useLabRequestsListFetch({ urlLink: `${getBaseURL()}/requests/get_lab_requests` });
    const datesEditOPRef = useRef<OverlayPanel>(null);
    const scannedFileOPRef = useRef<OverlayPanel>(null);
    const viewFilesOPRef = useRef<OverlayPanel>(null);
    const uploadScannedFilesRef = useRef(null);
    const [requestsState, setRequestsState] = useState<TLabRequestPage>({
        requestsList: [],
        impressionDate: new Date(),
        dispatchedDate: new Date(),
        receivedDate: new Date(),
        fittedDate: new Date(),
        editingObject: undefined,
        diagnosisId: '',
        showDialog: false,
        scannedFiles: [],
        scannedFileNames: [],
        isLoading: false,
        requestFiles: [],
        requestId: ''
    });
    const user = useContext<TUsers>(AppUserContext);
    const [value, setValue] = useState<Item | number>(1);
    const [labRequestsList, setLabRequestsLists] = useState<TPatientsLabRequests[]>([]);
    useEffect(() => {
        if (!user) navigate('/login');
    }, []);
    if (isLoading) return <Loader />;
    const labMenu = () => {
        return [
            {
                label: 'Refresh',
                icon: 'pi pi-refresh',
                command: () => refetch()
            }
        ];
    };
    const setupLabsView = (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const diagnosisId = getTableRowId(e, 'id');
            const selectedDiagnosis = labRequests?.find((request: TPatientsLabRequests) => request.diagnosisId === diagnosisId);

            const requestsList = typeof selectedDiagnosis?.labRequests === 'string' ? JSON.parse(selectedDiagnosis!.labRequests) : [];

            setRequestsState((prevState) => {
                return { ...prevState, requestsList: requestsList, diagnosisId, showDialog: true };
            });
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        }
    };

    const setupDatesEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const requestId = getTableRowId(e, 'id');

            const selectedRequest: TLabRequest | undefined = requestsState.requestsList.find((request: TLabRequest) => request.requestId === requestId);

            const { dateImpressionTaken, dispatchedDate, receivedDate, applianceFittedDate } = selectedRequest!;
            setRequestsState((prevState) => {
                return {
                    ...prevState,
                    impressionDate: new Date(dateImpressionTaken as Date),
                    dispatchedDate: new Date(dispatchedDate as Date),
                    receivedDate: new Date(receivedDate as Date),
                    fittedDate: new Date(applianceFittedDate as Date),
                    editingObject: selectedRequest
                };
            });
            datesEditOPRef.current?.toggle(e);
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        }
    };

    const updateRequestData = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const updatedRequest = {
                ...requestsState.editingObject,
                dateImpressionTaken: requestsState.impressionDate,
                receivedDate: requestsState.receivedDate,
                applianceFittedDate: requestsState.fittedDate,
                dispatchedDate: requestsState.dispatchedDate,
                diagnosisId: requestsState.diagnosisId
            };
            setRequestsState((prevState) => {
                return { ...prevState, isLoading: true };
            });
            const labUpdateResponse = await labs.updateLabRequest({ updatedRequest });

            if (labUpdateResponse.data.status === 1) {
                displayMessage({
                    header: 'Upload Success',
                    message: 'Request information was successfully updated!',
                    infoType: 'success',
                    toastComponent: toastRef,
                    life: 5000
                });
                await refetch();
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setRequestsState((prevState) => {
                return { ...prevState, isLoading: false };
            });
        }
    };
    const onDateChange = (e: CalendarChangeEvent) => {
        setRequestsState((prevState) => {
            return { ...prevState, [e.target.id]: e.value };
        });
    };
    const onCloseDialog = () => {
        setRequestsState((prevState) => {
            return { ...prevState, showDialog: false };
        });
    };
    const setScannedFilesUpload = async (event: FileUploadFilesEvent) => {
        try {
            const fileItems: TScannedFiles[] = [...requestsState.scannedFiles];
            for (let i = 0; i < event.files.length; i++) {
                fileItems.push(await encodeFilesToData(event.files[i]));
            }
            const scannedFiles = fileItems;
            const scannedFileNames = fileItems.map((item: TScannedFiles) => item.fName);
            await completeFilesUpload(scannedFiles, scannedFileNames);
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        }
    };
    const completeFilesUpload = async (scannedFiles: TScannedFiles[], scannedFileNames: string[]) => {
        try {
            if (scannedFileNames.length === 0) {
                displayMessage({
                    header: 'Error',
                    message: 'Add at least one file to the process list to continue',
                    infoType: 'error',
                    toastComponent: toastRef,
                    life: 5000
                });
                return;
            }
            setRequestsState((prevState) => {
                return { ...prevState, isLoading: true };
            });

            const uploadResponse = await labs.uploadLabFiles({
                scannedFiles: scannedFiles,
                scannedFileNames: [...scannedFileNames, ...requestsState.requestFiles],
                diagnosisId: requestsState.diagnosisId,
                requestId: requestsState.requestId
            });
            if (uploadResponse.data.status === 1) {
                setRequestsState((prevState) => {
                    return { ...prevState, showDialog: false };
                });
                scannedFileOPRef.current?.hide();
                displayMessage({
                    header: 'Upload Success',
                    message: 'Files were successfully uploaded for request',
                    infoType: 'success',
                    toastComponent: toastRef,
                    life: 5000
                });
                await refetch();
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setRequestsState((prevState) => {
                return { ...prevState, isLoading: false };
            });
        }
    };
    const onSelectionChange = (e: SelectButtonChangeEvent) => {
        switch (e.value) {
            case 1:
                setLabRequestsLists(getTodayDates<TPatientsLabRequests>(labRequests!, 'diagnosisDate'));
                break;
            case 2:
                setLabRequestsLists(getThisMonthDates<TPatientsLabRequests>(labRequests!, 'diagnosisDate'));
                break;
            case 3:
                setLabRequestsLists(labRequests!);
                break;
            default:
                return labRequests;
        }
        setValue(e.value);
    };
    const scannedFileNamesTemplate = (scannedFile: string) => {
        return (
            <>
                <div className="col-12">
                    <div className="flex flex-column xl:flex-row xl:align-items-start">
                        <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1">
                            <a href={`${getBaseURL()}/scannedFiles/${scannedFile}`} target="_blank">
                                {scannedFile}
                            </a>
                        </div>
                    </div>
                </div>
                <Divider />
            </>
        );
    };
    const openApplicationFiles = (event: React.MouseEvent<HTMLButtonElement>) => {
        const requestId = getTableRowId(event, 'id');
        setRequestsState((prevState) => {
            return { ...prevState, requestFiles: getRequestFiles(requestId) };
        });
        viewFilesOPRef.current?.toggle(event);
    };
    const openUploadDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
        const requestId = getTableRowId(e, 'id');
        const currentRequestFiles = getRequestFiles(requestId) !== undefined ? getRequestFiles(requestId) : [];
        setRequestsState((prevState) => {
            return { ...prevState, requestId, requestFiles: currentRequestFiles };
        });
        scannedFileOPRef.current?.toggle(e);
    };
    const getRequestFiles = (requestId: string) => {
        const selectedRequest = requestsState.requestsList.find((req: TLabRequest) => req.requestId === requestId)!;
        return typeof selectedRequest.requestFiles === 'string' ? JSON.parse(selectedRequest.requestFiles) : selectedRequest.requestFiles;
    };

    return (
        <>
            <Card>
                {requestsState.isLoading && <Loader />}
                <GeneralPageProps toastRef={toastRef} />
                <div className="card flex justify-content-center">
                    <SelectButton value={value} onChange={onSelectionChange} optionLabel="name" options={items} />
                </div>
                <div className="p-fluid lg:pl-5">
                    <SimpleTableWithMenu
                        tableKey={'diagnosisId'}
                        columnsDef={[
                            { field: 'fullName', header: 'Name' },
                            { body: (rowData: TPatientsLabRequests) => <div>{format(new Date(rowData.diagnosisDate), 'yyyy-MM-dd')}</div>, header: 'Date' },
                            { field: 'phoneNumber', header: 'Phone' },
                            { field: 'location', header: 'Location' },
                            {
                                body: (rowData: any) => (
                                    <div className="undeline text-blue-200 cursor-pointer">
                                        <Button id={rowData.diagnosisId} className="bg-transparent border-transparent" onClick={setupLabsView}>
                                            View Labs
                                        </Button>
                                    </div>
                                ),
                                header: 'View Labs',
                                style: '5'
                            }
                        ]}
                        tableData={labRequestsList}
                        menuModel={labMenu()}
                        hasMenuList={true}
                        tableTitle="Lab Requests List"
                        // lastTableUpdate={dataUpdatedAt}
                        childTableDef={[]}
                        searchValues={['fullName', 'phoneNumber', 'location']}
                        searchFieldPlaceHolder="Search by Full Name, Phone Number or Location"
                    />
                </div>
            </Card>
            <Dialog onHide={onCloseDialog} visible={requestsState.showDialog} closeOnEscape header="Diagnosis Lab Requests" position="top">
                <Card>
                    <SimpleTableWithMenu
                        tableKey={'requestId'}
                        columnsDef={[
                            { field: 'nameOfLab', header: 'Lab' },
                            { body: (rowData: TLabRequest) => <div>{format(new Date(rowData.dateImpressionTaken as Date), 'yyyy-MM-dd')}</div>, header: 'Impression Date' },
                            { body: (rowData: TLabRequest) => <div>{format(new Date(rowData.dispatchedDate as Date), 'yyyy-MM-dd')}</div>, header: 'Dispatched Date' },
                            { body: (rowData: TLabRequest) => <div>{format(new Date(rowData.receivedDate as Date), 'yyyy-MM-dd')}</div>, header: 'Received Date' },
                            { body: (rowData: TLabRequest) => <div>{format(new Date(rowData.applianceFittedDate as Date), 'yyyy-MM-dd')}</div>, header: 'Fitted Date' },
                            { body: (rowData: TLabRequest) => <Button id={rowData.requestId} className="" icon="pi pi-pencil" rounded onClick={setupDatesEdit}></Button>, header: 'Edit Request' },
                            { body: (rowData: TLabRequest) => <Button id={rowData.requestId} className="" icon="pi pi-upload" rounded onClick={openUploadDialog}></Button>, header: 'Upload Copies' },
                            { body: (rowData: TLabRequest) => <Button id={rowData.requestId} className="" icon="pi pi-file" rounded onClick={openApplicationFiles}></Button>, header: 'Files' }
                        ]}
                        tableData={requestsState?.requestsList}
                        hasMenuList={false}
                        tableTitle="Lab Requests List"
                        lastTableUpdate={dataUpdatedAt}
                        childTableDef={[
                            { field: 'selectedProsthesis', header: 'Prosthesis' },
                            { field: 'selectedMaterialType', header: 'Material Type' },
                            { field: 'numberOfUnits', header: 'Units' },
                            { field: 'courier', header: 'Courier' },
                            { field: 'carNumber', header: 'Car Number' },
                            { field: 'driverContact', header: 'Driver' },
                            { field: 'station', header: 'Station' },
                            { field: 'code', header: 'Code' }
                        ]}
                        searchValues={['prosthesis', 'Material', 'location']}
                        searchFieldPlaceHolder="Search by Full Name, Phone Number or Location"
                        childTableHeading="More Lab Request Info"
                    />
                </Card>
            </Dialog>
            <OverlayPanel ref={datesEditOPRef}>
                <div className="grid">
                    <div className="field lg:col-6 md:col-12 col-12">
                        <label htmlFor="impressionDate">Impression Date</label>
                        <DatePicker dateValue={requestsState.impressionDate} onDateChange={onDateChange} labelText="Impression Date" controlId="impressionDate" selectionType="single" displayButtonBar={true} displayTime={false} />
                    </div>
                    <div className="field lg:col-6 md:col-12 col-12">
                        <label htmlFor="dispatchedDate">Parcel Dispatched Date</label>
                        <DatePicker
                            dateValue={requestsState.dispatchedDate}
                            onDateChange={onDateChange}
                            labelText="Dispatched Date"
                            controlId="dispatchedDate"
                            selectionType="single"
                            displayButtonBar={true}
                            displayTime={false}
                            maximumDateValue={requestsState.impressionDate as Date}
                        />
                    </div>
                    <div className="field lg:col-6 md:col-12 col-12">
                        <label htmlFor="receivedDate">Parcel Received Date</label>
                        <DatePicker dateValue={requestsState.receivedDate} onDateChange={onDateChange} labelText="Received Date" controlId="receivedDate" selectionType="single" displayButtonBar={true} displayTime={false} />
                    </div>
                    <div className="field lg:col-6 md:col-12 col-12">
                        <label htmlFor="fittedDate">Appliance Fitted Date</label>
                        <DatePicker dateValue={requestsState.fittedDate} onDateChange={onDateChange} labelText="Fitted Date" controlId="fittedDate" selectionType="single" displayButtonBar={true} displayTime={false} />
                    </div>
                    <div className="field lg:col-6 md:col-12 col-12">
                        <Button onClick={updateRequestData}>Update Request</Button>
                    </div>
                </div>
            </OverlayPanel>
            <OverlayPanel ref={scannedFileOPRef}>
                <FileUploader acceptMultipleFiles={true} onFileUpload={setScannedFilesUpload} fileUploadRef={uploadScannedFilesRef} id="applicationFiles" acceptableFileTypes=".pdf" />
            </OverlayPanel>
            <OverlayPanel ref={viewFilesOPRef}>{<DataView value={requestsState.requestFiles} emptyMessage="Nothing Found" itemTemplate={scannedFileNamesTemplate} />}</OverlayPanel>
        </>
    );
};
export default LabRequests;
