import { PersistedClient, Persister } from '@tanstack/react-query-persist-client';
import { del, get, set } from 'idb-keyval';
import Joi from 'joi';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { DMProps, Gender, PromptUserActionProps, TFetchListArg, TUsers } from './typesUtil';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { confirmPopup } from 'primereact/confirmpopup';
import { getCode, getNames } from 'country-list';
import { createContext, useState } from 'react';
import { addDays, endOfMonth, endOfWeek, endOfYear, format, isSameDay, isToday, isWithinInterval, parse, startOfMonth, startOfWeek, startOfYear, subMonths, subWeeks } from 'date-fns';
import * as XLSX from 'xlsx';
const _ = require('lodash');

interface DropdownOption {
    name: string;
    id: string | number;
    value: string;
}
export const enum REDUCER_ACTION_TYPE {
    CHANGE_STATE_VALUES
}

export type StateReducerAction = {
    type: REDUCER_ACTION_TYPE;
    payload: {};
};
export function sliceObject(selectableProperties: Array<keyof any>, parentObject: any): any {
    const selectedValues: any = {};
    for (const prop of selectableProperties) {
        selectedValues[prop] = parentObject[prop];
    }
    return selectedValues;
}
export const remakeDropdownSelects = (data: any[], nameOption: string, idOption: string | number): DropdownOption[] => {
    return data.map((selectData) => ({
        name: selectData[nameOption],
        id: selectData[idOption],
        value: selectData[idOption]
    }));
};
export function createIDBPersister(idbValidKey: IDBValidKey = 'dental') {
    return {
        persistClient: async (client: PersistedClient) => {
            await set(idbValidKey, client);
        },
        restoreClient: async () => {
            return await get<PersistedClient>(idbValidKey);
        },
        removeClient: async () => {
            await del(idbValidKey);
        }
    } as Persister;
}
export const getTableRowId = (e: any, rowProp: string) => {
    //@ts-ignore
    if (e.target.closest('button')[rowProp]) {
        //@ts-ignore
        return e.target.closest('button')[rowProp]!;
    }
    return 'Found';
};
export const pageDataValidation = <T>(validationObject: Joi.ObjectSchema<T>, stateValues: Partial<T>, toastRef: React.MutableRefObject<any>): boolean => {
    const value = validationObject.validate(stateValues, { abortEarly: true });
    if (value.error) {
        displayMessage({ toastComponent: toastRef, header: 'Error', message: value.error.details[0].message, infoType: 'error', life: 3000 });
        console.log(value);
        return false;
    }
    return true;
};
export const changeState = <T>(stateValues: Partial<T>, dispatch: React.Dispatch<StateReducerAction>) => {
    dispatch({
        type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
        payload: { ...stateValues }
    });
};
export const inputChange = (e: React.ChangeEvent<HTMLInputElement>, dispatch: React.Dispatch<StateReducerAction>) => {
    changeState({ [e.target.id]: e.target.value }, dispatch);
};
export const selectControlChange = (e: DropdownChangeEvent, dispatch: React.Dispatch<StateReducerAction>) => {
    changeState({ [e.target.id]: e.value }, dispatch);
};

export async function fetchAction<T>(actionType: AxiosRequestConfig['method'], url: string, actionData: object): Promise<AxiosResponse<T>> {
    const config: AxiosRequestConfig = {
        method: actionType,
        url: url,
        headers: {
            'Content-Type': 'application/json'
        },
        data: actionData
    };

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error;
        } else {
            console.log('An error occurred:', error);
            throw new Error('An error occurred while making the request.');
        }
    }
}
export const fetchPageInit = async <T>(reqParams: TFetchListArg): Promise<T> => {
    const response: AxiosResponse<T> = await fetchAction('get', `${reqParams.urlLink}`, {});

    if (response.status !== 1) {
        throw new Error('Failed to fetch data');
    }

    return response.data;
};
export function customReducer<T>(state: T, action: StateReducerAction): T {
    switch (action.type) {
        case REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES:
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
}

export const displayMessage = ({ header, message, infoType, toastComponent, life, sticky = false }: DMProps) => {
    toastComponent.current?.show({
        severity: infoType,
        summary: header,
        detail: message,
        life,
        sticky: sticky
    });
};
export const promptUserAction = ({ yesAction = () => {}, noAction = () => {}, event, displayText, acceptLabel = 'Yes', rejectLabel = 'No' }: PromptUserActionProps) => {
    confirmPopup({
        target: event.currentTarget,
        message: displayText,
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-success',
        rejectClassName: 'p-button-danger',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        accept: yesAction,
        reject: noAction,
        acceptLabel: acceptLabel,
        rejectLabel: rejectLabel,
        style: { width: '400px' }
    });
};
export const displayGenders = (): DropdownOption[] => {
    const genders: Gender[] = [
        { id: 'Male', desc: 'Male' },
        { id: 'Female', desc: 'Female' }
    ];

    return remakeDropdownSelects(genders, 'desc', 'id');
};
export const displayReligions = (): DropdownOption[] => {
    const religions = [{ id: 'Christianity' }, { id: 'Islam' }, { id: 'Traditionalist' }];
    return remakeDropdownSelects(religions, 'id', 'id');
};
type TDropdownList = {
    id: string;
    description: string;
};
export const displayStatusType = (): DropdownOption[] => {
    const statusTypes: TDropdownList[] = [
        { description: 'Insured', id: 'Insured' },
        { description: 'Uninsured', id: 'Uninsured' }
    ];
    return remakeDropdownSelects(statusTypes, 'description', 'id');
};
export const displayTitles = (): DropdownOption[] => {
    const statusTypes: TDropdownList[] = [
        { description: 'Mr', id: 'Mr' },
        { description: 'Mrs', id: 'Mrs' },
        { description: 'Miss', id: 'Miss' },
        { description: 'Master', id: 'Master' },
        { description: 'Dr', id: 'Dr' },
        { description: 'Prof', id: 'Prof' }
    ];
    return remakeDropdownSelects(statusTypes, 'description', 'id');
};
export const displayCountries = (): DropdownOption[] => {
    const countriesListNames = getNames();
    const countriesList: TDropdownList[] = countriesListNames.map((countryName: string) => {
        return { description: countryName, id: getCode(countryName)! };
    });
    return remakeDropdownSelects(countriesList, 'description', 'id');
};
export const getBaseURL = () => {
    return `https://dentaldb.vitalfacilitiesgroup.com`;
    // return `http://localhost:5000`;
};
export async function addNewItemToCache<T>(existingArray: T[], newItem: T) {
    return [...existingArray, newItem];
}

export async function updateCacheItem<T>(updatedItem: T, itemBeforeUpdate: T, allItems: T[]) {
    const indexOfItem = _.findIndex(allItems, itemBeforeUpdate);

    const newItems: T[] = [...allItems];

    _.set(newItems, indexOfItem, updatedItem);

    return newItems;
}
export async function deleteCacheItem<T>(itemBeforeDelete: T, allItems: T[]) {
    const indexOfItem = _.findIndex(allItems, itemBeforeDelete);

    const clonedItems = [...allItems];

    _.pullAt(clonedItems, indexOfItem);

    return clonedItems;
}
export const onCheckBoxItemSelect = (setStateFunc: Function, stateProperty: string, stateValue: string[], checkBoxValue: string, checkBoxState: boolean) => {
    if (checkBoxState) {
        setStateFunc({ [stateProperty]: _.concat(stateValue, checkBoxValue) });
        return;
    }
    setStateFunc({ [stateProperty]: _.without(stateValue, checkBoxValue) });
};

export const medicalDiagnosisConditions = [
    { name: 'None' },
    { name: 'Dental Caries' },
    { name: 'Reversible Pulpitis' },
    { name: 'Irreversible Pulpitis' },
    { name: 'Cracked Tooth Syndrome' },
    { name: 'Fractured Tooth' },
    { name: 'Cervical Abrasion' },
    { name: 'Sensitive Teeth' },
    { name: 'Chronic Open Pulpitis' },
    { name: 'Pulp Polyp' },
    { name: 'Necrotic Tooth' },
    { name: 'Dilaceration' },
    { name: 'Globulomaxillary Cyst' },
    { name: 'Avulsion of Teeth' },
    { name: 'Impacted Wisdom Tooth' },
    { name: 'Retained Roots' },
    { name: 'Bells Palsy' },
    { name: 'Retained Deciduous Tooth' },
    { name: 'Supernumery Tooth' },
    { name: 'Mesiodens' },
    { name: 'Ectopic Eruption' },
    { name: 'Peg Shaped Lateral Incisor' },
    { name: 'Eruption Gingivitis' },
    { name: 'TMJ Pain Dysfunction Syndrome' },
    { name: 'Chronic Periodontitis' },
    { name: 'Acute Periodontitis' },
    { name: 'Juvenile Periodontitis' },
    { name: 'Sialolithiasis' },
    { name: 'Apthous Ulcer' },
    { name: 'Lichen Planus' },
    { name: 'Jaw Dislocation' },
    { name: 'Squamous Cell Carcinoma' },
    { name: 'Pyogenic Granuloma' },
    { name: 'Ameloblastoma' },
    { name: 'Osteosarcoma' },
    { name: 'Rhabdomyosarcoma' },
    { name: 'Osteomyelitis' },
    { name: 'Dentigyrous Cyst' },
    { name: 'Raducular Cyst' },
    { name: 'Odontogenic Keratocyst' },
    { name: 'Lymphedema' },
    { name: 'Lymphadenopathy' },
    { name: 'Hemangioma' },
    { name: 'Cystic Hygroma' },
    { name: 'Fibrous Dysplasia' },
    { name: 'Ossifying Fibroma' },
    { name: 'Dry Socket' },
    { name: 'Atypical Odontalgia' },
    { name: 'Burkitts Lymphoma' },
    { name: 'Pharyngitis' },
    { name: 'Tonsillitis' },
    { name: 'Tongue Tie' },
    { name: 'Enamel Hypoplasia' },
    { name: 'Fluorosis' },
    { name: 'Primary Herpetic Gingivostomatitis' },
    { name: 'Tongue Laceration' },
    { name: 'Parotiditis' },
    { name: 'Mumps' },
    { name: 'Retroviral Infection' },
    { name: 'Halitosis' },
    { name: 'Halitophobia' },
    { name: 'Maxillary Sinusitis' },
    { name: 'Class 1 Malocclusion' },
    { name: 'Class 2 Malocclusion' },
    { name: 'Class 3 Malocclusion' },
    { name: 'Open Bite' },
    { name: 'Cross Bite' },
    { name: 'Deep Bite' },
    { name: 'Oral Candidiasis' },
    { name: 'Amelogenesis Imperfecta' },
    { name: 'Dentinogenesis Imperfecta' },
    { name: 'Attrition of Teeth' },
    { name: 'Poor Oral Hygiene' },
    { name: 'Missing Teeth' },
    { name: 'Partial Edentulism' },
    { name: 'Fibrooseus Lesion' },
    { name: 'Pyogenic Granuloma' },
    { name: 'Epulis' },
    { name: 'Pleiomorphic Adenoma' },
    { name: 'Adenocarcinoma' },
    { name: 'ANUG' },
    { name: 'Leucoplakia' },
    { name: 'Periapical Abscess' },
    { name: 'Cervical Abrasion' },
    { name: 'Pericoronitis' },
    { name: 'Impacted Tooth' },
    { name: 'Soft Tissue Laceration' },
    { name: 'Avulsion' },
    { name: 'Intrusion' }
];
export const typesOfProsthesis = [
    { name: 'Upper Partial Denture' },
    { name: 'Lower Partial Denture' },
    { name: 'Upper Full Denture' },
    { name: 'Lower Full Denture' },
    { name: 'Full Full Denture' },
    { name: 'Crown' },
    { name: 'Bridge' },
    { name: 'Cast Post' },
    { name: 'Anterior Acryllic Bite Plate' },
    { name: 'Night Guard' },
    { name: 'Removable Orthodontic Retainers' },
    { name: 'Hawly Appliance' },
    { name: 'Bite Block' },
    { name: 'Twin Block' },
    { name: 'Wax Bite' },
    { name: 'Tongue Spike' }
];

export const typeOfMaterial = [{ name: 'Acryllic' }, { name: 'Viaplast' }, { name: 'Chrome Cobalt' }, { name: 'Zirconia' }, { name: 'Composite' }, { name: 'Porcelain' }, { name: 'Emax' }];

export const listOfDrugs = [{ name: 'Cap amoxicycillin 250mg' }, { name: 'Tab amoksiclav 1g' }];
export const drugsFormulationTypes = [{ name: 'Injection' }, { name: 'Capsules' }, { name: 'Syrup' }, { name: 'Tablet' }, { name: 'Mouthwash' }, { name: 'Paste' }];
export const partnerLabs = [{ name: 'German Dental Center' }, { name: 'Gideon Dental Lab' }, { name: 'TAK Dental Lab' }, { name: "Mimi's Dental Lab" }, { name: 'Advocate Dental Lab' }, { name: 'Shadee' }, { name: 'Quayson' }];
export const couriers = [{ name: 'STC' }, { name: 'GPRTU' }];
export const carStations = [{ name: 'Stanbic' }, { name: 'Ford - JOKO' }, { name: 'Ford - Shell' }];
export const medicalHistoryData = [
    'None',
    'Under Doctor Care',
    'Taking Med Now',
    'Ever ill in Hospital',
    'Pregnant Now',
    'Medicine Allergies',
    'Bleed Abnormally',
    'Hypertension',
    'Heart Disease',
    'Rheumatic Fever',
    'Asthma',
    'Epilepsy',
    'Hepatitis',
    'Kidney Disease',
    'Communicable Disease',
    'Gum Bleeding',
    'Others'
];

export const wizardItems = [
    { label: 'Medical History' },
    { label: 'Drug History' },
    { label: 'Known Allergies' },
    { label: 'Dental History' },
    { label: `Doctor's Notes` },
    { label: 'Medical Diagnosis' },
    { label: 'Dental Chart' },
    { label: 'Lab' },
    { label: 'Treatment Plan' },
    { label: 'Treatment' },
    { label: 'Prescription' },
    { label: 'Pictures' },
    { label: 'Billing' }
];
export const useUserLocalStorage = (): [TUsers | null, (userData: TUsers) => void, () => void] => {
    const [user, setUser] = useState<TUsers | null>(() => {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const updateUser = (userData: TUsers) => {
        localStorage.setItem('user', JSON.stringify(userData));
    };

    const clearUser = () => {
        setUser(null);
        localStorage.removeItem('user');
    };

    return [user, updateUser, clearUser];
};

export const encodeFilesToData = (file: File): Promise<{ fileData: string; fName: string }> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64Data = reader.result as string;
            const fileExtension = file.name.split('.').pop() || '';
            const fName = `file_${Date.now()}.${fileExtension}`;
            resolve({ fileData: base64Data, fName });
        };

        reader.onerror = () => {
            reject(reader.error);
        };

        reader.readAsDataURL(file);
    });
};
export const getSelectedDayDates = <T>(filterList: T[], criteriaField: keyof T, currentDate: Date = new Date()): T[] => {
    return filterList?.filter((listITem: T) => isSameDay(new Date(listITem[criteriaField] as Date), currentDate));
};
export const getTodayDates = <T>(filterList: T[], criteriaField: keyof T): T[] => {
    return filterList?.filter((listITem: T) => isToday(new Date(listITem[criteriaField] as Date)));
};
export const getThisWeekDates = <T>(filterList: T[], criteriaField: keyof T, currentDate: Date = new Date()) => {
    // const currentDate = new Date(); // or use a specific date if needed
    const startDate = startOfWeek(currentDate);
    const endDate = endOfWeek(currentDate);
    return filterList?.filter((listItem) => isWithinInterval(new Date(listItem[criteriaField] as Date), { start: startDate, end: endDate }));
};
export const getLastWeekAppointments = <T>(filterList: T[], criteriaField: keyof T): T[] => {
    const currentDate = new Date(); // or use a specific date if needed
    const startDateLastWeek = startOfWeek(subWeeks(currentDate, 1)); // subtract 1 week from the current date
    const endDateLastWeek = endOfWeek(subWeeks(currentDate, 1));
    return filterList?.filter((listItem) => isWithinInterval(new Date(listItem[criteriaField] as Date), { start: startDateLastWeek, end: endDateLastWeek }));
};
export const getLastMonthDates = <T>(filterList: T[], criteriaField: keyof T, currentDate: Date = new Date()): T[] => {
    // const currentDate = new Date(); // or use a specific date if needed
    const startDateLastMonth = startOfMonth(subMonths(currentDate, 1)); // subtract 1 month from the current date
    const endDateLastMonth = endOfMonth(subMonths(currentDate, 1));
    return filterList?.filter((listItem) => isWithinInterval(new Date(listItem[criteriaField] as Date), { start: startDateLastMonth, end: endDateLastMonth }));
};
export const getThisMonthDates = <T>(filterList: T[], criteriaField: keyof T, currentDate: Date = new Date()): T[] => {
    // const currentDate = new Date(); // or use a specific date if needed
    const startDateThisMonth = startOfMonth(currentDate);
    const endDateThisMonth = endOfMonth(currentDate);
    return filterList?.filter((listItem) =>
        isWithinInterval(new Date(listItem[criteriaField] as Date), {
            start: startDateThisMonth,
            end: endDateThisMonth
        })
    );
};
export const getThisYearDates = <T>(filterList: T[], criteriaField: keyof T, currentDate: Date): T[] => {
    const startOfThisYear = startOfYear(currentDate);
    const endOfThisYear = endOfYear(currentDate);
    return filterList?.filter((listItem) =>
        isWithinInterval(new Date(listItem[criteriaField] as Date), {
            start: startOfThisYear,
            end: endOfThisYear
        })
    );
};
export const configureExcelUpload = async (file: File, uploadFileHeaders: string[], uploadObjectNameFields: string[]): Promise<any[]> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
            const bufferArray = e.target?.result as ArrayBuffer;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const headers: any = XLSX.utils.sheet_to_json(ws, { header: 1 })[0]; // Get excel file headers
            const sheetHeaders = headers.map((header: string) => header.trim());
            if (!checkExcelDataIntegrity(sheetHeaders, uploadFileHeaders)) {
                reject(new Error('File Dishonesty detected'));
                return;
            }
            const parsedData = XLSX.utils.sheet_to_json(ws, { range: 1, header: uploadObjectNameFields }); // Starting file read from row 2;

            resolve(parsedData);
        };

        fileReader.readAsArrayBuffer(file);
    });
};

export const checkExcelDataIntegrity = (excelFileHeaders: [], localHeaders: string[]) => {
    const validData = excelFileHeaders.every((header: string) => localHeaders.includes(header));
    let arrangementIsValid = true;
    localHeaders.forEach((uploadHeader, index) => {
        // @ts-ignore
        const index2 = excelFileHeaders.indexOf(uploadHeader.trim());
        if (index !== index2) {
            arrangementIsValid = false;
            return false;
        }
    });
    return validData && arrangementIsValid;
};
export function getRandomDateOfBirth() {
    // Generate a random number of days between 0 and 365 * (2020 - 1950)
    const randomDays = Math.floor(Math.random() * (365 * (2020 - 1950)));

    // Add the random number of days to the start date (January 1, 1950)
    const startDate = parse('1950-01-01', 'yyyy-MM-dd', new Date());
    const randomDate = addDays(startDate, randomDays);

    // Format the date in the desired format (e.g., 'yyyy-MM-dd')
    return format(randomDate, 'yyyy-MM-dd');
}
export const formatDate = (dateToFormat: string | Date) => {
    return format(new Date(dateToFormat), 'yyyy-MM-dd');
};
export const straightLine = (hStartFrom = 0, hEndTo = 540, vStartFrom = 10, vEndTo = 10, lineWidth = 1) => {
    return {
        canvas: [
            {
                type: 'line',
                x1: hStartFrom,
                y1: vStartFrom,
                x2: hEndTo,
                y2: vEndTo,
                lineWidth: lineWidth,
                color: 'rgba(23,22,22,0.95)'
            }
        ]
    };
};
export const rectangularShape = () => {
    return {
        canvas: [
            {
                type: 'rect',
                x: 0, // X-coordinate of the rectangle
                y: 0, // Y-coordinate of the rectangle
                w: 550, // Width of the rectangle
                h: 200, // Height of the rectangle
                r: 5 // Corner radius (adjust as needed)
            }
        ]
    };
};
export const AppUserContext = createContext<TUsers>({ userId: '', fullName: '', gender: '', phoneNumber: '', emailAddress: '', username: '', password: '', role: '' });
