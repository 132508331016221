import { GeneralPageProps, IconTextInput, Loader, SimpleTableWithMenu, tableEditOption } from '../../utils/components';
import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { TTreatmentProcedure } from '../../utils/typesUtil';
import { displayMessage, getBaseURL, getTableRowId, pageDataValidation, promptUserAction } from '../../utils/utils';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { v4 as uuidv4 } from 'uuid';
import Diagnosis from '../../classes/Diagnosis';
import { addRecordToCache, deleteCacheRecord, updateCacheRecord, useTreatmentProceduresListFetch } from '../../utils/reactQueryUtils';
import { useQueryClient } from '@tanstack/react-query';
import Joi from 'joi';

type TProcedureState = TTreatmentProcedure & {
    isLoading: boolean;
    showProcedureDialog: boolean;
    editState: boolean;
};
const INITIAL_STATE: TProcedureState = {
    isLoading: false,
    procedureDescription: '',
    walkInFees: '0',
    insuranceFees: '0',
    companyFees: '0',
    procedureId: '',
    showProcedureDialog: false,
    editState: false
};
const validateTreatmentProcedure: Joi.ObjectSchema<TTreatmentProcedure> = Joi.object({
    procedureId: Joi.string(),
    procedureDescription: Joi.string().messages({ 'string.empty': 'Enter a valid description for treatment procedure' }),
    walkInFees: Joi.number().messages({ 'number.base': 'Enter a valid value for walk-in fees' }),
    insuranceFees: Joi.number().messages({ 'number.base': 'Enter a valid value for insurance fees' }),
    companyFees: Joi.number().messages({ 'number.base': 'Enter a valid value for company fees' })
});
const diagnosis = new Diagnosis();
const TreatmentProcedures = () => {
    const queryClient = useQueryClient();
    const [procedureState, setProcedureState] = useState<TProcedureState>(INITIAL_STATE);
    const toastRef = useRef<Toast>(null);
    const { data: treatmentProceduresList, isLoading, refetch, dataUpdatedAt } = useTreatmentProceduresListFetch({ urlLink: `${getBaseURL()}/diagnosis/get_all_treatment_procedures` });

    useEffect(() => {
        setStateValues({
            procedureId: uuidv4()
        });
    }, []);
    const setStateValues = (stateValues: Partial<TProcedureState>) => {
        setProcedureState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    const getStateValues = (): TTreatmentProcedure => {
        const { procedureId, procedureDescription, walkInFees, insuranceFees, companyFees }: TTreatmentProcedure = procedureState;
        return { procedureId, walkInFees, insuranceFees, companyFees, procedureDescription };
    };
    const treatmentProcedureDataValidation = (): boolean => {
        return pageDataValidation<TTreatmentProcedure>(validateTreatmentProcedure, getStateValues(), toastRef);
    };
    if (isLoading) return <Loader />;
    const procedureMenu = () => {
        return [
            {
                label: 'New Procedure',
                icon: 'pi pi-plus',
                command: () => setStateValues({ showProcedureDialog: true })
            }
        ];
    };
    const onHideDialog = () => {
        setStateValues({ showProcedureDialog: false, editState: false });
    };
    const saveProcedure = async () => {
        try {
            setStateValues({ isLoading: true });
            const saveTreatmentProcedureResponse = await diagnosis.createTreatmentProcedure(getStateValues());
            if (saveTreatmentProcedureResponse.data.status === 1) {
                await addRecordToCache(queryClient, ['treatmentProcedures'], saveTreatmentProcedureResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'New procedure was successfully saved with the supplied credentials',
                    infoType: 'success',
                    life: 5000
                });
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const updateProcedure = async () => {
        try {
            setStateValues({ isLoading: true });
            const updateTreatmentProcedureResponse = await diagnosis.updateTreatmentProcedure(getStateValues());
            if (updateTreatmentProcedureResponse.data.status === 1) {
                await updateCacheRecord(queryClient, ['treatmentProcedures'], [updateTreatmentProcedureResponse.data.operatedData, procedureState.procedureId, 'procedureId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Selected Treatment Procedure was successfully updated with the supplied credentials',
                    infoType: 'success',
                    life: 3000
                });
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const setupTreatmentProcedureEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const procedureId = getTableRowId(e, 'id');
        const selectedProcedure = treatmentProceduresList?.find((listItem: TTreatmentProcedure) => listItem.procedureId === procedureId);
        if (selectedProcedure !== undefined) {
            const { procedureId, procedureDescription, walkInFees, insuranceFees, companyFees } = selectedProcedure;
            setStateValues({ procedureId, procedureDescription, walkInFees, insuranceFees, companyFees, showProcedureDialog: true, editState: true });
        }
    };
    const deleteTreatmentProcedure = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const deletingProcedureId = getTableRowId(e, 'name');
            setStateValues({ isLoading: true });
            const deleteProcedureResponse = await diagnosis.deleteTreatmentProcedure(deletingProcedureId);
            if (deleteProcedureResponse.data.status === 1) {
                await deleteCacheRecord<TTreatmentProcedure>(queryClient, ['treatmentProcedures'], [deleteProcedureResponse.data.operatedData, deletingProcedureId, 'procedureId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Delete Success',
                    message: 'Selected Treatment Procedure was successfully removed from list!',
                    infoType: 'success',
                    life: 3000
                });
            }
        } catch (error: any) {
        } finally {
            setStateValues({ isLoading: false });
        }
    };
    const resetState = () => {
        setStateValues({ editState: false, showProcedureDialog: false, walkInFees: '0', insuranceFees: '0', companyFees: '0', procedureDescription: '', procedureId: uuidv4() });
    };
    const promptProcedureSave = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!treatmentProcedureDataValidation()) return;
        promptUserAction({ yesAction: saveProcedure, event, displayText: 'This will new treatment procedure to the list. Are you sure you want to proceed?' });
    };
    const promptProcedureUpdate = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!treatmentProcedureDataValidation()) return;
        promptUserAction({ yesAction: updateProcedure, event, displayText: 'This will update the selected treatment procedure. Are you sure you want to proceed?' });
    };
    const promptProcedureDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        promptUserAction({ yesAction: () => deleteTreatmentProcedure(event), event, displayText: 'This will delete the selected treatment procedure. Are you sure you want to proceed?' });
    };
    return (
        <>
            {procedureState.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={'procedureId'}
                    columnsDef={[
                        { field: 'procedureDescription', header: 'Procedure', style: '70rem' },
                        { field: 'walkInFees', header: 'Walk-In Fee', style: '20rem' },
                        { field: 'insuranceFees', header: 'Insurance Fee', style: '20rem' },
                        { field: 'companyFees', header: 'Company Fee', style: '20rem' },
                        { body: (rowData: TTreatmentProcedure) => tableEditOption(setupTreatmentProcedureEdit, promptProcedureDelete, rowData.procedureId), header: 'Edit', style: '10rem' }
                    ]}
                    tableData={treatmentProceduresList}
                    menuModel={procedureMenu()}
                    hasMenuList={true}
                    tableTitle="Procedure List"
                    // lastTableUpdate={salesUpdatedAt}
                    childTableDef={[]}
                    searchValues={['procedureDescription', '', '']}
                    searchFieldPlaceHolder="Search by Procedure Description"
                />
            </div>
            <Dialog onHide={onHideDialog} visible={procedureState.showProcedureDialog} className="lg:w-6 scalein animation-duration-500" position="top-right">
                <div className="card" title="Treatment Procedure">
                    <div className="p-fluid">
                        <div className="grid p-formgrid">
                            <IconTextInput
                                value={procedureState.procedureDescription}
                                onInputChange={(e) => setStateValues({ procedureDescription: e.target.value })}
                                placeholderValue="Procedure Description"
                                iconText="pi pi-pencil"
                                componentId="procedureDescription"
                                customClasses="lg:col-6 md:col-12 col-12"
                            />
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="procedureFee">Walk-In Fee</label>
                                <InputText type="number" value={procedureState.walkInFees} onChange={(e) => setStateValues({ walkInFees: e.target.value! })} id="procedureFee" />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="procedureFee">Insurance Fee</label>
                                <InputText type="number" value={procedureState.insuranceFees} onChange={(e) => setStateValues({ insuranceFees: e.target.value! })} id="procedureFee" />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="procedureFee">Company Fee</label>
                                <InputText type="number" value={procedureState.companyFees} onChange={(e) => setStateValues({ companyFees: e.target.value! })} id="procedureFee" />
                            </div>
                            <div className="field lg:col-3 md:col-12 col-12">
                                <Button onClick={!procedureState.editState ? promptProcedureSave : promptProcedureUpdate}>{!procedureState.editState ? `Save Procedure` : `Update Procedure`}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};
export default TreatmentProcedures;
