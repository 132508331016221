import { DropdownOption, TItem, TPurchase, TSelectedPurchaseListItem, TUsers } from '../utils/typesUtil';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppUserContext, displayMessage, formatDate, getBaseURL, getTableRowId, pageDataValidation, remakeDropdownSelects, updateCacheItem } from '../utils/utils';
import { DatePicker, DiscountComponent, FilterSelect, GeneralPageProps, Loader, NumberInputWithButtons, SimpleTableWithMenu, tableEditOption } from '../utils/components';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { CalendarChangeEvent } from 'primereact/calendar';
import { addRecordToCache, updateCacheRecord, useItemsListFetch, usePurchasesListFetch, useSuppliersListFetch } from '../utils/reactQueryUtils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { v4 as uuidv4 } from 'uuid';
import Items from '../classes/Items';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { useQueryClient } from '@tanstack/react-query';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { Badge } from 'primereact/badge';
import Joi from 'joi';
const _ = require('lodash');

type TPurchaseState = TPurchase & {
    purchaseListItems: DropdownOption[];
    selectedItem: string;
    suppliersList: DropdownOption[];
    purchaseTotalCost: number;
    showPurchaseDialog: boolean;
    isLoading: boolean;
    editState: boolean;
    quantityEditOptions: string[];
    selectedEditOption: string;
    editQtyValue: number;
    editingItemId: string;
    itemsInEdit: TSelectedPurchaseListItem[];
    discountOptions: string[];
    discountValue: string;
};

const INITIAL_STATE: TPurchaseState = {
    purchaseListItems: [],
    selectedItem: '',
    selectedPurchaseListItems: [],
    purchaseDate: new Date(),
    suppliersList: [],
    selectedSupplier: '',
    purchaseTotalCost: 0,
    amountPaid: 0,
    purchaseId: '',
    modifiedBy: '',
    showPurchaseDialog: false,
    isLoading: false,
    editState: false,
    quantityEditOptions: ['Add', 'Return'],
    selectedEditOption: '',
    editQtyValue: 0,
    editingItemId: '',
    itemsInEdit: [],
    purchaseDiscount: { discountOption: 'Cash Discount', discountAmount: '0', discountPercentage: 0 },
    discountOptions: ['Cash Discount', 'Percentage Discount'],
    discountValue: '0'
};

const validatePurchase: Joi.ObjectSchema<TPurchaseState> = Joi.object({
    purchaseId: Joi.string(),
    selectedSupplier: Joi.string().messages({ 'string.empty': 'Select a supplier for this purchase' }),
    selectedPurchaseListItems: Joi.array().min(1).messages({ 'array.min': 'Select at least one item to continue purchase activity' }),
    purchaseDate: Joi.date(),
    amountPaid: Joi.number(),
    modifiedBy: Joi.string(),
    purchaseDiscount: Joi.object()
});
const validateItemQuantityEdit = Joi.object({
    selectedEditOption: Joi.string().messages({ 'string.empty': 'Select edit action for quantity edit' }),
    editQtyValue: Joi.number().min(1).messages({ 'number.min': 'Ensure that editing quantity is greater than 0' })
});
const item = new Items();
const Purchases = () => {
    const queryClient = useQueryClient();
    const { data: suppliersList, isLoading, refetch, dataUpdatedAt } = useSuppliersListFetch({ urlLink: `${getBaseURL()}/users/get_suppliers_list` });
    const { data: purchasesList, isLoading: isPurchaseLoading, refetch: refetchPurchases, dataUpdatedAt: purchasesUpdatedAt } = usePurchasesListFetch({ urlLink: `${getBaseURL()}/items/get_all_purchases` });
    const { data: itemsList, isLoading: itemsLoading, refetch: refetchItems, dataUpdatedAt: itemsUpdatedAt } = useItemsListFetch({ urlLink: `${getBaseURL()}/items/get_all_items` });
    const quantityEditRef = useRef<OverlayPanel>(null);
    const user = useContext<TUsers>(AppUserContext);
    const toastRef = useRef<Toast>(null);
    const discountOPRef = useRef<OverlayPanel>(null);
    const [purchaseState, setPurchaseState] = useState<TPurchaseState>(INITIAL_STATE);

    useEffect(() => {
        if (itemsList !== undefined && suppliersList !== undefined) {
            setPurchaseState((prevState) => {
                return { ...prevState, purchaseListItems: remakeDropdownSelects(itemsList!, 'itemName', 'itemId'), suppliersList: remakeDropdownSelects(suppliersList!, 'supplierName', 'supplierId'), purchaseId: uuidv4(), modifiedBy: user.userId };
            });
        }
    }, [itemsList, suppliersList]);
    if (isLoading || isPurchaseLoading || isPurchaseLoading) return <Loader />;
    const setStateValue = (stateValues: Partial<TPurchaseState>) => {
        setPurchaseState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    const getPurchaseStateValues = () => {
        const { purchaseId, selectedPurchaseListItems, purchaseDate, selectedSupplier, amountPaid, modifiedBy, purchaseDiscount }: TPurchase = purchaseState;
        return { purchaseId, selectedPurchaseListItems, purchaseDate: formatDate(purchaseDate as Date), selectedSupplier, amountPaid, modifiedBy, purchaseDiscount };
    };
    const purchaseValidation = () => {
        return pageDataValidation<Partial<TPurchaseState>>(validatePurchase, getPurchaseStateValues(), toastRef);
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setStateValue({ [e.target.id]: e.value });
    };
    const onItemSelection = (e: DropdownChangeEvent) => {
        const selectedItem = itemsList!.find((item: TItem) => item.itemId === e.value);

        const { itemCost, itemName, itemPrice } = selectedItem!;
        setStateValue({
            selectedItem: e.value,
            selectedPurchaseListItems: [
                ...purchaseState.selectedPurchaseListItems,
                {
                    itemName: itemName,
                    itemId: e.value,
                    itemCost: parseFloat(itemCost),
                    itemQty: 1,
                    itemTotalCost: parseFloat(itemCost),
                    itemSell: parseFloat(itemPrice),
                    qtyAdded: 0,
                    qtyReturned: 0
                }
            ]
        });
    };
    const itemCostInput = (rowData: TSelectedPurchaseListItem) => {
        return <NumberInputWithButtons inputValue={rowData.itemCost} inputValueChange={onItemCostValueChange} numberInputId={rowData.itemId} />;
    };
    const itemQuantityInput = (rowData: TSelectedPurchaseListItem) => {
        return <NumberInputWithButtons allowDecimalValues={false} disableState={purchaseState.editState} inputValue={rowData.itemQty} inputValueChange={onItemQuantityValueChange} numberInputName={rowData.itemId} />;
    };
    const itemSellPriceInput = (rowData: TSelectedPurchaseListItem) => {
        return <NumberInputWithButtons inputValue={rowData.itemSell} inputValueChange={onItemSellPriceValueChange} numberInputName={rowData.itemId} />;
    };
    const onItemCostValueChange = (e: InputNumberValueChangeEvent) => {
        const editingItem = purchaseState.selectedPurchaseListItems.find((listItem: TSelectedPurchaseListItem) => listItem.itemId === e.target.id);
        if (editingItem !== undefined) {
            editingItem.itemCost = e.value!;
            editingItem.itemTotalCost = (editingItem.itemQty + editingItem.qtyAdded - editingItem.qtyReturned) * e.value!;
        }
        setStateValue({ selectedPurchaseListItems: [...purchaseState.selectedPurchaseListItems] });
    };
    const onItemQuantityValueChange = (e: InputNumberValueChangeEvent) => {
        const editingItem = purchaseState.selectedPurchaseListItems.find((listItem: TSelectedPurchaseListItem) => listItem.itemId === e.target.name);
        if (editingItem !== undefined) {
            editingItem.itemQty = e.value!;
            editingItem.itemTotalCost = editingItem.itemCost * e.value!;
        }
        setStateValue({ selectedPurchaseListItems: [...purchaseState.selectedPurchaseListItems] });
    };
    const onItemSellPriceValueChange = (e: InputNumberValueChangeEvent) => {
        const editingItem = purchaseState.selectedPurchaseListItems.find((listItem: TSelectedPurchaseListItem) => listItem.itemId === e.target.name);
        if (editingItem !== undefined) {
            editingItem.itemSell = e.value!;
        }
        setStateValue({ selectedPurchaseListItems: [...purchaseState.selectedPurchaseListItems] });
    };
    const removeListItem = (e: React.MouseEvent<HTMLButtonElement>) => {
        const itemId = getTableRowId(e, 'id');
        if (purchaseState.editState) {
            if (_.find(purchaseState.itemsInEdit, (listItem: TSelectedPurchaseListItem) => listItem.itemId === itemId)) {
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Remove Lock',
                    message: 'Item cannot be removed from list. You can choose to return all the quantities!',
                    infoType: 'warn',
                    life: 5000
                });
                return;
            }
        }

        const listItems = purchaseState.selectedPurchaseListItems.filter((item) => item.itemId !== itemId);
        setStateValue({ selectedPurchaseListItems: listItems });
    };
    const getItemsTotalCost = () => {
        if (purchaseState.editState) {
            return purchaseState.selectedPurchaseListItems.reduce((previousValue, currentValue) => {
                return previousValue + (currentValue.itemQty - currentValue.qtyReturned + currentValue.qtyAdded) * currentValue.itemCost;
            }, 0);
        }

        return purchaseState.selectedPurchaseListItems.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.itemTotalCost;
        }, 0);
    };
    const onPurchasePaidChange = (e: InputNumberValueChangeEvent) => {
        setStateValue({ amountPaid: e.value! });
    };
    const completePurchase = async () => {
        try {
            if (!purchaseValidation()) return;
            const stateValues: TPurchase = getPurchaseStateValues();
            setStateValue({ isLoading: true });
            const responseData = await item.newPurchase(stateValues);
            if (responseData.data.status === 1) {
                await addRecordToCache(queryClient, ['purchasesList'], responseData.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'Purchase was successfully saved!',
                    infoType: 'success',
                    life: 5000
                });
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const resetState = () => {
        setStateValue({ purchaseId: uuidv4(), selectedPurchaseListItems: [], selectedSupplier: '', purchaseDate: new Date(), amountPaid: 0, purchaseDiscount: { discountAmount: '0', discountPercentage: 0, discountOption: 'Cash Discount' } });
    };
    const setupPurchaseEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const purchaseId = getTableRowId(e, 'id');

        const selectedPurchase: TPurchase = _.find(purchasesList, (listItem: TPurchase) => listItem.purchaseId === purchaseId);

        if (selectedPurchase !== undefined) {
            const { purchaseId, purchaseDate, selectedPurchaseListItems, selectedSupplier, modifiedBy, amountPaid, discountAmount } = selectedPurchase;
            const parsedList = typeof selectedPurchaseListItems !== 'object' ? JSON.parse(selectedPurchaseListItems) : selectedPurchaseListItems;
            const listItems = parsedList.map((listItem: TSelectedPurchaseListItem) => {
                return { ...listItem, itemTotalCost: (listItem.itemQty - listItem.qtyReturned + listItem.qtyAdded) * listItem.itemCost };
            });
            setStateValue({
                purchaseId,
                purchaseDate: new Date(purchaseDate as Date),
                selectedSupplier,
                selectedPurchaseListItems: listItems,
                amountPaid,
                modifiedBy,
                showPurchaseDialog: true,
                editState: true,
                itemsInEdit: parsedList,
                purchaseDiscount: { ...purchaseState.purchaseDiscount, discountAmount: discountAmount! }
            });
        }
    };

    const deletePurchase = () => {};
    const onDialogHide = () => {
        setStateValue({ showPurchaseDialog: false, editState: false });
    };
    const purchasesMenu = () => {
        return [
            {
                label: 'New Purchase',
                icon: 'pi pi-plus',
                command: () => setStateValue({ showPurchaseDialog: true, selectedPurchaseListItems: [], selectedItem: '', selectedSupplier: '' })
            }
        ];
    };
    const updatePurchase = async () => {
        try {
            if (!purchaseValidation()) return;
            const stateValues: TPurchase = getPurchaseStateValues();
            setStateValue({ isLoading: true });
            const responseData = await item.updatePurchase(stateValues);
            if (responseData.data.status === 1) {
                await updateCacheRecord(queryClient, ['purchasesList'], [responseData.data.operatedData, purchaseState.purchaseId, 'purchaseId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Purchase was successfully updated!',
                    infoType: 'success',
                    life: 5000
                });
                resetState();
            }
        } catch (error: any) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: error.message,
                infoType: 'error',
                life: 5000
            });
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const editItemQty = (e: React.MouseEvent<HTMLButtonElement>) => {
        const itemId = getTableRowId(e, 'name');
        setStateValue({ editingItemId: itemId });
        quantityEditRef.current?.toggle(e);
    };
    const onQuantityEditOptionSelect = (e: SelectButtonChangeEvent) => {
        if (e.value === 'Return' && !_.find(purchaseState.itemsInEdit, (listItem: TSelectedPurchaseListItem) => listItem.itemId === purchaseState.editingItemId)) {
            displayMessage({
                toastComponent: toastRef,
                header: 'Error',
                message: 'You cannot issue return for this item. It is just being added!',
                infoType: 'warn',
                life: 5000
            });
            return;
        }
        setStateValue({ selectedEditOption: e.value });
    };
    const onEditQtyValueChange = (e: InputNumberValueChangeEvent) => {
        setStateValue({ editQtyValue: e.value! });
    };
    const completeQuantityEditAction = async () => {
        if (!pageDataValidation(validatePurchase, { selectedEditOption: purchaseState.selectedEditOption, editQtyValue: purchaseState.editQtyValue }, toastRef)) {
            return;
        }
        const affectingItem: TSelectedPurchaseListItem = _.find(purchaseState.selectedPurchaseListItems, (listItem: TSelectedPurchaseListItem) => listItem.itemId === purchaseState.editingItemId);
        const addedQty = purchaseState.selectedEditOption === 'Add' ? purchaseState.editQtyValue : 0;
        const returnedQty = purchaseState.selectedEditOption === 'Return' ? purchaseState.editQtyValue : 0;
        const itemTotalCost = (affectingItem.itemQty - (affectingItem.qtyReturned + returnedQty) + (affectingItem.qtyAdded + addedQty)) * affectingItem.itemCost;
        if (!_.find(purchaseState.itemsInEdit, (purchaseItem: TSelectedPurchaseListItem) => purchaseItem.itemId === purchaseState.editingItemId)) {
            const updatedItem: TSelectedPurchaseListItem = { ...affectingItem, itemTotalCost, itemQty: addedQty };
            const newItems = await updateCacheItem<TSelectedPurchaseListItem>(updatedItem, affectingItem, purchaseState.selectedPurchaseListItems);
            setStateValue({ selectedPurchaseListItems: newItems, editQtyValue: 0 });
            return;
        }
        const affectingItemEdited: TSelectedPurchaseListItem = { ...affectingItem, qtyReturned: returnedQty + affectingItem.qtyReturned, qtyAdded: addedQty + affectingItem.qtyAdded, itemTotalCost };
        const newSelectedItemsState = await updateCacheItem<TSelectedPurchaseListItem>(affectingItemEdited, affectingItem, purchaseState.selectedPurchaseListItems);
        setStateValue({ selectedPurchaseListItems: newSelectedItemsState, editQtyValue: 0 });
    };
    const onDiscountOptionChange = (e: SelectButtonChangeEvent) => {
        setStateValue({ purchaseDiscount: { ...purchaseState.purchaseDiscount, discountOption: e.value } });
    };
    const getPurchaseBalance = () => {
        return getItemsTotalCost() - (purchaseState.amountPaid + parseFloat(purchaseState.purchaseDiscount.discountAmount));
    };
    const onApplyDiscount = () => {
        const purchaseTotals = getItemsTotalCost();
        const discountPercent = purchaseState.purchaseDiscount.discountOption === 'Cash Discount' ? (parseFloat(purchaseState.discountValue) / purchaseTotals) * 100 : parseFloat(purchaseState?.discountValue!);
        const discountAmount = purchaseState.purchaseDiscount.discountOption === 'Cash Discount' ? purchaseState.discountValue : ((parseFloat(purchaseState?.discountValue!) / 100) * purchaseTotals).toFixed(2);
        setStateValue({ purchaseDiscount: { ...purchaseState.purchaseDiscount, discountPercentage: discountPercent, discountAmount } });
        displayMessage({
            toastComponent: toastRef,
            header: 'Discount Applied',
            message: 'Discount was successfully applied to purchase',
            infoType: 'success',
            life: 5000
        });
    };
    return (
        <>
            {purchaseState.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={'purchaseId'}
                    columnsDef={[
                        { body: (rowData: TPurchase) => <div>{formatDate(rowData.purchaseDate as Date)}</div>, header: 'Date' },
                        { field: 'supplierName', header: 'Supplier' },
                        { field: 'username', header: 'User' },
                        { body: (rowData: TPurchase) => tableEditOption(setupPurchaseEdit, deletePurchase, rowData.purchaseId), header: 'Edit' }
                    ]}
                    tableData={purchasesList}
                    menuModel={purchasesMenu()}
                    hasMenuList={true}
                    tableTitle="Purchases List"
                    lastTableUpdate={purchasesUpdatedAt}
                    childTableDef={[]}
                    searchValues={['supplierName', 'purchaseDate', 'modifiedBy']}
                    searchFieldPlaceHolder="Search by Supplier Name, Purchase Date or Purchase Person"
                />
            </div>
            <Dialog onHide={onDialogHide} visible={purchaseState.showPurchaseDialog} maximized>
                <div className="lg:min-h-full">
                    <div className="card">
                        <div className="p-fluid">
                            <div className="grid p-formgrid">
                                <div className="field lg:col-6 md:col-12 col-12">
                                    <label htmlFor="purchaseDate">Purchase Date</label>
                                    <DatePicker
                                        dateValue={purchaseState.purchaseDate}
                                        onDateChange={(e: CalendarChangeEvent) =>
                                            setPurchaseState((prevState) => {
                                                return { ...prevState, purchaseDate: e.value! };
                                            })
                                        }
                                        labelText="Purchase Date"
                                        controlId="purchaseDate"
                                        selectionType="single"
                                        displayButtonBar={true}
                                        displayTime={false}
                                    />
                                </div>
                                <div className="field lg:col-6 md:col-12 col-12">
                                    <FilterSelect selectableOptions={purchaseState?.suppliersList!} selectedOption={purchaseState?.selectedSupplier!} onSelectChange={onSelectChange} elementId="selectedSupplier" defaultValue="Suppliers" />
                                </div>
                                <div className="field lg:col-12 md:col-12 col-12">
                                    <FilterSelect selectableOptions={purchaseState?.purchaseListItems!} selectedOption={purchaseState?.selectedItem!} onSelectChange={onItemSelection} elementId="selectedItem" defaultValue="Items List" />
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="grid">
                                <DataTable value={purchaseState.selectedPurchaseListItems} emptyMessage="Select an item to continue">
                                    <Column
                                        header="Item"
                                        style={{ width: '30rem' }}
                                        body={(rowData: TSelectedPurchaseListItem) => (
                                            <div>
                                                {`${rowData.itemName}`}{' '}
                                                <span>
                                                    {rowData.qtyReturned > 0 || rowData.qtyAdded > 0 ? (
                                                        <>
                                                            <Badge value={rowData.qtyReturned} severity="danger" className="mr-2" />
                                                            <Badge value={rowData.qtyAdded} severity="success" />
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    ></Column>
                                    <Column field="itemCost" header="Cost Price" style={{ width: '20rem' }} body={itemCostInput}></Column>
                                    <Column header="Sell Price" style={{ width: '20rem' }} body={itemSellPriceInput}></Column>
                                    <Column header="Qty" style={{ width: '20rem' }} body={itemQuantityInput}></Column>
                                    <Column field="itemTotalCost" header="Total Cost" style={{ width: '20rem' }}></Column>
                                    <Column
                                        body={(rowData: TSelectedPurchaseListItem) => (
                                            <div>
                                                <Button icon="pi pi-trash" className="p-button-danger" id={rowData.itemId} onClick={removeListItem} />

                                                <Button icon="pi pi-pencil" className="ml-2 p-button-warning" name={rowData.itemId} onClick={editItemQty} />
                                            </div>
                                        )}
                                        header="Edit"
                                        style={{ width: '10rem' }}
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-3">Total Cost:</div>
                        <div className="col-6 align-items-start">{getItemsTotalCost()}</div>
                    </div>
                    <div className="grid mt-3">
                        <div className="col-3">Total Paid:</div>
                        <div className="col-6 align-items-start">
                            <NumberInputWithButtons inputValue={purchaseState.amountPaid} inputValueChange={onPurchasePaidChange} numberInputId="purchaseTotal" />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-3">Discount:</div>
                        <div className="col-6 align-items-start">{purchaseState.purchaseDiscount.discountAmount}</div>
                    </div>
                    <div className="grid">
                        <div className="col-3">Balance:</div>
                        <div className="col-6 align-items-start">{getPurchaseBalance()}</div>
                    </div>
                    <div className="grid mt-4">
                        <div className="col-2">
                            <Button onClick={(e) => discountOPRef.current?.toggle(e)}>Add Purchase Discount</Button>
                        </div>
                        <div className="col-2">
                            <Button onClick={!purchaseState.editState ? completePurchase : updatePurchase}>{!purchaseState.editState ? `Complete Purchase` : `Update Purchase`}</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <OverlayPanel ref={quantityEditRef} className="lg:w-3">
                <div className="card">
                    <div className="grid">
                        <div className="col-12">
                            <label>Select What to do</label>
                            <SelectButton className="mt-2" value={purchaseState.selectedEditOption} onChange={onQuantityEditOptionSelect} options={purchaseState.quantityEditOptions} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <label>Editing Quantity</label>
                            <InputNumber className="mt-2" value={purchaseState.editQtyValue} onValueChange={onEditQtyValueChange} />
                        </div>
                    </div>
                    <Button className="mt-2 w-7" onClick={completeQuantityEditAction}>{`${purchaseState.selectedEditOption} Quantity`}</Button>
                </div>
            </OverlayPanel>
            <DiscountComponent
                discountOPRef={discountOPRef}
                discountOptions={purchaseState.discountOptions}
                onDiscountOptionChange={onDiscountOptionChange}
                discountSelectedOption={purchaseState.purchaseDiscount.discountOption}
                discountAmount={purchaseState.discountValue}
                onDiscountAmountChange={(e) => setStateValue({ discountValue: e.target.value })}
                applyDiscountAction={onApplyDiscount}
            />
        </>
    );
};

export default Purchases;
