import { DatePicker, FilterSelect, GeneralPageProps, Loader, NumberInputWithButtons, SimpleTableWithMenu, tableEditOption } from '../utils/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { AppUserContext, displayMessage, formatDate, getBaseURL, getTableRowId, pageDataValidation, remakeDropdownSelects } from '../utils/utils';
import { Dialog } from 'primereact/dialog';
import { CalendarChangeEvent } from 'primereact/calendar';
import { DropdownOption, TExpenditure, TUsers } from '../utils/typesUtil';
import { addRecordToCache, deleteCacheRecord, updateCacheRecord, useExpensesListFetch, useExpenseTypesListFetch } from '../utils/reactQueryUtils';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import CExpenditure from '../classes/CExpenditure';
import Joi from 'joi';
import { v4 as uuidv4 } from 'uuid';
import { useQueryClient } from '@tanstack/react-query';
const _ = require('lodash');

type TExpensesState = TExpenditure & {
    showExpenseDialog: boolean;
    isLoading: boolean;
    expenditureTypesList: DropdownOption[];
    editState: boolean;
};
const INITIAL_STATE: TExpensesState = {
    expenditureDate: new Date(),
    expenditureId: '',
    expenditureTypeId: '',
    expensesAmount: 0,
    modifiedBy: '',
    showExpenseDialog: false,
    isLoading: false,
    expenditureNotes: '',
    expenditureTypesList: [],
    editState: false
};
const validateExpenses: Joi.ObjectSchema<TExpenditure> = Joi.object({
    expenditureId: Joi.string(),
    expenditureDate: Joi.date(),
    expenditureTypeId: Joi.number(),
    expensesAmount: Joi.number().min(1).messages({ 'number.min': 'Ensure that expenses amount exceeds 0' }),
    expenditureNotes: Joi.string().allow('', null),
    modifiedBy: Joi.string()
    // expenditureNotes
});
const expenditure = new CExpenditure();
const Expenditure = () => {
    const queryClient = useQueryClient();
    const toastRef = useRef<Toast>(null);
    const [expensesState, setExpensesState] = useState<TExpensesState>(INITIAL_STATE);
    const { data: expenditureTypesList, isLoading: isExpensesLoading, refetch: fetchExpensesTypesAgain } = useExpenseTypesListFetch({ urlLink: `${getBaseURL()}/bills/get_expenditure_types` });
    const { data: expenditureList, isLoading: expensesListLoading, refetch: fetchExpensesAgain, dataUpdatedAt: expenditureLastUpdate } = useExpensesListFetch({ urlLink: `${getBaseURL()}/bills/get_expenditures` });
    const user = useContext<TUsers>(AppUserContext);

    useEffect(() => {
        if (expenditureTypesList !== undefined) {
            setStateValue({ expenditureTypesList: remakeDropdownSelects(expenditureTypesList, 'expenditureTypeDescription', 'expenditureTypeId'), modifiedBy: user.userId, expenditureId: uuidv4() });
        }
    }, [expenditureTypesList]);
    const setStateValue = (stateValues: Partial<TExpensesState>) => {
        setExpensesState((prevState) => {
            return { ...prevState, ...stateValues };
        });
    };
    if (isExpensesLoading || expensesListLoading) return <Loader />;
    const getStateValues = (): TExpenditure => {
        const { expenditureId, expenditureDate, expensesAmount, expenditureTypeId, expenditureNotes, modifiedBy }: TExpenditure = expensesState;
        return {
            expenditureId,
            expenditureDate: formatDate(expenditureDate as Date),
            expensesAmount,
            expenditureTypeId,
            expenditureNotes,
            modifiedBy
        };
    };
    const expensesMenu = () => {
        return [
            {
                label: 'New Expenditure',
                icon: 'pi pi-plus',
                command: () => setStateValue({ showExpenseDialog: true })
            }
        ];
    };
    const onExpenseDialogHide = () => {
        setStateValue({ showExpenseDialog: false, editState: false });
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setStateValue({ [e.target.id]: e.value });
    };
    const onExpenditureInputChange = (e: InputNumberValueChangeEvent) => {
        setStateValue({ expensesAmount: e.value! });
    };
    const onCompleteExpenditure = async () => {
        try {
            if (!pageDataValidation(validateExpenses, getStateValues(), toastRef)) {
                return;
            }
            setStateValue({ isLoading: true });
            const newExpenditureResponse = await expenditure.createInstance(getStateValues());
            if (newExpenditureResponse.data.status === 1) {
                await addRecordToCache<TExpenditure>(queryClient, ['expenditureList'], newExpenditureResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'Expenditure was successfully saved!',
                    infoType: 'success',
                    life: 5000
                });
                //reset state after
            }
        } catch (error: any) {
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const setupExpenditureEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const expenditureId = getTableRowId(e, 'id');
        const selectedExpenditure = _.find(expenditureList, (expenseItem: TExpenditure) => expenseItem.expenditureId === expenditureId);

        if (selectedExpenditure !== undefined) {
            const { expenditureId, expenditureDate, expensesAmount, expenditureTypeId, expenditureNotes, modifiedBy }: TExpenditure = selectedExpenditure;
            setStateValue({ expenditureId, expenditureDate: new Date(expenditureDate as Date), expensesAmount, expenditureTypeId, expenditureNotes, modifiedBy, showExpenseDialog: true, editState: true });
        }
    };
    const updateExpenditure = async () => {
        try {
            if (!pageDataValidation(validateExpenses, getStateValues(), toastRef)) {
                return;
            }
            setStateValue({ isLoading: true });
            const updateExpenditureResponse = await expenditure.updateInstance(getStateValues());
            if (updateExpenditureResponse.data.status === 1) {
                await updateCacheRecord<TExpenditure>(queryClient, ['expenditureList'], [updateExpenditureResponse.data.operatedData, expensesState.expenditureId, 'expenditureId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Expenditure was successfully updated!',
                    infoType: 'success',
                    life: 5000
                });
                //reset state after
            }
        } catch (error: any) {
        } finally {
            setStateValue({ isLoading: false });
        }
    };
    const deleteExpenditure = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const expenditureId = getTableRowId(e, 'name');
        console.log(expenditureId);
        try {
            const deleteExpenditureResponse = await expenditure.deleteInstance(expenditureId);
            if (deleteExpenditureResponse.data.status === 1) {
                await deleteCacheRecord<TExpenditure>(queryClient, ['expenditureList'], [deleteExpenditureResponse.data.operatedData, expenditureId, 'expenditureId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Delete Success',
                    message: 'Expenditure was successfully deleted!',
                    infoType: 'success',
                    life: 5000
                });
            }
        } catch (error: any) {
        } finally {
        }
    };
    return (
        <>
            {expensesState.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={'expenditureId'}
                    columnsDef={[
                        { body: (rowData: TExpenditure) => <div>{formatDate(rowData.expenditureDate as Date)}</div>, header: 'Date', style: '10rem' },
                        { field: 'expenditureTypeDescription', header: 'Description', style: '30rem' },
                        { field: 'username', header: 'User', style: '15rem' },
                        { field: 'expensesAmount', header: 'Amount', style: '15rem' },
                        { body: (rowData: TExpenditure) => tableEditOption(setupExpenditureEdit, deleteExpenditure, rowData.expenditureId), header: 'Edit', style: '10rem' }
                    ]}
                    tableData={expenditureList}
                    menuModel={expensesMenu()}
                    hasMenuList={true}
                    tableTitle="Expenses List"
                    lastTableUpdate={expenditureLastUpdate}
                    childTableDef={[]}
                    searchValues={['expenditureTypeDescription', 'expenditureDate', 'modifiedBy']}
                    searchFieldPlaceHolder="Search by Expense Type, Expense Date or  Person"
                />
            </div>
            <Dialog header="Expenditure Recordings" onHide={onExpenseDialogHide} className="lg:w-7" visible={expensesState.showExpenseDialog} position="top-right">
                <div className="card">
                    <div className="p-fluid">
                        <div className="grid p-formgrid">
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="expenditureDate">Expenditure Date</label>
                                <DatePicker
                                    dateValue={expensesState.expenditureDate}
                                    onDateChange={(e: CalendarChangeEvent) => setStateValue({ expenditureDate: e.value! })}
                                    labelText="Expenditure Date"
                                    controlId="expenditureDate"
                                    selectionType="single"
                                    displayButtonBar={true}
                                    displayTime={false}
                                />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <FilterSelect selectableOptions={expensesState.expenditureTypesList} selectedOption={expensesState.expenditureTypeId} onSelectChange={onSelectChange} elementId="expenditureTypeId" defaultValue="Expenditure Type" />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="expensesAmount">Expenditure Amount</label>
                                <NumberInputWithButtons inputValue={expensesState.expensesAmount} inputValueChange={onExpenditureInputChange} numberInputId="expensesAmount" />
                            </div>
                            <div className="field lg:col-6 md:col-12 col-12">
                                <label htmlFor="expenditureNotes">Additional Notes</label>
                                <InputTextarea value={expensesState.expenditureNotes} onChange={(e) => setStateValue({ expenditureNotes: e.target.value })} rows={1} id="expenditureNotes" />
                            </div>
                            <div className="field lg:col-3 md:col-12 col-12">
                                <Button onClick={!expensesState.editState ? onCompleteExpenditure : updateExpenditure}>{!expensesState.editState ? `Save Expenditure` : `Update Expenditure`}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default Expenditure;
