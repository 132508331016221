import React, { useContext, useRef, useState } from 'react';
import { TDiagnosisContext, TLabRequest, TPrescription } from '../../utils/typesUtil';
import { DiagnosisCenterContext } from '../DiagnosisCenter';
import { Chip } from 'primereact/chip';
import { Divider } from 'primereact/divider';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { displayMessage, getBaseURL, getTableRowId } from '../../utils/utils';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Galleria } from 'primereact/galleria';
const responsiveOptions = [
    {
        breakpoint: '991px',
        numVisible: 4
    },
    {
        breakpoint: '767px',
        numVisible: 3
    },
    {
        breakpoint: '575px',
        numVisible: 1
    }
];
const PatientHistoryDisplay = () => {
    const viewLabRequestsOP = useRef<OverlayPanel>(null);
    const [labFiles, setLabFile] = useState<string[]>([]);
    const galleria = useRef<Galleria>(null);
    const { setStateValues, state, toastRef, onclickOp } = useContext<Partial<TDiagnosisContext>>(DiagnosisCenterContext!);
    const labRequestTemplate = (labRequest: TLabRequest) => {
        return (
            <div className="col-12">
                <div className="grid">
                    <div className="col-3">{labRequest.nameOfLab}</div>
                    <div className="col-3">{labRequest.selectedProsthesis}</div>
                    <div className="col-3">{labRequest.selectedMaterialType}</div>
                    <div className="col-3 underline cursor-pointer">
                        <Button id={labRequest.requestId} size="small" className="bg-transparent bg-green-800" tooltip="Click to view this lab results" onClick={openLabFiles}>
                            view Results
                        </Button>
                    </div>
                </div>
                <Divider />
            </div>
        );
    };
    const prescriptionsTemplate = (prescription: TPrescription) => {
        return (
            <div className="col-12">
                <div className="grid">
                    <div className="col-6">{prescription.itemName}</div>
                    <div className="col-2">{prescription.formulation}</div>
                    <div className="col-2">{prescription.dosage}</div>
                    <div className="col-2">{prescription.duration}</div>
                </div>
                <Divider />
            </div>
        );
    };
    const itemTemplate = (item: string) => {
        return <img src={`${getBaseURL()}/patientImages/${item}`} alt={item} style={{ width: '1000%', display: 'block' }} />;
    };

    const thumbnailTemplate = (item: string) => {
        return <img src={`${getBaseURL()}/patientImages/${item}`} alt={item} style={{ display: 'block', width: 80, height: 80 }} />;
    };
    const setupDiagnosisForEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (state?.diagnosisHistory !== undefined) {
            try {
                setStateValues!({
                    medicalHistory: state?.diagnosisHistory?.medicalHistory,
                    drugHistory: state?.diagnosisHistory?.drugHistory,
                    allergies: state?.diagnosisHistory?.allergies,
                    dentalHistory: state?.diagnosisHistory?.dentalHistory,
                    doctorNotes: state?.diagnosisHistory?.doctorNotes,
                    selectedMedicalConditions: state?.diagnosisHistory?.selectedMedicalConditions,
                    diagnosisLabRequests: state?.diagnosisHistory?.diagnosisLabRequests,
                    treatmentPlan: state?.diagnosisHistory?.treatmentPlan,
                    selectedTreatments: state?.diagnosisHistory?.selectedTreatments,
                    prescriptionForDiagnosis: state?.diagnosisHistory?.prescriptionForDiagnosis,
                    otherMedicalHistory: state.diagnosisHistory?.otherMedicalHistory,
                    otherAllergies: state.diagnosisHistory?.otherAllergies,
                    otherDentalHistory: state.diagnosisHistory?.otherDentalHistory,
                    otherTreatment: state.diagnosisHistory?.otherTreatment,
                    billedInstruments: state.diagnosisHistory?.billedInstruments,
                    selectedInstruments: state.diagnosisHistory?.selectedInstruments,
                    paymentInstruments: state.diagnosisHistory?.billedInstruments,
                    diagnosisDate: state.diagnosisHistory?.diagnosisDate,
                    diagnosisId: state.diagnosisHistory?.diagnosisId,
                    patientId: state.diagnosisHistory?.patientId,
                    diagnosisDescription: state.diagnosisHistory?.diagnosisDescription ? state.diagnosisHistory?.diagnosisDescription : '',
                    otherMedicalDiagnosis: state.diagnosisHistory?.otherMedicalDiagnosis ? state.diagnosisHistory?.otherMedicalDiagnosis : [],
                    patientImages: state.diagnosisHistory?.patientImages ? state.diagnosisHistory?.patientImages : [],
                    patientImagesPath: state.diagnosisHistory?.patientImagesPath ? state.diagnosisHistory?.patientImagesPath : [],
                    imagesToShow: state.diagnosisHistory?.patientImagesPath ? state.diagnosisHistory?.patientImagesPath : [],
                    billDiscount: state.diagnosisHistory?.billDiscount ? state.diagnosisHistory.billDiscount : { discountAmount: '0', discountPercentage: 0, discountOption: '' },
                    selectedProcedurePayer: state.diagnosisHistory.selectedProcedurePayer ? state.diagnosisHistory.selectedProcedurePayer : '',
                    editDiagnosisState: true
                });
                displayMessage({
                    toastComponent: toastRef!,
                    header: 'Edit Ready!',
                    message: 'Patient Diagnosis data has been set for possible edits!',
                    infoType: 'success',
                    life: 3000
                });
            } catch (error: any) {
                displayMessage({
                    toastComponent: toastRef!,
                    header: 'Add Error',
                    message: 'Drug with formulation type may already exist in already prescribed drugs!',
                    infoType: 'error',
                    life: 5000
                });
            }
            onclickOp!(e);
        }
    };
    const scannedFileNamesTemplate = (scannedFile: string) => {
        return (
            <>
                <div className="col-12">
                    <div className="flex flex-column xl:flex-row xl:align-items-start">
                        <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1">
                            <a href={`${getBaseURL()}/scannedFiles/${scannedFile}`} target="_blank">
                                {scannedFile}
                            </a>
                        </div>
                    </div>
                </div>
                <Divider />
            </>
        );
    };
    const openLabFiles = (e: React.MouseEvent<HTMLButtonElement>) => {
        const requestId = getTableRowId(e, 'id');
        const currentRequest = state?.diagnosisHistory?.diagnosisLabRequests.find((request: TLabRequest) => request.requestId === requestId);
        const labFiles = typeof currentRequest?.requestFiles === 'string' ? JSON.parse(currentRequest.requestFiles) : currentRequest?.requestFiles;
        setLabFile(labFiles);
        viewLabRequestsOP.current?.toggle(e);
    };
    // console.log(state?.imagesToShow);
    return (
        <>
            <div className="card p-fluid border-round m-3 fadeinleft animation-duration-500">
                <div className="card-header mb-4">
                    Folder Records
                    <Button tooltip="Edit this Diagnosis" className="cursor-pointer text-blue-200 mr-2 bg-transparent hover:shadow-8" rounded icon="pi pi-pencil" onClick={setupDiagnosisForEdit} />
                </div>
                <div className="grid">
                    <div className="col-12 lg:col-12 md:col-12">
                        <h6 className="underline">Medical History</h6>
                        <div className="card shadow-5">
                            {[...state?.diagnosisHistory?.medicalHistory!, ...state?.diagnosisHistory?.otherMedicalHistory!].map((history: string, index) => {
                                return <Chip className="text-cyan-200 ml-2 mb-2" label={history} key={`mh${index}`} />;
                            })}
                        </div>
                        <Divider />
                        <h6 className="underline">Drug History</h6>
                        <div className="card shadow-8">
                            <p dangerouslySetInnerHTML={{ __html: state?.diagnosisHistory?.drugHistory! }} />
                        </div>
                        <Divider />
                        <h6>Allergies</h6>
                        <div className="card shadow-5">
                            {[...state?.diagnosisHistory?.allergies!, ...state?.diagnosisHistory?.otherAllergies!].map((allergy: string, index) => {
                                return <Chip className="text-cyan-200 ml-2 mb-2" label={allergy} key={`al${index}`} />;
                            })}
                        </div>
                        <Divider />
                        <h6 className="underline">Dental History</h6>
                        <div className="card shadow-5">
                            {[...state?.diagnosisHistory?.dentalHistory!, ...state?.diagnosisHistory?.otherDentalHistory!].map((history: string, index) => {
                                return <Chip className="text-cyan-200 ml-2 mb-2" label={history} key={`dh${index}`} />;
                            })}
                        </div>
                        <Divider />
                        <h6 className="underline">Doctor's Notes</h6>
                        <div className="card shadow-5">
                            <div dangerouslySetInnerHTML={{ __html: state?.diagnosisHistory?.doctorNotes! }} />
                        </div>
                        <Divider />
                        <h6 className="underline">Diagnosis</h6>
                        <div className="card shadow-5">
                            {[...state?.diagnosisHistory?.selectedMedicalConditions!, ...state?.otherMedicalDiagnosis!].map((history: string, index) => {
                                return <Chip className="text-cyan-200 ml-2 mb-2" label={history} key={`sm${index}`} />;
                            })}
                        </div>
                        <Divider />
                        <h6 className="underline">Lab Requests</h6>
                        <div className="card shadow-5">
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-3 font-bold">Lab</div>
                                    <div className="col-3 font-bold">Prosthesis</div>
                                    <div className="col-3 font-bold">Material</div>
                                    <div className="col-3 font-bold">More...</div>
                                </div>
                            </div>
                            <Divider />
                            <DataView value={state?.diagnosisHistory?.diagnosisLabRequests} itemTemplate={labRequestTemplate}></DataView>
                        </div>
                        <Divider />
                        <h6>Treatment Plan</h6>
                        <div className="card shadow-5">
                            <div dangerouslySetInnerHTML={{ __html: state?.diagnosisHistory?.treatmentPlan! }} />
                        </div>
                        <Divider />
                        <h6 className="underline">Treatment</h6>
                        <div className="card shadow-5">
                            {[...state?.diagnosisHistory?.selectedTreatments!, ...state?.diagnosisHistory?.otherTreatment!].map((selectedTreatment: string, index) => {
                                return <Chip className="text-cyan-200 ml-2 mb-2" label={selectedTreatment} key={`st${index}`} />;
                            })}
                        </div>
                        <Divider />
                        <h6 className="underline">Prescriptions</h6>
                        <div className="card shadow-5">
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-6 font-bold">Drug</div>
                                    <div className="col-2 font-bold">Formulation</div>
                                    <div className="col-2 font-bold">Dosage</div>
                                    <div className="col-2 font-bold">Duration</div>
                                </div>
                            </div>
                            <DataView value={state?.diagnosisHistory?.prescriptionForDiagnosis} itemTemplate={prescriptionsTemplate}></DataView>
                        </div>
                        <Divider />
                        <h6 className="underline">Clinical Image</h6>
                        <div className="card">
                            <div className="flex align-items-center justify-content-center">
                                <Galleria
                                    ref={galleria}
                                    value={state?.diagnosisHistory?.patientImagesPath}
                                    // responsiveOptions={responsiveOptions}
                                    numVisible={9}
                                    style={{ maxWidth: '50%' }}
                                    circular
                                    fullScreen
                                    showItemNavigators
                                    item={itemTemplate}
                                    thumbnail={thumbnailTemplate}
                                />
                                <div className="col-4">
                                    <Button label="View Patient Clinical Images" icon="pi pi-external-link" onClick={() => galleria.current?.show()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex-column mr-2">
                        <Button size="small" tooltip="Show all patient history in a book like a hospital folder">
                            View All In A Book Like Format...
                        </Button>
                    </div>
                </div>
            </div>
            <OverlayPanel ref={viewLabRequestsOP}>
                <DataView value={labFiles} emptyMessage="Nothing Found" itemTemplate={scannedFileNamesTemplate} />
            </OverlayPanel>
        </>
    );
};
export default PatientHistoryDisplay;
