import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { DropdownOption, TBillDiscount, TGeneralBag, TPayment, TPaymentInstrument } from '../utils/typesUtil';
import { useQueryClient } from '@tanstack/react-query';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Card } from 'primereact/card';
import { FilterSelect, GeneralPageProps, IconTextInput, Loader } from '../utils/components';
import { InputText } from 'primereact/inputtext';
import Consultation from '../classes/Consultation';
import { v4 as uuidv4 } from 'uuid';
import { displayMessage, pageDataValidation, remakeDropdownSelects, useUserLocalStorage } from '../utils/utils';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { format } from 'date-fns';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';
import { useGetHangedDiagnosisData } from '../utils/reactQueryUtils';

type TInvoice = {
    item: string;
    price: string;
    billingItems: TPaymentInstrument[];
    payingEntity: string;
    payingEntities: [];
    payer: string;
    paymentNotes: string;
    amountTendered: string;
    paymentAmount: string;
    paymentChange: string;
    isLoading: boolean;
    paymentTypes: DropdownOption[];
    selectedPaymentType: string;
    patientCompanies: DropdownOption[];
    billDiscount?: TBillDiscount;
    patientDebtAmount: number;
};

const consultation = new Consultation();

const validateBillPayment: Joi.ObjectSchema<TPayment> = Joi.object({
    paymentId: Joi.string(),
    payingEntity: Joi.string().messages({ 'string.empty': 'Select a paying entity' }),
    payer: Joi.string().messages({ 'string.empty': 'Enter a payer name' }),
    paymentNotes: Joi.string().allow('', null),
    billingInstruments: Joi.array().min(1).messages({ 'array.min': 'Add at least one item to the bill list' }),
    amountPaid: Joi.string().messages({ 'string.empty': 'Enter a valid amount for amount paying. Zero is accepted!' }),
    amountTendered: Joi.string().optional(),
    diagnosisId: Joi.string().required(),
    patientId: Joi.string().required(),
    selectedPaymentType: Joi.string().messages({ 'string.empty': 'Please select a payment type to continue!' }),
    discountAmount: Joi.number()
});

const Invoice = (props: any) => {
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const data: TGeneralBag | undefined = queryClient.getQueryData(['generalBag']);

    const opRef = useRef<OverlayPanel>(null);

    const paymentOPRef = useRef<OverlayPanel>(null);

    const [printState, setPrintState] = useState<TInvoice>({
        item: '',
        price: '',
        billingItems: [],
        payingEntity: '',
        payingEntities: [],
        payer: '',
        paymentNotes: '',
        paymentAmount: '0',
        paymentChange: '00.00',
        isLoading: false,
        amountTendered: '0',
        paymentTypes: remakeDropdownSelects([{ id: 'Cash' }, { id: 'Momo' }, { id: 'Cheque' }], 'id', 'id'),
        selectedPaymentType: '',
        patientCompanies: [],
        billDiscount: undefined,
        patientDebtAmount: 0
    });

    const toastRef = useRef<Toast>(null);

    const [user] = useUserLocalStorage();

    useEffect(() => {
        if (!user) navigate('/login');

        setPrintState((prevState: any) => {
            return {
                ...prevState,
                payingEntities: remakeDropdownSelects([{ entity: 'Insurance' }, { entity: 'Company' }, { entity: 'Walk-In' }], 'entity', 'entity'),
                billingItems: typeof data?.diagnosingPatient.billedInstruments === 'string' ? JSON.parse(data?.diagnosingPatient.billedInstruments) : [],
                billDiscount: typeof data?.diagnosingPatient.billDiscount === 'string' ? JSON.parse(`[${data?.diagnosingPatient.billDiscount}]`)[0] : { discountAmount: '0', discountPercentage: 0, discountOption: '' },
                patientCompanies: remakeDropdownSelects([{ company: 'Gold Fields Tarkwa' }, { company: 'Gold Fields Damang' }, { company: 'Anglogold' }, { company: 'Golden Star' }], 'company', 'company'),
                patientDebtAmount: data?.patientDebtAmount
            };
        });
    }, []);

    const savePaymentInformation = async () => {
        try {
            const paymentData: TPayment = {
                payer: printState.payer,
                payingEntity: printState.payingEntity,
                paymentNotes: printState.paymentNotes,
                paymentId: uuidv4(),
                billingInstruments: printState.billingItems!,
                amountPaid: printState.paymentAmount,
                diagnosisId: data?.diagnosingPatient.diagnosisId!,
                patientId: data?.diagnosingPatient?.patientId!,
                amountTendered: printState.amountTendered,
                selectedPaymentType: printState.selectedPaymentType,
                discountAmount: parseFloat(printState.billDiscount?.discountAmount!)
            };

            if (!pageDataValidation<TPayment>(validateBillPayment, paymentData, toastRef)) return;
            setPrintState((prevState) => {
                return { ...prevState, isLoading: true };
            });
            const savePaymentResponse = await consultation.acceptBillPayment(paymentData);

            if (savePaymentResponse.data.status === 1) {
                //check if local state manages local payment history;
                window.print();
                queryClient.setQueryData(['generalBag'], { diagnosingPatient: {} });
                navigate('/consultation');
            }
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        } finally {
            setPrintState((prevState) => {
                return { ...prevState, isLoading: false };
            });
        }
    };
    const getTotalBillCost = () => {
        const debtAmount = data?.patientDebtAmount !== undefined ? data.patientDebtAmount : 0;
        return printState.billingItems!.reduce((previousValue: number, currentValue: TPaymentInstrument) => {
            return previousValue + parseFloat(currentValue.price);
        }, debtAmount);
    };
    const onItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrintState((prevState) => {
            return { ...prevState, [e.target.id]: e.target.value };
        });
    };
    const onAddInvoiceItem = () => {
        if (printState.price === '') return;
        setPrintState((prevState: TInvoice) => {
            return { ...prevState, billingItems: [...prevState.billingItems!, { item: printState.item, price: parseFloat(printState.price!).toFixed(2) }], item: '', price: '' };
        });
    };
    const onSelectChange = (e: DropdownChangeEvent) => {
        setPrintState((prevState) => {
            return { ...prevState, [e.target.id]: e.target.value };
        });
    };
    const controlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrintState((prevState) => {
            return { ...prevState, [e.target.id]: e.target.value };
        });
    };
    const onPaymentAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const billCost = getTotalBillCost() - parseFloat(printState.billDiscount?.discountAmount!);
        const amountTendered = parseFloat(e.target.value);
        const payingAmount = (amountTendered > billCost ? billCost : amountTendered).toFixed(2);
        const paymentChange = amountTendered > billCost ? amountTendered - billCost : 0;
        setPrintState((prevState) => {
            return { ...prevState, amountTendered: e.target.value, paymentAmount: payingAmount, paymentChange: paymentChange.toFixed(2) };
        });
    };
    const textAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPrintState((prevState) => {
            return { ...prevState, [e.target.id]: e.target.value };
        });
    };
    const onCompletePayment = async (e: React.MouseEvent<HTMLButtonElement>) => {
        await savePaymentInformation();
    };
    const onPayingEntityChange = (e: DropdownChangeEvent) => {
        setPrintState((prevState) => {
            return { ...prevState, [e.target.id]: e.target.value, payer: '' };
        });
    };
    return (
        <>
            {printState.isLoading && <Loader />}
            <GeneralPageProps toastRef={toastRef} />
            <div className="layout-invoice-page">
                {/*TODO:UPDATE BILLS INSTRUMENT IN DIAGNOSIS IF ALLOW USER TO ADD TO BILLS LIST*/}
                <Button type="button" disabled label="Add Invoice Item" icon="pi pi-plus" onClick={(e) => opRef.current?.toggle(e)} className="p-button-outlined invoice-button mb-2 ml-2"></Button>
                <Button type="button" label="Add Payment Information" icon="pi pi-print" onClick={(e) => paymentOPRef.current?.toggle(e)} className="p-button-outlined invoice-button mb-2 ml-2"></Button>

                <div className="grid">
                    <div className="col">
                        <div className="card">
                            <div id="invoice-content">
                                <div className="invoice">
                                    <div className="invoice-header">
                                        <div className="invoice-company flex align-items-center">
                                            <img className="invoice-logo" src={`assets/layout/images/logo-${props.colorMode === 'light' ? 'dark' : 'light'}.png`} alt="atlantis-layout" />
                                            <img className="invoice-appname" src={`assets/layout/images/appname-${props.colorMode === 'light' ? 'dark' : 'light'}.png`} alt="atlantis-layout" />
                                        </div>
                                        <div className="ml-2">
                                            <div className="customer-id">Patient ID: C1613</div>
                                            <div className="invoice-address">{data?.diagnosingPatient.location}</div>
                                        </div>
                                    </div>

                                    <div className="invoice-to">
                                        <div className="invoice-date">{format(new Date(), 'yyyy-MM-dd')}</div>
                                        <div className="invoice-id">
                                            Invoice <span>#00002</span>
                                        </div>
                                        <div className="invoice-to-name">Patient Name</div>
                                        <div className="invoice-to-info">
                                            <div>{data?.diagnosingPatient.fullName}</div>
                                            <div>
                                                {data?.diagnosingPatient.location},{data?.diagnosingPatient.phoneNumber}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="invoice-items">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Description</th>
                                                    <th>Unit Price</th>
                                                    <th>Line Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Arrears:</td>
                                                    <td>GHC {printState.patientDebtAmount.toFixed(2)}</td>
                                                    <td>GHC {printState.patientDebtAmount.toFixed(2)}</td>
                                                </tr>
                                                {printState.billingItems.map((bill: any, index) => {
                                                    return (
                                                        <tr key={`${index}B`}>
                                                            <td>{bill.item}</td>
                                                            <td>GHC {bill.price}</td>
                                                            <td>GHC {bill.price}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="invoice-summary">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Sub total:</td>
                                                    <td>GHC {getTotalBillCost().toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Vat:</td>
                                                    <td>GHC 00.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Discount:</td>
                                                    <td>{printState.billDiscount?.discountAmount}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total:</td>
                                                    <td>GHC {getTotalBillCost() - parseFloat(printState.billDiscount?.discountAmount!)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OverlayPanel className="lg:w-5" ref={opRef}>
                <Card title="New Invoice Item">
                    <div className="grid">
                        <IconTextInput value={printState.item} onInputChange={onItemChange} placeholderValue="New Item" iconText="pi pi-briefcase" componentId="item" customClasses="lg:col-6 md:col-12 col-12" />
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="price">Item Cost</label>
                            <br />
                            <InputText type="number" value={printState.price} onChange={onItemChange} id="price" />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <Button onClick={onAddInvoiceItem}>Add Item</Button>
                        </div>
                    </div>
                </Card>
            </OverlayPanel>
            <OverlayPanel ref={paymentOPRef} className="lg:col-12">
                <Card title="Accept Payment" className="shadow-8">
                    <div className="grid">
                        <div className="col-6">
                            <div className="grid">
                                <div className="col-12 lg:col-12 md:col-12">
                                    <FilterSelect selectableOptions={printState.payingEntities} selectedOption={printState.payingEntity} onSelectChange={onPayingEntityChange} elementId="payingEntity" defaultValue="Paying Entity" />
                                </div>
                                <div className="col-12 lg:col-12 md:col-12">
                                    {printState.payingEntity !== 'Company' ? (
                                        <>
                                            <label>Payer</label>
                                            <InputText className="mt-2" type="text" id="payer" value={printState.payer} onChange={controlChange} autoComplete="off" style={{ width: '100%' }} />
                                        </>
                                    ) : (
                                        <div className="field lg:col-12 md:col-12 col-12">
                                            <FilterSelect selectableOptions={printState.patientCompanies!} selectedOption={printState?.payer!} onSelectChange={onSelectChange} elementId="payer" defaultValue="Patient Company" />
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 lg:col-12 md:col-12">
                                    <label>Payment Notes:</label>
                                    <InputTextarea className="mt-2" id="paymentNotes" value={printState.paymentNotes} onChange={textAreaChange} rows={1} cols={30} style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="grid">
                                <div className="field lg:col-6 md:col-12 col-12">Total Bill Cost:</div>
                                <div className="field lg:col-6 md:col-12 col-12">{getTotalBillCost() - parseFloat(printState.billDiscount?.discountAmount!)}</div>
                            </div>
                            <div className="grid">
                                <div className="field lg:col-6 md:col-12 col-12">Payment Type:</div>
                                <div className="field lg:col-6 md:col-12 col-12">
                                    <FilterSelect selectableOptions={printState.paymentTypes} selectedOption={printState.selectedPaymentType} onSelectChange={onSelectChange} elementId="selectedPaymentType" defaultValue="" />
                                </div>
                            </div>
                            <div className="grid">
                                <div className="field lg:col-6 md:col-12 col-12">Amount Tendered:</div>
                                <div className="field lg:col-6 md:col-12 col-12">
                                    <InputText type="number" value={printState.amountTendered} onChange={onPaymentAmountChange} id="paymentAmount" />
                                </div>
                            </div>
                            <div className="grid">
                                <div className="field lg:col-6 md:col-12 col-12">Amount Paying:</div>
                                <div className="field lg:col-6 md:col-12 col-12">{printState.paymentAmount}</div>
                            </div>
                            <div className="grid">
                                <div className="field lg:col-6 md:col-12 col-12 align-content-end">Change:</div>
                                <div className="field lg:col-6 md:col-12 col-12">{printState.paymentChange}</div>
                            </div>
                            <div className="grid mt-4">
                                <Button onClick={onCompletePayment}>Save & Print</Button>
                            </div>
                        </div>
                    </div>
                </Card>
            </OverlayPanel>
        </>
    );
};

export default Invoice;
