import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import reportWebVitals from './reportWebVitals';
import { QueryClient } from '@tanstack/react-query';
import { createIDBPersister } from './utils/utils';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: Infinity,
            // staleTime: 86400000, //24 hours
            staleTime: 30000, //30 seconds
            networkMode: 'offlineFirst'
        }
    }
});
const idbPersistor = createIDBPersister();
const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <HashRouter>
            <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: idbPersistor }}>
                <AppWrapper></AppWrapper>
                <ReactQueryDevtools initialIsOpen={false} />
            </PersistQueryClientProvider>
        </HashRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
