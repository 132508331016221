import {
    IEmergencyContact,
    IPatient,
    IPatientAndEmergencyContact,
    TAdjustment,
    TAppointment,
    TBrand,
    TConsultationWaitList,
    TDebtor,
    TDiagnosisDataList,
    TExpenditure,
    TExpenditureType,
    TFetchListArg,
    TGeneralBag,
    TItem,
    TLoginData,
    TPatientAndEmergency,
    TPatientsLabRequests,
    TPaymentDetails,
    TPaymentListItem,
    TPaymentsList,
    TPurchase,
    TSuppliers,
    TTreatmentProcedure,
    TUsers
} from './typesUtil';
import { addNewItemToCache, deleteCacheItem, fetchAction, fetchPageInit, formatDate, getBaseURL, updateCacheItem } from './utils';
import { QueryClient, QueryKey, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export const doUserLogin = async (userDetails: TLoginData) => {
    const response: AxiosResponse<TUsers, TUsers> = await fetchAction('post', `${getBaseURL()}/users/user_log_in`, { userDetails });

    if (!response.status) {
        throw new Error('Failed to fetch registration details');
    }

    return response.data;
};

export const usePatientsListFetch = (reqParams: TFetchListArg) =>
    useQuery(['patientsList'], async () => await fetchPageInit<IPatientAndEmergencyContact[]>(reqParams), {
        select: (data) => {
            const patientsList: TPatientAndEmergency[] = [];
            for (const patient of data) {
                const currentPatient: IPatient[] = typeof patient.patientData === 'string' ? JSON.parse(`[${patient.patientData}]`) : patient.patientData;
                const emergencyContact: IEmergencyContact[] = typeof patient.emergencyContact === 'string' ? JSON.parse(`[${patient.emergencyContact}]`) : patient.emergencyContact;
                const fullName = `${currentPatient[0].title} ${currentPatient[0].firstName} ${currentPatient[0].lastName} ${currentPatient[0].otherNames}`;
                const emergencyFullName = `${emergencyContact[0].emergencyContactTitle} ${emergencyContact[0].emergencyFirstName} ${emergencyContact[0].emergencyLastName} ${emergencyContact[0].emergencyOtherName}`;
                patientsList.push({ ...currentPatient[0], ...emergencyContact[0], fullName: fullName, emergencyFullName });
            }
            return patientsList;
        }
    });
export const useAppointmentsListFetch = (reqParams: TFetchListArg) => useQuery(['appointmentsList'], async () => await fetchPageInit<TAppointment[]>(reqParams), {});
export const useAConsultationWaitListFetch = (reqParams: TFetchListArg) =>
    useQuery(['consultationList'], async () => await fetchPageInit<TConsultationWaitList[]>(reqParams), {
        select: (data) => {
            return data;
        },
        refetchInterval: 5000,
        refetchOnMount: 'always'
    });
export const usePatientsDiagnosisListFetch = (reqParams: TFetchListArg) =>
    useQuery(['diagnosisList'], async () => await fetchPageInit<TDiagnosisDataList[]>(reqParams), {
        select: (data) => {
            return data.map((diagnosisData: TDiagnosisDataList) => {
                const diagnosisDetails = typeof diagnosisData.diagnosisDetails === 'string' ? JSON.parse(`[${diagnosisData.diagnosisDetails}]`) : diagnosisData.diagnosisDetails;
                return { ...diagnosisData, diagnosisDetails };
            });
        },
        enabled: reqParams.enableFetch,
        staleTime: 86400000, //24 hours
        refetchOnMount: 'always'
    });
export const useBillsListFetch = (reqParams: TFetchListArg) =>
    useQuery(['billsList'], async () => await fetchPageInit<TConsultationWaitList[]>(reqParams), {
        select: (data) => {
            return data;
        },
        refetchInterval: 5000,
        refetchOnMount: 'always'
    });
export const useUsersListFetch = (reqParams: TFetchListArg) =>
    useQuery(['usersList'], async () => await fetchPageInit<TUsers[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useDebtorsListFetch = (reqParams: TFetchListArg) =>
    useQuery(['debtorsList'], async () => await fetchPageInit<TDebtor[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useAccumulatedDebtorsListFetch = (reqParams: TFetchListArg) =>
    useQuery(['accumulatedDebtorsList'], async () => await fetchPageInit<TDebtor[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useLabRequestsListFetch = (reqParams: TFetchListArg) =>
    useQuery(['labRequestsList'], async () => await fetchPageInit<TPatientsLabRequests[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useGetDashBoardReports = (reqParams: TFetchListArg) =>
    useQuery(['dashBoardReports'], async () => await fetchPageInit<string>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useGetPaymentsList = (reqParams: TFetchListArg) =>
    useQuery(['paymentsList'], async () => await fetchPageInit<TPaymentsList[]>(reqParams), {
        select: (data) => {
            let genPaymentsMade: TPaymentListItem[] = [];
            for (const payment of data) {
                const paymentsMade: TPaymentDetails[] = typeof payment.paymentDetails === 'string' ? JSON.parse(payment.paymentDetails) : [];

                for (const paymentDetail of paymentsMade) {
                    genPaymentsMade = [
                        ...genPaymentsMade,
                        {
                            patientId: payment.patientId,
                            patientCode: payment.patientCode,
                            fullName: payment.fullName,
                            paymentDate: formatDate(paymentDetail.paymentDate),
                            payingEntity: payment.payingEntity,
                            amountPaid: paymentDetail.amountPaid,
                            paymentId: paymentDetail.paymentId,
                            paymentType: paymentDetail.paymentType,
                            diagnosisId: payment.diagnosisId,
                            billingInstrument: typeof payment.billingInstrument === 'string' ? JSON.parse(payment.billingInstrument!) : payment.billingInstrument,
                            payer: payment?.payer
                        }
                    ];
                }
            }
            return genPaymentsMade;
        }
    });
export const useGetHangedDiagnosisData = (queryClient: QueryClient) =>
    useQuery(
        ['generalBag'],
        async () => {
            const generalBag: TGeneralBag | undefined = await queryClient.getQueryData(['generalBag']);
            return generalBag;
        },
        {
            staleTime: 86400000
        }
    );
export const useItemsListFetch = (reqParams: TFetchListArg) =>
    useQuery(['itemsList'], async () => await fetchPageInit<TItem[]>(reqParams), {
        select: (data) => {
            return data;
        },
        refetchOnMount: 'always'
    });
export const useExpensesListFetch = (reqParams: TFetchListArg) =>
    useQuery(['expenditureList'], async () => await fetchPageInit<TExpenditure[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useExpenseTypesListFetch = (reqParams: TFetchListArg) =>
    useQuery(['expenditureTypesList'], async () => await fetchPageInit<TExpenditureType[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useSuppliersListFetch = (reqParams: TFetchListArg) =>
    useQuery(['suppliersList'], async () => await fetchPageInit<TSuppliers[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const usePurchasesListFetch = (reqParams: TFetchListArg) =>
    useQuery(['purchasesList'], async () => await fetchPageInit<TPurchase[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useAdjustmentListFetch = (reqParams: TFetchListArg) =>
    useQuery(['adjustmentsList'], async () => await fetchPageInit<TAdjustment[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useSalesListFetch = (reqParams: TFetchListArg) =>
    useQuery(['salesList'], async () => await fetchPageInit<TPatientsLabRequests[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useTreatmentProceduresListFetch = (reqParams: TFetchListArg) =>
    useQuery(['treatmentProcedures'], async () => await fetchPageInit<TTreatmentProcedure[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useItemBrandsListFetch = (reqParams: TFetchListArg) =>
    useQuery(['brandsList'], async () => await fetchPageInit<TBrand[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export async function addRecordToCache<T>(queryClient: QueryClient, keyInCache: QueryKey, newItem: T) {
    try {
        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];
        const updatedArray = await addNewItemToCache(existingArray, newItem);

        // Update the cache with the updated array
        queryClient.setQueryData(keyInCache, updatedArray);
    } catch (error) {
        console.log(error);
    }
}
export async function updateCacheRecord<T>(queryClient: QueryClient, keyInCache: QueryKey, data: [T, string | number, string]) {
    try {
        const updatedItem = data[0];

        const itemId = data[1] as string;

        const itemIdProp = data[2] as any;

        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];
        const selectedRecord = existingArray?.find((item: any) => item[itemIdProp] === itemId);
        if (selectedRecord) {
            const updatedArray = await updateCacheItem<T | string>(updatedItem, selectedRecord!, existingArray);

            // Update the cache with the updated array
            queryClient.setQueryData(keyInCache, [...updatedArray]);
        }
    } catch (error: any) {
        console.log(error);
    }
}
export async function deleteCacheRecord<T>(queryClient: QueryClient, keyInCache: QueryKey, data: [T, string, string]) {
    try {
        const deletingItem = data[0];

        const itemId = data[1] as string;

        const itemIdProp = data[2] as string;

        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];

        const selectedRecord = existingArray.find((rec: any) => rec[itemIdProp] === itemId);

        const remainingData = await deleteCacheItem(selectedRecord, existingArray);

        // Update the cache with the updated array
        queryClient.setQueryData(keyInCache, remainingData);
    } catch (error) {
        console.log(error);
    }
}
export async function addRecordsToCache<T>(queryClient: QueryClient, keyInCache: QueryKey, newItems: T[]) {
    try {
        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];

        // Update the cache with the updated array
        queryClient.setQueryData(keyInCache, newItems);
    } catch (error) {
        console.log(error);
    }
}
