import React, { useState, useRef, useEffect, useReducer } from 'react';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { Timeline } from 'primereact/timeline';
import { customReducer, getBaseURL, getSelectedDayDates, getThisMonthDates, REDUCER_ACTION_TYPE, useUserLocalStorage } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import { useAConsultationWaitListFetch, useBillsListFetch, useGetDashBoardReports, useGetPaymentsList } from '../utils/reactQueryUtils';
import { Loader } from '../utils/components';
import { TConsultationWaitList, TDashboardReports, TMoneyReceived, TPaymentInstrument, TPaymentListItem } from '../utils/typesUtil';
import { addMonths, format, getMonth, isAfter, parseISO, startOfMonth, sub, subMonths } from 'date-fns';
import { getYear } from 'date-fns/fp';
const _ = require('lodash');

const visitorChartOptions = {
    plugins: {
        legend: {
            position: 'top',
            align: 'end'
        }
    },
    responsive: true,
    hover: {
        mode: 'index'
    },
    scales: {
        y: {
            min: 10,
            max: 500,
            grid: {
                display: false
            }
        },
        x: {
            grid: {
                display: false
            }
        }
    }
};

const revenueChartOptions = {
    responsive: true,
    hover: {
        mode: 'index'
    },
    scales: {
        y: {
            min: 0,
            max: 500000,
            ticks: {
                stepSize: 100000
            }
        }
    }
};

type TDatasetObject = {
    label?: string;
    data?: number[];
    borderColor?: string;
    pointBackgroundColor?: string;
    backgroundColor?: string;
    fill?: boolean;
    tension?: number;
    barPercentage?: number;
    pointBorderColor?: string;
    type?: string;
    stepped?: boolean;
};
type TRevenueChart = {
    labels: string[];
    datasets: TDatasetObject[];
};
type TSummaryData = {
    treatmentsToday: number;
    treatmentsYesterday: number;
    treatmentsThisMonth: number;
    treatmentsLastMonth: number;
    dailyDifference: number;
    monthlyDifference: number;
    paymentsToday: number;
    paymentsYesterday: number;
    dailyPaymentsDiff: number;
    paymentsThisMonth: number;
    paymentsLastMonth: number;
    monthlyPaymentsDiff: number;
};
type TDashboard = {
    todayEarned?: TMoneyReceived;
    thisWeekEarned?: TMoneyReceived;
    thisMonthEarned?: TMoneyReceived;
    summaryData: TSummaryData;
    appointmentChart: any | undefined;
    expectedAppointments: TAppointmentEvent[] | undefined;
    revenueChart: TRevenueChart;
    averageMonthlyPatientVisit: number;
};
type TAppointmentEvent = {
    patientName: string;
    appointmentTime: string;
    phoneNumber: string;
    color: string;
    icon: string;
};

const visitorYear = [
    { name: 2023, code: '1' },
    { name: 2024, code: '2' },
    { name: 2025, code: '3' },
    { name: 2026, code: '4' }
];

const revenueYear = [
    { name: 2023, code: '1' },
    { name: 2024, code: '2' },
    { name: 2025, code: '3' },
    { name: 2026, code: '4' }
];

const INITIAL_STATE: TDashboard = {
    appointmentChart: undefined,
    thisMonthEarned: undefined,
    thisWeekEarned: undefined,
    todayEarned: undefined,
    summaryData: {
        treatmentsToday: 0,
        treatmentsYesterday: 0,
        treatmentsThisMonth: 0,
        treatmentsLastMonth: 0,
        dailyDifference: 0,
        monthlyDifference: 0,
        paymentsToday: 0,
        paymentsYesterday: 0,
        dailyPaymentsDiff: 0,
        paymentsThisMonth: 0,
        paymentsLastMonth: 0,
        monthlyPaymentsDiff: 0
    },
    expectedAppointments: [],
    revenueChart: { labels: [], datasets: [] },
    averageMonthlyPatientVisit: 0
};
const Dashboard = () => {
    const navigate = useNavigate();
    const [selectedVisitorYear, setSelectedVisitorYear] = useState(visitorYear[1]);
    const [selectedRevenueYear, setSelectedRevenueYear] = useState(revenueYear[1]);
    const [user, updateUser, clearUser] = useUserLocalStorage();
    const visitor = useRef<any>(null);
    const revenue = useRef<any>(null);
    const [state, dispatch] = useReducer(customReducer<TDashboard>, INITIAL_STATE);
    // const { data: dashboardData, isLoading, refetch, dataUpdatedAt } = useGetDashBoardReports({ urlLink: `${getBaseURL()}/bills/get_dashboard_reports` });
    const { data, isLoading } = useAConsultationWaitListFetch({ urlLink: `${getBaseURL()}/consultation/get_wait_list` });
    const { data: paymentsList, isLoading: paymentsLoading } = useGetPaymentsList({ urlLink: `${getBaseURL()}/bills/get_payments_list` });

    useEffect(() => {
        if (data !== undefined && paymentsList !== undefined) {
            const { dailyDifference, treatmentsToday, treatmentsYesterday, treatmentsThisMonth, treatmentsLastMonth, monthlyDifference, paymentsToday, paymentsYesterday, dailyPaymentsDiff, monthlyPaymentsDiff, paymentsLastMonth, paymentsThisMonth } =
                computeToLevelData();
            const currentYearAppointments = getMonthlyAppointmentsCountArray(data, selectedVisitorYear.name);
            const currentMonthNumber = new Date().getMonth() + 1;
            const totalPatientsThisYear = currentYearAppointments.appointmentsCount;
            setStateValues({
                summaryData: {
                    dailyDifference,
                    treatmentsToday,
                    treatmentsYesterday,
                    treatmentsThisMonth,
                    treatmentsLastMonth,
                    monthlyDifference,
                    paymentsToday,
                    paymentsYesterday,
                    dailyPaymentsDiff,
                    monthlyPaymentsDiff,
                    paymentsLastMonth,
                    paymentsThisMonth
                },
                appointmentChart: appointmentsChart(currentYearAppointments.monthlyCounts),
                expectedAppointments: expectedAppointments(),
                revenueChart: revenueChartData(selectedRevenueYear.name),
                averageMonthlyPatientVisit: totalPatientsThisYear !== undefined ? totalPatientsThisYear / currentMonthNumber : 0
            });
        }
    }, [data, paymentsList]);

    const setStateValues = (stateValues: Partial<TDashboard>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: { ...stateValues }
        });
    };

    const computeToLevelData = (): TSummaryData => {
        const todayPatientsTreated = getSelectedDayDates<TConsultationWaitList>(data!, 'appointmentDate').length;
        const yesterdayTreatments = getSelectedDayDates<TConsultationWaitList>(data!, 'appointmentDate', sub(new Date(), { days: 1 })).length;

        const dailyDiff = ((todayPatientsTreated - yesterdayTreatments) / yesterdayTreatments) * 100;

        const thisMonthTreatments = getThisMonthDates<TConsultationWaitList>(data!, 'appointmentDate', new Date()).length;
        const lastMonthTreatments = getThisMonthDates<TConsultationWaitList>(data!, 'appointmentDate', subMonths(new Date(), 1)).length;
        const treatmentsLastMonth = lastMonthTreatments !== 0 ? lastMonthTreatments : 1;
        const monthlyDiff = (((thisMonthTreatments - lastMonthTreatments) / treatmentsLastMonth) * 100).toFixed(2);

        const todayPayments = getSelectedDayDates<TPaymentListItem>(paymentsList!, 'paymentDate', new Date()).reduce((previousValue, currentValue) => {
            return previousValue + parseFloat(currentValue.amountPaid.toString());
        }, 0);
        const yesterdayPayments = getSelectedDayDates<TPaymentListItem>(paymentsList!, 'paymentDate', sub(new Date(), { days: 1 })).reduce((previousValue, currentValue) => {
            return previousValue + parseFloat(currentValue.amountPaid.toString());
        }, 0);

        const dailyPaymentsDiff = (((todayPayments - yesterdayPayments) / yesterdayPayments) * 100).toFixed(2);

        const thisMonthPayments = getThisMonthDates<TPaymentListItem>(paymentsList!, 'paymentDate', new Date()).reduce((previousValue, currentValue) => {
            return previousValue + parseFloat(currentValue.amountPaid.toString());
        }, 0);
        const lastMonthPayments = getThisMonthDates<TPaymentListItem>(paymentsList!, 'paymentDate', subMonths(new Date(), 1)).reduce((previousValue, currentValue) => {
            return previousValue + currentValue.amountPaid;
        }, 0);
        const monthlyPaymentsDiff = (((thisMonthPayments - lastMonthPayments) / lastMonthPayments) * 100).toFixed(2);

        return {
            dailyDifference: dailyDiff === Infinity ? 0 : parseFloat(dailyDiff.toFixed(2)),
            treatmentsToday: todayPatientsTreated,
            treatmentsYesterday: yesterdayTreatments,
            treatmentsThisMonth: thisMonthTreatments,
            treatmentsLastMonth: lastMonthTreatments,
            monthlyDifference: parseFloat(monthlyDiff) === Infinity ? 0 : parseFloat(monthlyDiff),
            paymentsToday: todayPayments,
            paymentsYesterday: yesterdayPayments,
            dailyPaymentsDiff: parseFloat(dailyPaymentsDiff) === Infinity ? 0 : parseFloat(dailyPaymentsDiff),
            monthlyPaymentsDiff: parseFloat(monthlyPaymentsDiff) === Infinity ? 0 : parseFloat(monthlyPaymentsDiff),
            paymentsLastMonth: lastMonthPayments,
            paymentsThisMonth: thisMonthPayments
        };
    };

    if (!user) navigate('/login');
    if (isLoading) return <Loader />;
    const expectedAppointments = (): TAppointmentEvent[] => {
        const today = new Date();

        const expectedAppointments = data?.filter((appointment) => {
            return appointment.appointmentType === 'Appointment' && isAfter(parseISO(appointment.appointmentDate as string), today);
        });

        return expectedAppointments!.map((appointment: TConsultationWaitList) => {
            return {
                patientName: appointment.fullName,
                appointmentTime: appointment.appointmentDate as string,
                icon: 'pi pi-plus',
                color: '#0BD18A',
                phoneNumber: appointment.phoneNumber
            };
        });
    };
    const changeRevenueChart = (event: any) => {
        setSelectedRevenueYear(event.value);
        setStateValues({
            revenueChart: revenueChartData(event.value.name)
        });
    };

    const changeVisitorChart = (event: any) => {
        setSelectedVisitorYear(event.value);
        const currentYearAppointments = getMonthlyAppointmentsCountArray(data, parseInt(event.value.name));

        const currentMonthNumber = new Date().getMonth() + 1;
        const totalPatientsThisYear = currentYearAppointments.appointmentsCount;
        setStateValues({
            appointmentChart: appointmentsChart(currentYearAppointments.monthlyCounts),
            averageMonthlyPatientVisit: totalPatientsThisYear !== undefined ? totalPatientsThisYear / currentMonthNumber : 0
        });
    };

    const marker = (item: TAppointmentEvent) => {
        return (
            <span className="custom-marker" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const content = (item: TAppointmentEvent) => {
        return (
            <>
                <div className="flex align-items-center justify-content-between">
                    <p>{item.patientName.toUpperCase()}</p>
                    <h6 style={{ color: item.color }}> {item.phoneNumber}</h6>
                </div>
                <span>{format(new Date(item.appointmentTime), 'yyyy-MM-dd HH:mm')}</span>
            </>
        );
    };
    const filterAppointmentsByYear = (appointments: any, year: any) => {
        return appointments.filter((appointment: TConsultationWaitList) => {
            const appointmentYear = parseISO(appointment.appointmentDate as string).getFullYear();
            return appointmentYear === year;
        });
    };
    const getMonthlyAppointmentsCountArray = (appointments: any, year: any): { monthlyCounts: number[]; appointmentsCount: number } => {
        const filteredAppointments = filterAppointmentsByYear(appointments, year);

        const firstMonthOfYear = new Date(`${year}-01-01T00:00:00.000Z`);
        const lastMonthOfYear = new Date(`${year + 1}-01-01T00:00:00.000Z`);

        const monthlyCounts = [];
        let currentDate = firstMonthOfYear;

        while (currentDate < lastMonthOfYear) {
            const currentMonth = format(currentDate, 'yyyy-MM');
            const appointmentsInMonth = filteredAppointments.filter((appointment: any) => {
                const appointmentMonth = format(parseISO(appointment.appointmentDate), 'yyyy-MM');
                return appointmentMonth === currentMonth;
            });

            monthlyCounts.push(appointmentsInMonth.length);
            currentDate = addMonths(currentDate, 1);
        }
        const appointmentsCount = filteredAppointments.length;
        return { monthlyCounts, appointmentsCount };
    };
    const appointmentsChart = (actualGrowthData: number[]): TRevenueChart => {
        return {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            datasets: [
                {
                    label: 'Plan',
                    data: [10, 20, 40, 30, 25, 30, 20, 45, 10, 15, 80, 50],
                    borderColor: '#FC6161',
                    pointBorderColor: 'transparent',
                    pointBackgroundColor: 'transparent',
                    type: 'line',
                    fill: false,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    label: 'Growth actual',
                    data: actualGrowthData,
                    backgroundColor: getComputedStyle(document.body).getPropertyValue('--primary-color'),
                    fill: true,
                    barPercentage: 0.5
                }
            ]
        };
    };
    const revenueChartData = (selectedRevenueYear: number) => {
        const revenueData = genRevenueChartData(selectedRevenueYear);
        return {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            datasets: [
                {
                    label: 'Consultation Fee',
                    data: revenueData['Consultation Fee'],
                    borderColor: '#EEE500',
                    pointBackgroundColor: '#EEE500',
                    backgroundColor: 'rgba(238, 229, 0, 0.05)',
                    fill: true,
                    tension: 0.4
                },
                {
                    label: 'Registration Fee',
                    data: revenueData['Registration Fee'],
                    borderColor: '#00D0DE',
                    pointBackgroundColor: '#00D0DE',
                    backgroundColor: 'rgba(0, 208, 222, 0.05)',
                    fill: true,
                    tension: 0.4
                },
                {
                    label: 'Procedure Fee',
                    data: revenueData['Procedure Fee'],
                    borderColor: '#FC6161',
                    pointBackgroundColor: '#FC6161',
                    backgroundColor: 'rgba(253, 72, 74, 0.05)',
                    fill: true,
                    tension: 0.4
                },
                {
                    label: 'Drugs Fee',
                    data: revenueData['Drugs Fee'],
                    borderColor: '#0F8BFD',
                    pointBackgroundColor: '#0F8BFD',
                    backgroundColor: 'rgba(15, 139, 253, 0.05)',
                    fill: true,
                    tension: 0.4
                }
            ]
        };
    };
    const genRevenueChartData = (revenueYear: number) => {
        const aggregatedValues: any = {};

        paymentsList
            ?.filter((payment) => getYear(parseISO(payment.paymentDate)) === revenueYear)
            .forEach((payment) => {
                const paymentDate = parseISO(payment.paymentDate);
                const month = getMonth(paymentDate);

                payment.billingInstrument.forEach((billingItem) => {
                    if (!aggregatedValues[billingItem.item]) {
                        aggregatedValues[billingItem.item] = Array(12).fill(0);
                    }

                    aggregatedValues[billingItem.item][month] += parseFloat(billingItem.price);
                });
            });

        return aggregatedValues;
    };
    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12 md:col-3">
                    <div className={`card widget-overview-box ${state.summaryData.dailyDifference === 0 ? 'widget-overview-box-3' : state.summaryData.dailyDifference > 0 ? 'widget-overview-box-2' : 'widget-overview-box-1'}`}>
                        <span className="overview-title">TREATMENTS TODAY</span>
                        <div className="flex justify-content-between">
                            <div className="overview-detail flex justify-content-between">
                                <div className="overview-badge flex justify-content-center align-items-center">
                                    <i className={`${state.summaryData.dailyDifference === 0 ? 'pi pi-minus' : state.summaryData.dailyDifference > 0 ? 'pi pi-arrow-up' : 'pi pi-arrow-down'}`}></i>
                                    <span>{state.summaryData.dailyDifference}%</span>
                                </div>
                                <div className="overview-text">{state.summaryData.treatmentsToday}</div>
                            </div>
                        </div>
                        <img src={`assets/layout/images/dashboard/${state.summaryData.dailyDifference === 0 ? 'quantity.svg' : state.summaryData.dailyDifference > 0 ? 'value.svg' : 'rate.svg'}`} alt="rate" />
                    </div>
                </div>
                <div className="col-12 md:col-3">
                    <div className={`card widget-overview-box ${state.summaryData.monthlyDifference === 0 ? 'widget-overview-box-3' : state.summaryData.monthlyDifference > 0 ? 'widget-overview-box-2' : 'widget-overview-box-1'}`}>
                        <span className="overview-title">MONTH TREATMENTS</span>
                        <div className="flex justify-content-between">
                            <div className="overview-detail flex justify-content-between">
                                <div className="overview-badge flex justify-content-center align-items-center">
                                    <i className={`${state.summaryData.monthlyDifference === 0 ? 'pi pi-minus' : state.summaryData.monthlyDifference > 0 ? 'pi pi-arrow-up' : 'pi pi-arrow-down'}`}></i>
                                    <span>{state.summaryData.monthlyDifference}%</span>
                                </div>
                                <div className="overview-text">{state.summaryData.treatmentsThisMonth}</div>
                            </div>
                        </div>
                        <img src={`assets/layout/images/dashboard/${state.summaryData.monthlyDifference === 0 ? 'quantity.svg' : state.summaryData?.monthlyDifference > 0 ? 'value.svg' : 'rate.svg'}`} alt="rate" />
                    </div>
                </div>
                <div className="col-12 md:col-3">
                    <div className={`card widget-overview-box ${state.summaryData.dailyPaymentsDiff === 0 ? 'widget-overview-box-3' : state.summaryData.dailyPaymentsDiff > 0 ? 'widget-overview-box-2' : 'widget-overview-box-1'}`}>
                        <span className="overview-title">PAYMENTS TODAY</span>
                        <div className="flex justify-content-between">
                            <div className="overview-detail flex justify-content-between">
                                <div className="overview-badge flex justify-content-center align-items-center">
                                    <i className={`${state.summaryData.dailyPaymentsDiff === 0 ? 'pi pi-minus' : state.summaryData.dailyPaymentsDiff > 0 ? 'pi pi-arrow-up' : 'pi pi-arrow-down'}`}></i>
                                    <span>{state.summaryData.dailyPaymentsDiff}%</span>
                                </div>
                                <div className="overview-text">{state.summaryData.paymentsToday}</div>
                            </div>
                        </div>
                        <img src={`assets/layout/images/dashboard/${state.summaryData.dailyPaymentsDiff === 0 ? 'quantity.svg' : state.summaryData.dailyPaymentsDiff > 0 ? 'value.svg' : 'rate.svg'}`} alt="rate" />
                    </div>
                </div>
                <div className="col-12 md:col-3">
                    <div className={`card widget-overview-box ${state.summaryData.monthlyPaymentsDiff === 0 ? 'widget-overview-box-3' : state.summaryData.monthlyPaymentsDiff > 0 ? 'widget-overview-box-2' : 'widget-overview-box-1'}`}>
                        <span className="overview-title">PAYMENTS THIS MONTH</span>
                        <div className="flex justify-content-between">
                            <div className="overview-detail flex justify-content-between">
                                <div className="overview-badge flex justify-content-center align-items-center">
                                    <i className={`${state.summaryData.monthlyPaymentsDiff === 0 ? 'pi pi-minus' : state.summaryData.monthlyPaymentsDiff > 0 ? 'pi pi-arrow-up' : 'pi pi-arrow-down'}`}></i>
                                    <span>{state.summaryData.monthlyPaymentsDiff}%</span>
                                </div>
                                <div className="overview-text">{state.summaryData.paymentsThisMonth}</div>
                            </div>
                        </div>
                        <img src={`assets/layout/images/dashboard/${state.summaryData.monthlyPaymentsDiff === 0 ? 'quantity.svg' : state.summaryData.monthlyPaymentsDiff > 0 ? 'value.svg' : 'rate.svg'}`} alt="rate" />
                    </div>
                </div>
                <div className="col-12 md:col-8">
                    <div className="card widget-visitor-graph">
                        <div className="card-header">
                            <span>Unique visitor graph</span>
                            <Dropdown options={visitorYear} value={selectedVisitorYear} optionLabel="name" onChange={changeVisitorChart}></Dropdown>
                        </div>

                        <div className="graph-content grid">
                            <div className="col-12 md:col-6">
                                <h2>{state.averageMonthlyPatientVisit}</h2>
                                <h6>AVG. MRR/PATIENT</h6>
                                <p>
                                    The accumulated visits generated per monthly basis. <button className="p-link">Learn more</button>
                                </p>
                            </div>
                        </div>

                        <div className="graph">
                            <h6>Visits</h6>
                            <Chart ref={visitor} type="bar" data={state?.appointmentChart} options={visitorChartOptions} id="visitor-chart"></Chart>
                        </div>
                    </div>
                </div>

                <div className="col-12 md:col-4">
                    <div className="card widget-timeline">
                        <div className="timeline-header flex justify-content-between align-items-center">
                            <p>Incoming Appointments</p>
                            <div className="header-icons">
                                <i className="pi pi-refresh"></i>
                                <i className="pi pi-filter"></i>
                            </div>
                        </div>
                        <div className="timeline-content">
                            <Timeline value={state.expectedAppointments} marker={marker} content={content} className="custimized-timeline" />
                        </div>
                        <div className="timeline-footer flex align-items-center justify-content-center">
                            <button className="p-link">
                                View all transactions <i className="pi pi-arrow-down"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="col-12 md:col-12">
                    <div className="card widget-revenue-graph">
                        <div className="card-header">
                            <span>Monthly revenue</span>
                            <Dropdown options={revenueYear} value={selectedRevenueYear} optionLabel="name" onChange={changeRevenueChart}></Dropdown>
                        </div>

                        <div className="graph">
                            <Chart ref={revenue} type="line" id="revenue-chart" data={state.revenueChart} options={revenueChartOptions}></Chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
