import { IOneForAll, TAdjustment, TBrand, TItem, TPriceEditItem, TPurchase, TSales } from '../utils/typesUtil';
import { AxiosResponse } from 'axios';
import { fetchAction, getBaseURL } from '../utils/utils';

class Items implements IOneForAll<TItem> {
    async createInstance(stateValues: TItem): Promise<AxiosResponse<{ status: number; operatedData: TItem; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/items/new_item`, { itemData: stateValues });
    }

    async deleteInstance(deleteId: string): Promise<AxiosResponse<{ status: number; operatedData: TItem; error?: string }>> {
        return await fetchAction('delete', `${getBaseURL()}/items/delete_item`, { deleteItemId: deleteId });
    }

    async updateInstance(stateValues: TItem): Promise<AxiosResponse<{ status: number; operatedData: TItem; error?: string }>> {
        return await fetchAction('patch', `${getBaseURL()}/items/update_item`, { itemData: stateValues });
    }
    async newPurchase(purchase: TPurchase): Promise<AxiosResponse<{ status: number; operatedData: TPurchase; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/items/new_purchase`, { purchaseData: purchase });
    }
    async updatePurchase(purchase: TPurchase): Promise<AxiosResponse<{ status: number; operatedData: TPurchase; error?: string }>> {
        return await fetchAction('patch', `${getBaseURL()}/items/update_purchase`, { purchaseData: purchase });
    }
    async newAdjustment(adjustmentData: TAdjustment): Promise<AxiosResponse<{ status: number; operatedData: TAdjustment; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/items/new_adjustment`, { adjustmentData });
    }
    async newSales(salesData: TSales): Promise<AxiosResponse<{ status: number; operatedData: TSales; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/items/new_sales`, { salesData });
    }
    async updateSales(salesData: TSales): Promise<AxiosResponse<{ status: number; operatedData: TSales; error?: string }>> {
        return await fetchAction('patch', `${getBaseURL()}/items/update_sales`, { salesData });
    }
    async updatePrices(priceEditItems: TPriceEditItem[]): Promise<AxiosResponse<{ status: number; operatedData: TItem[]; error?: string }>> {
        return await fetchAction('patch', `${getBaseURL()}/items/edit_items_prices`, { priceEditItems });
    }
    async newItemBrand(brandDescription: string): Promise<AxiosResponse<{ status: number; operatedData: TBrand; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/items/new_brand`, { brandDescription });
    }
    async updateItemBrand(brandData: TBrand): Promise<AxiosResponse<{ status: number; operatedData: TBrand; error?: string }>> {
        return await fetchAction('patch', `${getBaseURL()}/items/update_brand`, { brandData });
    }
}
export default Items;
