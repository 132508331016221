import { GeneralPageProps, Loader, SimpleTableWithMenu } from '../utils/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getTime } from 'date-fns';
import { Toast } from 'primereact/toast';
import { useAccumulatedDebtorsListFetch, useBillsListFetch } from '../utils/reactQueryUtils';
import { AppUserContext, formatDate, getBaseURL, getTableRowId, getThisMonthDates, getTodayDates } from '../utils/utils';
import { Button } from 'primereact/button';
import { Item, TConsultationWaitList, TDebtor, TUsers } from '../utils/typesUtil';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
const items = [
    { name: 'Today', value: 1 },
    { name: 'This Month', value: 2 },
    { name: 'All Time', value: 3 }
];
const BillList = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const toastRef = useRef<Toast>(null);
    const user = useContext<TUsers>(AppUserContext);
    const { data: billsWaitList, isLoading, refetch, dataUpdatedAt } = useBillsListFetch({ urlLink: `${getBaseURL()}/bills/get_bills_list` });
    const [value, setValue] = useState<Item | number>(1);
    const [billsList, setBillsList] = useState<TConsultationWaitList[]>([]);
    const { data: debtorsList, isLoading: debtorsLoading } = useAccumulatedDebtorsListFetch({ urlLink: `${getBaseURL()}/bills/get_accumulated_debtors_list` });

    useEffect(() => {
        if (!user) navigate('/login');
        setBillsList(getTodayDates<TConsultationWaitList>(billsWaitList!, 'diagnosisDate')!);
        setValue(1);
    }, [billsWaitList]);

    if (isLoading || debtorsLoading) return <Loader />;
    const goToPaymentCenter = (e: React.MouseEvent<HTMLButtonElement>) => {
        let patientDebtAmount = 0;
        // @ts-ignore
        // const appointmentStatus = e.target.closest('td').previousSibling.innerText;
        // if (appointmentStatus !== 'Accounts') {
        //     displayMessage({
        //         toastComponent: toastRef,
        //         header: 'Error',
        //         message: 'Patient cannot be processed to selected room currently!',
        //         infoType: 'error',
        //         life: 3000
        //     });
        //     return;
        // }
        const diagnosisId = getTableRowId(e, 'id');
        const selectedPatient = billsWaitList?.find((waitingPatient: TConsultationWaitList) => waitingPatient.diagnosisId === diagnosisId);
        const patientDebtor = debtorsList?.find((patient: TDebtor) => patient.patientId === selectedPatient?.patientId);
        if (patientDebtor) patientDebtAmount = patientDebtor.balance;

        queryClient.setQueryData(['generalBag'], { diagnosingPatient: selectedPatient, patientDebtAmount: parseFloat(patientDebtAmount.toFixed(2)) });
        navigate('/invoice');
    };
    const onSelectionChange = (e: SelectButtonChangeEvent) => {
        switch (e.value) {
            case 1:
                setBillsList(getTodayDates<TConsultationWaitList>(billsWaitList!, 'diagnosisDate'));
                break;
            case 2:
                setBillsList(getThisMonthDates<TConsultationWaitList>(billsWaitList!, 'diagnosisDate'));
                break;
            case 3:
                setBillsList(billsWaitList!);
                break;
            default:
                return billsWaitList;
        }
        setValue(e.value);
    };
    return (
        <>
            <GeneralPageProps toastRef={toastRef} toastPosition="top-right" />
            <div className="card flex justify-content-center">
                <SelectButton value={value} onChange={onSelectionChange} optionLabel="name" options={items} />
            </div>
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={'diagnosisId'}
                    columnsDef={[
                        { body: (rowData: TConsultationWaitList) => <div>{formatDate(rowData.diagnosisDate as Date)}</div>, header: 'Diagnosis Date' },
                        { field: 'fullName', header: 'Name' },
                        { field: 'gender', header: 'Gender' },
                        { field: 'phoneNumber', header: 'Phone' },
                        { field: 'location', header: 'Location' },
                        { field: 'insuranceStatus', header: 'Insurance' },
                        {
                            body: (row: TConsultationWaitList) => (
                                <div>
                                    <Button id={row.diagnosisId} icon="pi pi-external-link" className="p-button-rounded p-button-success mr-2" onClick={goToPaymentCenter} />
                                </div>
                            ),
                            header: 'Accounts'
                        }
                        // {
                        //     body: (row: TConsultationWaitList) => (
                        //         <div>
                        //             <Button id={row.diagnosisId} icon="pi pi-external-link" className="p-button-rounded p-button-success mr-2" onClick={goToPaymentCenter} />
                        //         </div>
                        //     ),
                        //     header: 'Accounts'
                        // }
                    ]}
                    tableData={billsList!}
                    hasMenuList={false}
                    tableTitle="Bills List"
                    lastTableUpdate={getTime(new Date())}
                    childTableDef={[]}
                    searchValues={['fullName', 'phoneNumber', 'diagnosisDate']}
                    searchFieldPlaceHolder="Search by Full Name, Phone Number or Date"
                    refreshTableAction={refetch}
                />
            </div>
        </>
    );
};
export default BillList;
