import { IEmergencyContact, IOneForAll, IPatient, IPatientAndEmergencyContact, TAppointment, TPatientAndEmergency } from '../utils/typesUtil';
import { AxiosResponse } from 'axios';
import { fetchAction, getBaseURL, sliceObject } from '../utils/utils';
import patient from '../components/Patient';

class PatientsInfo implements IOneForAll<IPatientAndEmergencyContact>, IPatient, IEmergencyContact {
    company: string = '';
    emailAddress: string = '';
    emergencyContactTitle: string = '';
    emergencyEmailAddress: string = '';
    emergencyFirstName: string = '';
    emergencyLastName: string = '';
    emergencyOtherName: string = '';
    emergencyPhoneNumber: string = '';
    emergencyRelationship: string = '';
    firstName: string = '';
    gender: string = '';
    nationality: string = '';
    occupation: string = '';
    otherNames: string = '';
    patientId: string = '';
    phoneNumber: string = '';
    religion: string = '';
    sponsorshipLimit: string = '';
    title: string = '';
    insuranceStatus: string = '';
    insuranceType: string = '';
    lastName: string = '';
    location: string = '';
    dateOfBirth: string | Date | Date[] = new Date();
    patientCode: string = '';
    async createInstance(stateValues: IPatientAndEmergencyContact): Promise<AxiosResponse<{ status: number; operatedData: IPatientAndEmergencyContact; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/patients/new_patient`, { patientData: stateValues });
    }

    async deleteInstance(deleteId: string): Promise<AxiosResponse> {
        return await fetchAction('post', `${getBaseURL()}/customers/delete_customer`, {});
    }

    async updateInstance(stateValues: IPatientAndEmergencyContact): Promise<AxiosResponse<{ status: number; operatedData: IPatientAndEmergencyContact; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/patients/update_patient`, { patientData: stateValues });
    }
    async bookConsultation(patientId: string): Promise<AxiosResponse<{ status: number; operatedData: TAppointment; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/patients/book_patient_consultation`, { patientId });
    }
    async uploadPatientsData(patients: Partial<TPatientAndEmergency>[]): Promise<AxiosResponse<{ status: number; operatedData: TAppointment[]; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/patients/upload_bulk_patients`, { patientData: patients });
    }
}

export default PatientsInfo;
