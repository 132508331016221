import { IOneForAll, TDiagnosis, TTreatmentProcedure } from '../utils/typesUtil';
import { AxiosResponse } from 'axios';
import { fetchAction, getBaseURL } from '../utils/utils';

class Diagnosis implements IOneForAll<TDiagnosis> {
    async createInstance(stateValues: TDiagnosis): Promise<AxiosResponse<{ status: number; operatedData: TDiagnosis; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/diagnosis/new_diagnosis`, { diagnosisData: stateValues });
    }
    async deleteInstance(deleteId: string): Promise<AxiosResponse> {
        return await fetchAction('post', `${getBaseURL()}/patients/new_patient`, { patientData: deleteId });
    }

    async updateInstance(stateValues: TDiagnosis): Promise<AxiosResponse<{ status: number; operatedData: TDiagnosis; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/diagnosis/update_diagnosis`, { diagnosisData: stateValues });
    }

    async updateLabRequest(stateValues: any): Promise<AxiosResponse<{ status: number; operatedData: TDiagnosis; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/labs/update_lab_request`, { ...stateValues });
    }
    async uploadLabFiles(filesData: any): Promise<AxiosResponse<{ status: number; operatedData: TDiagnosis; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/labs/upload_lab_files`, { filesData });
    }
    async createTreatmentProcedure(stateValues: TTreatmentProcedure): Promise<AxiosResponse<{ status: number; operatedData: TTreatmentProcedure; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/diagnosis/new_treatment_procedure`, { procedureData: stateValues });
    }
    async updateTreatmentProcedure(stateValues: TTreatmentProcedure): Promise<AxiosResponse<{ status: number; operatedData: TTreatmentProcedure; error?: string }>> {
        return await fetchAction('patch', `${getBaseURL()}/diagnosis/update_treatment_procedure`, { procedureData: stateValues });
    }
    async deleteTreatmentProcedure(procedureId: string): Promise<AxiosResponse<{ status: number; operatedData: TTreatmentProcedure; error?: string }>> {
        return await fetchAction('delete', `${getBaseURL()}/diagnosis/delete_treatment_procedure`, { procedureId });
    }
}
export default Diagnosis;
