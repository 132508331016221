import { IOneForAll, TAppointment } from '../utils/typesUtil';
import { AxiosResponse } from 'axios';
import { fetchAction, getBaseURL } from '../utils/utils';

class Appointment implements IOneForAll<any>, TAppointment {
    appointmentDate: string = '';
    appointmentId: string = '';
    appointmentPatientId: string = '';
    async createInstance(stateValues: TAppointment): Promise<AxiosResponse<{ status: number; operatedData: TAppointment; error?: string }>> {
        return await fetchAction('post', `${getBaseURL()}/appointments/new_appointment`, { appointmentData: stateValues });
    }

    async deleteInstance(deleteId: string): Promise<AxiosResponse<{ status: number; operatedData: TAppointment; error?: string }>> {
        return await fetchAction('delete', `${getBaseURL()}/appointments/delete_appointment`, { appointmentId: deleteId });
    }

    async updateInstance(stateValues: any): Promise<AxiosResponse<{ status: number; operatedData: TAppointment; error?: string }>> {
        return await fetchAction('patch', `${getBaseURL()}/appointments/update_appointment`, { appointmentData: stateValues });
    }
}
export default Appointment;
