import React, { ChangeEvent, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { doUserLogin } from '../utils/reactQueryUtils';
import { displayMessage, useUserLocalStorage } from '../utils/utils';
import { TUsers } from '../utils/typesUtil';
import { Toast } from 'primereact/toast';
import { Loader } from '../utils/components';

export const Login = (props: any) => {
    const queryClient = useQueryClient();
    const navigateFromHere = useNavigate();
    const [user, updateUser, clearUser] = useUserLocalStorage();
    const toastRef = useRef<Toast>(null);
    const [logState, setLogState] = useState({
        username: '',
        password: ''
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const performLogin = async () => {
        try {
            setIsLoading(true);
            queryClient
                .fetchQuery(['userData'], async () => await doUserLogin({ username: logState.username, password: logState.password }), {})
                .then((data: TUsers) => {
                    setupPreliminaryLocalCache();
                    updateUser(data);
                    navigateFromHere('/');
                });
        } catch (error: any) {
            displayMessage({
                header: 'Error',
                message: error.message,
                infoType: 'error',
                toastComponent: toastRef,
                life: 5000
            });
        }
    };
    const controlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLogState((prevState) => {
            return { ...prevState, [e.target.id]: e.target.value };
        });
    };
    const setupPreliminaryLocalCache = () => {
        queryClient.setQueryData(['generalBag'], []);
    };
    return (
        <>
            {isLoading && <Loader />}
            <div className="login-body">
                <div className="login-image">
                    <img src={`assets/layout/images/pages/login-${props.colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="atlantis" />
                </div>
                <div className="login-panel p-fluid">
                    <div className="flex flex-column">
                        <div className="flex align-items-center mb-6 logo-container">
                            <img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="login-logo" alt="login-logo" />
                            <img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="login-appname" alt="login-appname" />
                        </div>
                        <div className="form-container">
                            <span className="p-input-icon-left">
                                <i className="pi pi-envelope"></i>
                                <InputText value={logState.username} type="text" onChange={controlChange} placeholder="username" id="username" autoComplete="off" />
                                {/*<InputText value={logState.username} type="text" onChange={controlChange} placeholder="Email" id="username" autoComplete="off"/>*/}
                            </span>
                            <span className="p-input-icon-left">
                                <i className="pi pi-key"></i>
                                <InputText value={logState.password} onChange={controlChange} type="password" placeholder="Password" id="password" />
                            </span>
                            <button className="flex p-link">Forgot your password?</button>
                        </div>
                        <div className="button-container">
                            <Button type="button" label="Login" onClick={performLogin}></Button>
                            <span>
                                Don’t have an account?<button className="p-link">Sign-up here</button>
                            </span>
                        </div>
                    </div>

                    <div className="login-footer flex align-items-center">
                        <div className="flex align-items-center login-footer-logo-container">
                            <img src="assets/layout/images/logo-gray.png" className="login-footer-logo" alt="login-footer-logo" />
                            <img src="assets/layout/images/appname-gray.png" className="login-footer-appname" alt="login-footer-appname" />
                        </div>
                        <span>Copyright 2023</span>
                    </div>
                </div>
            </div>
        </>
    );
};
